/**
 * @fileOverview
 * @name VersionRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { CreatableRepository } from '@aim/shared/src/repository';
import { WebStorage } from '@w/util/storage';
import { VERSION_KEY } from '@w/config';

export type VersionCreatableRepository = CreatableRepository<{ version: string }, string | null>;

export class VersionRepository implements VersionCreatableRepository {
  public async create({
    version,
  }: {
    version: string;
  }): ReturnType<VersionCreatableRepository['create']> {
    const oldVersion = WebStorage.getItem(VERSION_KEY);
    if (oldVersion !== version) {
      WebStorage.setItem(VERSION_KEY, version);
      return oldVersion;
    }
    return null;
  }
}
