import React from 'react';

export const CornerIcon = React.memo(() => {
  return (
    <span aria-hidden={true}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7188 3.93774V14.5002C15.7188 14.8235 15.5903 15.1335 15.3618 15.362C15.1332 15.5906 14.8232 15.719 14.5 15.719H3.9375C3.61427 15.719 3.30427 15.5906 3.07571 15.362C2.84715 15.1335 2.71875 14.8235 2.71875 14.5002C2.71875 14.177 2.84715 13.867 3.07571 13.6385C3.30427 13.4099 3.61427 13.2815 3.9375 13.2815H11.5547L0.637734 2.36149C0.524366 2.24812 0.434438 2.11353 0.373084 1.96541C0.31173 1.81729 0.280151 1.65853 0.280151 1.49821C0.280151 1.17441 0.408778 0.863883 0.637734 0.634926C0.86669 0.40597 1.17722 0.277344 1.50102 0.277344C1.82481 0.277344 2.13534 0.40597 2.3643 0.634926L13.2812 11.5549V3.93774C13.2812 3.61451 13.4097 3.30451 13.6382 3.07595C13.8668 2.84739 14.1768 2.71899 14.5 2.71899C14.8232 2.71899 15.1332 2.84739 15.3618 3.07595C15.5903 3.30451 15.7188 3.61451 15.7188 3.93774Z"
          fill="#0F172A"
        />
      </svg>
    </span>
  );
});
