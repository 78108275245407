import React from 'react';
import { Environment } from '@s/platform/Environment';
import { UAParser } from 'ua-parser-js';
import { WidgetInternalConfig, defaultWidgetInternalConfig } from './WidgetConfig';
import { WidgetType } from './WidgetType';

export const defaultWidgetEnvironment = {
  isSupportedEnvironment: true,
  isSupportedPcBrowser: true,
  isSupportedMobileBrowser: false,
  platform: new UAParser().getResult(),
  forceSupportAs(unused: 'iOS' | 'Android' | 'PC') {
    void unused;
  },
};

export const WidgetEnvContext = React.createContext<{
  frame?: HTMLElement;
  environment: Environment;
  config: WidgetInternalConfig;
  type: WidgetType;
}>({
  frame: undefined,
  environment: defaultWidgetEnvironment,
  config: defaultWidgetInternalConfig,
  type: WidgetType.FLOATING,
});
