/**
 * @fileOverview
 * @name EventQueuingService.ts
 * @author Taketoshi Aono
 * @license
 */

import { Queue } from '@aim/shared/src/structs/Queue';
import { DisplayableMessageFormat } from '@s/components/atom/WidgetMessageConfig';

export interface EventQueuingApplicationService {
  enqueue(a: {
    message: DisplayableMessageFormat;
    onSendEvent(message: DisplayableMessageFormat): void;
  }): Promise<void>;
}

const enum Status {
  STOPPED,
  RUNNING,
}

export class EventQueuingService implements EventQueuingApplicationService {
  private readonly messageQueue = new Queue<DisplayableMessageFormat>();
  private status = Status.STOPPED;

  public async enqueue({
    message,
    onSendEvent,
  }: Parameters<EventQueuingApplicationService['enqueue']>[0]): ReturnType<
    EventQueuingApplicationService['enqueue']
  > {
    const handleRecursivePush = async (seq: number) => {
      const next = this.messageQueue.pop();
      if (!next) {
        this.status = Status.STOPPED;
        return;
      }

      if (next.isDelayNextMessage) {
        const { delayValue } = next;
        const {
          meta: { uuid, ...meta },
          ...message
        } = next;
        const newMessage = {
          ...message,
          id: `${message.id!}_$seq-${seq}`,
          at: next.at + seq,
          meta: { ...meta, uuid: `${uuid}-$seq-${seq}` },
        };
        onSendEvent(newMessage);
        setTimeout(() => {
          handleRecursivePush(seq + 1);
        }, delayValue);
      } else {
        const newMessage = {
          ...next,
          at: next.at + seq,
        };
        onSendEvent(newMessage);
        await handleRecursivePush(seq + 1);
      }
    };

    this.messageQueue.push(message);
    if (this.status === Status.STOPPED) {
      this.status = Status.RUNNING;
      handleRecursivePush(0);
    }
  }
}
