/**
 * @fileOverview
 * @name styleAttributesProxyPolyfill.ts
 * @author Taketoshi Aono
 * @license
 */

const createStyleAttributesList = () => {
  const htmlElements =
    'address article aside footer header h1 h2 h3 h4 h5 h6 hgroup main nav section blockquote dd div dl dt figcaption figure hr li main ol p pre ul a abbr b bdi bdo br cite code data dfn em i kbd mark q rb rp rt rtc ruby s samp small span strong sub sup time u var wbr area audio img map track video embed iframe object param picture source del ins caption col colgroup table tbody td tfoot th thead tr button datalist fieldset form input label legend meter optgroup option output progress select textarea details dialog menu summary';
  const properties: { [key: string]: boolean } = {};
  const functions: { [key: string]: boolean } = {};
  htmlElements.split(' ').forEach(name => {
    const node = document.createElement(name);
    for (const key in node.style) {
      if (typeof node.style[key] === 'function') {
        functions[key] = true;
      } else {
        properties[key] = true;
      }
    }
  });

  return { attributes: Object.keys(properties), functions: Object.keys(functions) };
};

export const createStyleProxyPolyfill = () => {
  const STYLE_ATTRIBUTES = createStyleAttributesList();

  class PolyfillConstructor {
    public constructor(protected target: HTMLElement, protected style: CSSStyleDeclaration) {}
  }
  Object.defineProperties(
    PolyfillConstructor.prototype,
    Object.assign(
      STYLE_ATTRIBUTES.attributes.reduce((map, attribute) => {
        const name = attribute.replace(/([a-z][A-Z])/g, ($0, $1) => {
          if ($1) {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return `${$1.slice(0, 1)}-${$1.slice(1).toLowerCase()}`;
          }
          return $0;
        });
        map[attribute] = {
          get(this: PolyfillConstructor) {
            return this.style[name as any];
          },
          set(this: PolyfillConstructor, value: string) {
            this.style.setProperty(name, value, 'important');
          },
        };
        return map;
      }, {} as PropertyDescriptorMap),
      STYLE_ATTRIBUTES.functions.reduce((map, fnName) => {
        map[fnName] = {
          get(this: PolyfillConstructor) {
            return (...args: any[]) => (this.style[fnName as any] as any)(...args);
          },
          set(this: PolyfillConstructor, value: string) {
            this.style[fnName as any] = value;
          },
        };

        return map;
      }, {} as PropertyDescriptorMap),
      {
        cssText: {
          get(this: PolyfillConstructor) {
            return this.style.cssText;
          },
          set(this: PolyfillConstructor, value: string) {
            this.style.cssText = value;
          },
        },
      }
    )
  );

  return (el: HTMLElement, style: CSSStyleDeclaration) => {
    return new PolyfillConstructor(el, style);
  };
};
