/**
 * @fileOverview
 * @name ChatStateRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { Auth } from '@w/domain/entities/Auth';
import { UpdatableRepository } from '@s/Repository';
import { fetchService } from '@s/io/fetchService';
import { staticConfig } from '@w/config';

export type UserDataUpdatableRepository = UpdatableRepository<
  { auth: Auth; userData: { [key: string]: string } },
  {}
>;

export class UserDataRepository implements UserDataUpdatableRepository {
  public async update({
    auth,
    userData,
  }: Parameters<UserDataUpdatableRepository['update']>[0]): Promise<{}> {
    return fetchService(`${staticConfig.customerEndpoint}/customer/additionals`, {
      method: 'PATCH',
      headers: {
        authorization: `Bearer ${auth.token}`,
        accept: 'application/json',
      },
      data: {
        additionals: userData,
      },
      responseType: 'json',
    });
  }
}
