/**
 * @fileOverview
 * @name lockable.ts
 * @author Taketoshi Aono
 * @license
 */

export const lockable = (target: any, name: string) => {
  const original = target[name];
  let isLocked = false;
  target[name] = function (...args: any[]) {
    if (isLocked) {
      return;
    }
    const ret = original.apply(this, args);
    if (ret.then) {
      const unlock = () => (isLocked = false);
      ret.then(() => unlock());
    } else {
      isLocked = false;
    }
    return ret;
  };
};
