/**
 * @fileoverview
 * @author
 */

import React from 'react';
import { Root, createRoot } from 'react-dom/client';
import window, { AimWidgetInitialConfig } from '@w/window';
import { isUrlOrDomainContainedInWhitelist } from '@w/util/isUrlOrDomainContainedInWhitelist';
import { initStore } from '@w/store';
import { WIDGET_HEIGHT, WIDGET_WIDTH } from '@s/components/atom/WidgetConstant';
import { isDebuggerEnabled } from '@s/debugger/isDebuggerEnabled';
import { Provider } from 'react-redux';
import { WidgetContainer } from './container/widgetContianer';
import { LLMWidgetContainer } from './container/LLMWidgetContianer';
import {
  WidgetConfiguration,
  defaultWidgetConfiguration,
  WidgetEventHandlers,
  mergeCustomization,
} from '@s/domain/values/WidgetConfiguration';
import { WidgetProps } from '@s/components/ecosystem/Widget';
import {
  getWidgetConfig,
  WidgetEventType,
  defaultWidgetInternalConfig,
} from '@s/components/atom/WidgetConfig';
import { getWidgetInitialConfig } from '@w/application/WidgetInitialConfig';
import { WidgetType } from '@s/components/atom/WidgetType';
import { WidgetEnvContext } from '@s/components/atom/WidgetEnvContext';
import { ThemeProvider } from '@emotion/react';
import { publicApi } from './util/createApiProxy';
import { staticConfig, runtimeConfig, FRAME_NAME, FRAME_EMBED_NAME } from './config';
import { registerPreFetchIntercepter } from '@s/io/fetchService';
import { AIM_WIDGET_ROOT_ID } from '@s/dom/widgetRootId';
import { storeEnvToState, prepareWelcomeMessage } from '@w/module/env/usecase';
import { updateTokenIfNecessary, storePreviewToken } from '@w/module/auth/usecase';
import {
  completeChatPreparation,
  cleanupChat,
  initializeChatMessages,
  openChat,
  closeChat,
  initializeChatState,
  reportCrashed,
} from '@w/module/chat/usecase';

import { ThunkDeps } from './store/ThunkDeps';
import { chatOpened } from './module/chat/action';
import { Environment, WidgetEnvironment } from '@s/platform/Environment';
import { sendData } from './module/userData/usecase';

export const ENV_PREFIX = staticConfig.envPrefix;
export const KEY_SUFFIX = `${ENV_PREFIX}_${getWidgetInitialConfig().tenantId}`;

export type AiMessengerWidgetFunctions = {
  [P in WidgetEventType]: ((...args: any[]) => any)[];
};

const isFunction = (fn: any): fn is (...args: any[]) => any => {
  return typeof fn === 'function';
};

type DeviceType = 'mobile' | 'console' | 'tablet';
const getDeviceType = (deviceType: string | undefined): DeviceType => {
  switch (deviceType) {
    case 'mobile':
    case 'console':
    case 'tablet':
      return deviceType;
    default:
      return 'console';
  }
};

const isEnabledDebug = () => {
  return new URL(window.parent.location.href).searchParams.get('aim_debug_log') === '1';
};

const isEnableLongPolling = () => {
  return new URL(window.parent.location.href).searchParams.get('aim_long_polling') === '1';
};

// AiMessenger
// wrapperタグを挿入して、widgetを生成する
export class AiMessenger {
  // configurationを取得する
  private static async getConfiguration(
    config: AimWidgetInitialConfig,
    deviceType: DeviceType = 'console'
  ) {
    const file = deviceType === 'mobile' ? 'configuration-sp.json' : 'configuration.json';
    const endpoint = `https://${
      config.dedicatedEnvConfigJson?.serverName
        ? `${config.dedicatedEnvConfigJson?.serverName}-`
        : ''
    }${staticConfig.scriptEndpoint}/configuration/${config.tenantName}/${
      config.projectId
    }/${file}?_=${Date.now()}`;
    try {
      const response = await fetch(endpoint);
      if (deviceType === 'mobile' && !response.ok) {
        const pcConfig: WidgetConfiguration = await AiMessenger.getConfiguration(config);
        return pcConfig;
      }
      const data: WidgetConfiguration = response.ok
        ? await response.json()
        : defaultWidgetConfiguration;
      return data;
    } catch (e) {
      console.error(e);
      if (deviceType === 'mobile') {
        const pcConfig: WidgetConfiguration = await AiMessenger.getConfiguration(config);
        return pcConfig;
      }
      return defaultWidgetConfiguration;
    }
  }

  private aimWidget: HTMLElement | null = null;
  private readonly config = getWidgetInitialConfig();
  private custom = defaultWidgetInternalConfig;
  private readonly deps: ThunkDeps;
  private readonly environment: Environment;
  private readonly hook: AiMessengerWidgetFunctions = {
    immediate: [],
    mount: [],
    open: [],
    openOnce: [],
    close: [],
    linkClick: [],
    buttonClick: [],
  };
  private isMatchedWithWhitelist = false;
  private readonly store: ReturnType<typeof initStore>['store'];
  private widgetEnabled = false;
  private readonly widgetType: WidgetType;
  private root: Root | undefined;

  public constructor() {
    const { store, deps } = initStore(this.config, isEnabledDebug(), isEnableLongPolling());
    this.store = store;
    this.deps = deps;
    this.widgetType = this.config.type;
    this.environment = new WidgetEnvironment(this.config.devices);
    this.registerPreConfiguredCallbacks(this.config.events);

    registerPreFetchIntercepter(async (url, req) => {
      if (url.includes('/customer') || url.includes('welcome_message')) {
        return req;
      }
      await this.store.dispatch(updateTokenIfNecessary() as any);
      if (!req.headers) {
        req.headers = {};
      }
      req.headers['Authorization'] = `Bearer ${this.store.getState().auth.auth.token}`;
      req.headers['X-AIM-Subdomain'] = this.store.getState().env.tenantName;
      return req;
    });

    this.store.dispatch(storeEnvToState(this.config) as any);
    this.store.dispatch(initializeChatState() as any);
    if (this.config.__preview?.token) {
      this.store.dispatch(storePreviewToken({ token: this.config.__preview.token }) as any);
    }
    if (this.config.type === WidgetType.EMBEDDED) {
      this.store.dispatch(chatOpened() as any);
    }
    if (this.config.forceSupportAs) {
      this.environment.forceSupportAs(this.config.forceSupportAs);
    }
  }

  @publicApi
  public close(): void {
    this.store.dispatch(closeChat({ shouldDisplayMiniFloat: false }) as any);
  }

  @publicApi
  public async destroy(shouldDeleteAll: boolean) {
    if (this.aimWidget) {
      this.root?.unmount?.();
      this.aimWidget.remove();
    }
    const webpackChunkKey = `__aimwidget__${this.version}`;
    if (window[webpackChunkKey]) {
      delete window[webpackChunkKey];
    }
    delete window.aimWidgetInitialConfigs;
    delete window.aiMessenger;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.config?.persistency === 'session' || shouldDeleteAll) {
      await new Promise<void>(resolve => {
        this.store.dispatch(cleanupChat({ onDelete: resolve }) as any);
      });
    }
  }

  @publicApi
  public disableLogging() {
    runtimeConfig.disableLogging();
  }

  @publicApi
  public async dispatch(type: WidgetEventType, ...args: any[]) {
    const callbacks = this.hook[type];
    if (callbacks.length) {
      return callbacks.reduce(async (p: Promise<any>, cb: (...args: any[]) => any) => {
        await p;
        return cb(...args);
      }, Promise.resolve(true));
    }
  }

  @publicApi
  public enableLogging() {
    runtimeConfig.enableLogging();
  }

  @publicApi
  public get isDomainContainsInWhitelist(): boolean {
    return this.isMatchedWithWhitelist;
  }

  @publicApi
  public on(type: string, handler: (...args: any[]) => any) {
    if (type in this.hook) {
      this.hook[type as WidgetEventType].push(handler);
    }
  }

  @publicApi
  public open(): void {
    this.store.dispatch(openChat() as any);
  }

  public async prepareDebuggerIfNecessary(): Promise<void> {
    if (isDebuggerEnabled(window, location.search, document.referrer)) {
      await import(/* webpackChunkName: "debugger" */ '@w/debugger').then(
        async ({ widgetDebugger }) => {
          const previewOption = await widgetDebugger({
            origin: staticConfig.debuggerSubjectOrigin,
            environment: this.environment,
            configuration: await this.fetchConfiguration(),
            type: this.widgetType,
          });
          if (previewOption) {
            this.config.__preview = previewOption;
            this.store.dispatch(storePreviewToken({ token: this.config.__preview.token }) as any);
          }
        }
      );
    }
  }

  @publicApi
  public get ready(): boolean {
    return this.widgetEnabled;
  }

  /**
   * 初回起動処理
   * @return {*}
   */
  @publicApi
  public async run(): Promise<void> {
    if (isEnabledDebug()) {
      runtimeConfig.enableLogging();
    }
    if (this.widgetEnabled) {
      return;
    }
    if (this.widgetType === WidgetType.FLOATING && document.getElementById(FRAME_NAME)) {
      return;
    }
    if (this.widgetType === WidgetType.EMBEDDED && !document.getElementById(FRAME_EMBED_NAME)) {
      return;
    }
    if (!this.environment.isSupportedEnvironment) {
      return;
    }

    const configuration = await this.fetchConfiguration();
    const { whitelist, customization, events } = configuration;

    if (events) {
      this.registerPreConfiguredCallbacks(
        Object.keys(events).reduce((h: WidgetEventHandlers, k: any) => {
          try {
            // eslint-disable-next-line @typescript-eslint/no-implied-eval
            h[k as keyof WidgetEventHandlers] = Function(
              `return function(${events[k as keyof WidgetEventHandlers]?.[0] ?? ''}) {${
                events[k as keyof WidgetEventHandlers]?.[1] ?? ''
              }}`
            )();
          } catch (e: any) {
            this.store.dispatch(reportCrashed(e) as any);
          }
          return h;
        }, {} as WidgetEventHandlers)
      );
    }

    this.custom = getWidgetConfig(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !this.config?.customization
        ? customization
        : mergeCustomization(customization, this.config.customization),
      async (type: WidgetEventType, ...args: any[]) => this.dispatch(type, ...args)
    );

    const hostWhitelist = (whitelist as any).acceptDomain || whitelist.acceptDomains;
    const acceptUrl = whitelist.acceptUrl;
    const checkList = acceptUrl || hostWhitelist;
    const target = acceptUrl
      ? `${window.parent.location.protocol}//${window.parent.location.host}${window.parent.location.pathname}${window.parent.location.search}${window.parent.location.hash}`
      : window.parent.location.host;
    if (!checkList || !isUrlOrDomainContainedInWhitelist(checkList, target)) {
      // 許可されていないドメインであればであれば以後の処理を止める
      return;
    }
    this.isMatchedWithWhitelist = true;
    this.getWelcomeMessage();
    this.runInternal();
    this.store.dispatch(initializeChatMessages() as any);
  }

  @publicApi
  public sendData(data: { [key: string]: string }): void {
    this.store.dispatch(sendData(data) as any);
  }

  @publicApi
  public get version(): string {
    return import.meta.env.SOURCE_HASH;
  }

  /**
   * floating用のframeを生成する
   * @private
   * @return {*}
   * @memberof AiMessenger
   */
  private createFloatingFrame(): HTMLElement {
    const frame = document.createElement('div');
    frame.id = AIM_WIDGET_ROOT_ID;
    frame.style.border = 'none !important';
    frame.style.position = 'fixed !important';
    frame.style.bottom = '5px !important';
    frame.style.right = '5px !important';
    frame.style.zIndex = '2147483645 !important';
    frame.style.transition = 'box-shadow 100ms ease-out 0s !important';
    frame.style.overflow = 'hidden !important';
    frame.style.color = '#333333 !important';
    this.aimWidget = frame;

    return frame;
  }

  private async fetchConfiguration(): Promise<WidgetConfiguration> {
    const deviceType: DeviceType = getDeviceType(this.environment.platform.device.type);
    const configuration: WidgetConfiguration =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      this.config?.__preview?.configuration ??
      (await AiMessenger.getConfiguration(this.config, deviceType));
    return configuration;
  }

  /**
   * モバイルのサポート状況をチェックする
   * @private
   * @return {string}
   */
  private getMobileSupport(): string {
    if (parent.matchMedia('(max-device-width: 767px)').matches) {
      const meta = document.getElementsByTagName('meta');

      const metaCont: string[] = [];
      Array.prototype.forEach.call(meta, v => {
        metaCont.push(v.content);
      });

      if (/width=device-width|initial-scale=1/.test(metaCont.join())) {
        return 'supported';
      } else {
        return 'unsupported';
      }
    } else {
      return 'no';
    }
  }

  /**
   * Welcome Message 読み込み
   * @private
   */
  private async getWelcomeMessage(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.config?.__preview?.welcomeMessage) {
      this.store.dispatch(
        prepareWelcomeMessage({
          welcomeMessage: this.config.__preview.welcomeMessage,
        }) as any
      );
      this.store.dispatch(completeChatPreparation() as any);
      return;
    }
    return new Promise(resolve => {
      this.store.dispatch(prepareWelcomeMessage({ onComplete: resolve }) as any);
    });
  }

  /**
   * frameにcomponentを挿入
   * @param {HTMLElement}  frame
   * @private
   */
  private insertFrameBody(frame: HTMLElement): void {
    const initProps: WidgetProps = {
      isChattingWithOperator: false,
      isDisplayTyping: false,
      shouldDisplayMiniFloat: false,
      config: this.custom,
      messages: [],
      mediaCache: {},
      backgroundColor: '#ffffff',
      borderRadius: 5,
      enableCloseButton: this.config.type === WidgetType.FLOATING,
      enableRefreshButton: true,
      isInputLoading: false,
      enableImageUpload: true,
      type: this.widgetType,
      onClose: () => {},
      onMount: () => {},
      onSendMessage: () => {},
      onSendImage: () => {},
      onCacheMedia: () => {},
      isChatLoading: false,
      isDesignerMode: false,
      isChatOpen: false,
      isOperatorChatting: false,
      onOpen: () => {},
      onUserInput: () => {},
    };

    this.root?.render?.(
      <WidgetEnvContext.Provider
        value={{
          frame,
          environment: this.environment,
          config: this.custom,
          type: this.widgetType,
        }}
      >
        <Provider store={this.store}>
          <ThemeProvider theme={this.custom}>
            {this.config.mode === 'llm' ? (
              <LLMWidgetContainer
                {...initProps}
                store={this.store}
                reportCrashed={this.deps.reportCrashed}
                design={this.config.design}
              />
            ) : (
              <WidgetContainer
                {...initProps}
                store={this.store}
                reportCrashed={this.deps.reportCrashed}
                design={this.config.design}
              />
            )}
          </ThemeProvider>
        </Provider>
      </WidgetEnvContext.Provider>
    );
    this.widgetEnabled = true;
  }

  /**
   * embed用のwrapperタグを生成する
   * @private
   * @param {*} frame
   * @return {HTMLElement}
   * @memberof AiMessenger
   */
  private makeEmbeddedFrame(frame: HTMLElement): HTMLElement {
    this.aimWidget = frame;
    return frame;
  }

  /**
   * Register function that configured in swallow_widget_initialconfig.
   * @param {Object} events
   */
  private registerPreConfiguredCallbacks(events?: WidgetEventHandlers): void {
    if (!events) {
      return;
    }
    if (isFunction(events.onImmediate)) {
      this.hook.immediate.push(events.onImmediate);
    }
    if (isFunction(events.onMount)) {
      this.hook.mount.push(events.onMount);
    }
    if (isFunction(events.onOpen)) {
      this.hook.open.push(events.onOpen);
    }
    if (isFunction(events.onClose)) {
      this.hook.close.push(events.onClose);
    }
    if (isFunction(events.onLinkClick)) {
      this.hook.linkClick.push(events.onLinkClick);
    }
    if (isFunction(events.onButtonClick)) {
      this.hook.buttonClick.push(events.onButtonClick);
    }
  }

  /**
   * 親タグ挿入
   * @private
   */
  private runInternal(): void {
    if (this.widgetType === WidgetType.EMBEDDED) {
      const embeddedFrame = document.getElementById(FRAME_EMBED_NAME);
      if (embeddedFrame) {
        this.root = createRoot(embeddedFrame);
        embeddedFrame.style.display = '';
        this.makeEmbeddedFrame(embeddedFrame);
        this.insertFrameBody(embeddedFrame);
      } else {
        console.error(
          `AIMessenger can't find a container element\Usually this error caused because AIMessenger couldn't find embedded container tag '<div id="aim-widget-frame-embed"></div>' in your html.`
        );
      }
    } else {
      const frame = this.createFloatingFrame();
      const mobile = this.getMobileSupport();
      this.root = createRoot(frame);
      if (mobile === 'unsupported') {
        frame.style.width = frame.style.height = '220px !important';
      } else {
        frame.style.width = `${WIDGET_WIDTH}px !important`;
        frame.style.height = `${WIDGET_HEIGHT}px !important`;
      }
      document.body.appendChild(frame);
      this.insertFrameBody(frame);
    }
  }
}
