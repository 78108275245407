/**
 * @fileOverview
 * @name usecase.ts
 * @author Taketoshi Aono
 * @license
 */

import { ReduxState } from '@w/domain/entities/State';
import { Dispatch } from 'redux';
import { ThunkDeps } from '@w/store/ThunkDeps';
import { authSucceeded, previewTokenStored } from '@w/module/auth/action';

export const updateTokenIfNecessary =
  () =>
  async (
    dispatch: Dispatch,
    getState: () => ReduxState,
    { firestoreService, reportCrashed }: ThunkDeps
  ) => {
    const { auth } = getState().auth;
    try {
      const newToken = await firestoreService.updateTokenIfNecessary({ auth });
      if (newToken) {
        dispatch(authSucceeded({ ...auth, token: newToken }));
      }
    } catch (error: any) {
      reportCrashed({ error, state: getState() });
    }
  };

export const storePreviewToken =
  ({ token }: { token: string }) =>
  async (dispatch: Dispatch) => {
    dispatch(previewTokenStored({ token }));
  };
