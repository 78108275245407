/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { WidgetContext } from './WidgetContext';
import { smallTextStyle } from './Text';
import { compareOnlyProperties } from '@s/compareOnlyProperties';

const BitElement = styled(motion.div)`
  will-change: transform, opacity;
  width: 5px;
  height: 5px;
  background: #ccc;
  border-radius: 100px;
  margin-right: 10px;
`;

const TypingContainerElement = styled(motion.div)`
  padding: 10px;
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
`;

const TextContainerElement = styled.div`
  white-space: nowrap;
  margin-right: 10px;
  ${smallTextStyle};
`;

const CustomerInputElement = styled.span`
  color: #ccc;
  margin: 0px 10px;
  display: block;
  max-width: 200px;
  word-break: break-all;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
`;

const TEXT = '入力中';
const INITIAL = { translateX: -10, opacity: 0 };
const ANIMATE = { translateX: 0, opacity: [1, 0] };
const MAX_CUSTOMER_INPUT_LENGTH = 150;
export const WidgetTyping = compareOnlyProperties(
  ({
    isOperatorMode,
    isOperatorChatting = false,
    customerInput,
    y,
  }: {
    isOperatorMode: boolean;
    isOperatorChatting?: boolean;
    customerInput: string;
    y: number;
  }) => {
    const { config } = useContext(WidgetContext);
    const rootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (rootRef.current) {
        rootRef.current.style.setProperty(
          'top',
          `${y - rootRef.current.clientHeight}px`,
          'important'
        );
      }
    }, [y, rootRef.current, customerInput]);

    return (
      <TypingContainerElement ref={rootRef}>
        <TextContainerElement
          css={{
            color: '#CCC',
            // For safari
            WebkitTextFillColor: '#CCC',
          }}
        >
          <span>{`${
            isOperatorMode ? 'カスタマーが' : isOperatorChatting ? 'オペレーターが' : 'ボットが'
          }${TEXT}`}</span>
        </TextContainerElement>
        {isOperatorMode ? (
          <CustomerInputElement>
            {customerInput.length > MAX_CUSTOMER_INPUT_LENGTH
              ? `${customerInput.slice(0, MAX_CUSTOMER_INPUT_LENGTH)}...`
              : customerInput}
          </CustomerInputElement>
        ) : null}
        <BitElement
          css={{ background: config.chatColoring.common.color }}
          initial={INITIAL}
          animate={ANIMATE}
          transition={{
            type: 'tween',
            duration: 1,
            loop: Infinity,
            delay: 0,
            repeatDelay: 1.6,
          }}
        />
        <BitElement
          css={{ background: config.chatColoring.common.color }}
          initial={INITIAL}
          animate={ANIMATE}
          transition={{
            type: 'tween',
            duration: 1,
            loop: Infinity,
            delay: 0.3,
            repeatDelay: 1.6,
          }}
        />
        <BitElement
          css={{ background: config.chatColoring.common.color }}
          initial={INITIAL}
          animate={ANIMATE}
          transition={{
            type: 'tween',
            duration: 1,
            loop: Infinity,
            delay: 0.6,
            repeatDelay: 1.6,
          }}
        />
      </TypingContainerElement>
    );
  }
);
