/**
 * @fileoverview
 * @author
 */

import window, { AimWidgetInitialConfig } from '@w/window';
import { WidgetType } from '@s/components/atom/WidgetType';

const widgetInitialConfigDefault: AimWidgetInitialConfig = {
  devices: ['PC', 'iOS', 'Android'],
  persistency: 'local',
  projectId: '',
  tenantId: '',
  tenantName: '',
  userData: {},
  type: WidgetType.FLOATING,
};

const config: AimWidgetInitialConfig = {
  ...widgetInitialConfigDefault,
  ...(() => {
    const config = window.aimWidgetInitialConfigs;
    if (config) {
      try {
        config.dedicatedEnvConfigJson = JSON.parse(atob(config.dedicatedEnvConfig!));
      } catch (e) {}
    }
    return config;
  })(),
};

export const getWidgetInitialConfig = (): AimWidgetInitialConfig => {
  return config;
};
