import React from 'react';

export const CrossIcon = React.memo(() => {
  return (
    <span aria-hidden={true}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.93341 15.5832L0.416748 14.0665L6.48342 7.99984L0.416748 1.93317L1.93341 0.416504L8.00008 6.48317L14.0667 0.416504L15.5834 1.93317L9.51675 7.99984L15.5834 14.0665L14.0667 15.5832L8.00008 9.5165L1.93341 15.5832Z"
          fill="#0F172A"
        />
      </svg>
    </span>
  );
});
