import { CreatableRepository } from '@s/Repository';
import { fetchService } from '@s/io/fetchService';

type LLMMessageRequest = {
  message: string;
  senderMessageId: string;
};

export type LLMMessageCreatableRepository = CreatableRepository<
  {
    data: LLMMessageRequest;
    projectId: string;
    headers?: { [key: string]: string };
  },
  void
>;

export class LLMMessageRepository {
  public constructor(private readonly getEndpoint: () => string) {}

  public async create({
    data,
    projectId,
    headers = {},
  }: Parameters<LLMMessageCreatableRepository['create']>[0]): Promise<void> {
    fetchService(`${this.getEndpoint()}/llmchat/project/${projectId}`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      data,
    });
  }
}
