/**
 * @fileOverview
 * @name EventRequestService.ts
 * @author Taketoshi Aono
 * @license
 */

import { rng } from '@w/util/md5';
import { SendEventRequestType, EventRequestParam } from '@s/domain/values/EventRequestParam';
import { MediaEntity } from '@s/domain/entity/Media';

export interface EventRequestApplicationService {
  initRequestPayload({
    type,
    message,
    uuid,
    payload,
  }: {
    type: SendEventRequestType;
    message?: string;
    mediaEntity?: MediaEntity;
    uuid?: string;
    payload?: string;
  }): EventRequestParam;
}

export class EventRequestService {
  public initRequestPayload({
    type,
    message,
    mediaEntity,
    uuid = rng(),
    payload,
  }: Parameters<EventRequestApplicationService['initRequestPayload']>[0]): ReturnType<
    EventRequestApplicationService['initRequestPayload']
  > {
    switch (type) {
      case 'message':
        return {
          type: 'message',
          uuid,
          meta: { uuid },
          at: new Date().getTime(),
          message: message,
        };
      case 'image':
        if (mediaEntity === undefined) {
          throw new Error('No mediaEntity');
        }
        return {
          type: 'image',
          uuid,
          meta: { uuid },
          at: new Date().getTime(),
          image: {
            mediaId: mediaEntity.id,
          },
          imageUrl: {
            url: mediaEntity.url,
            previewUrl: mediaEntity.url,
          },
        };
      case 'postback':
        return {
          type: 'postback',
          uuid,
          meta: { uuid },
          at: new Date().getTime(),
          postback: {
            text: message!,
            payload: payload!,
          },
        };
      case 'reset':
        return {
          type: 'reset',
          uuid,
          meta: { uuid },
          at: Date.now(),
        };
      default:
        throw new Error('Unsupported event type');
    }
  }
}
