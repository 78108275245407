/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React from 'react';

export const PictureIcon = ({ color }: { color: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <title>icon</title>
    <path
      d="M487.57.5H12.43A12,12,0,0,0,.5,12.43V487.57A12,12,0,0,0,12.43,499.5H487.57a12,12,0,0,0,11.93-11.93V12.43A12,12,0,0,0,487.57.5ZM459,41V368.28C426.19,323.45,362.82,240,346,240c-23,0-125,139-125,139s-51-61-79-61c-20.17,0-73,63.29-101,98.7V41Z"
      fill={color}
      css={{ transition: 'all 0.2s' }}
    />
    <circle cx="131" cy="127" r="68" fill={color} css={{ transition: 'all 0.2s' }} />
  </svg>
);
