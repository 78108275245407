/**
 * @fileOverview
 * @name action.ts<chat>
 * @author Taketoshi Aono
 * @license
 */

import { ChatStateValue } from '@w/application/value/ChatStateValue';
import { Console } from '@w/util/Console';

export const chatLoadingChanged = (isChanged: boolean) => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  Console.info(`call chatLoadingChanged = ${isChanged}`);
  return {
    type: 'AIM_WIDGET_CHAT_LOADING_CHANGED' as const,
    loading: isChanged,
  };
};

export const inputLoadingChanged = (isChanged: boolean) => {
  return {
    type: 'AIM_WIDGET_INPUT_LOADING_CHANGED' as const,
    loading: isChanged,
  };
};

export const chatOpened = () => {
  return {
    type: 'AIM_WIDGET_OPENED' as const,
  };
};

export const chatClosed = (payload: { shouldDisplayMiniFloat: boolean }) => {
  return {
    type: 'AIM_WIDGET_CLOSED' as const,
    payload,
  };
};

export const operatorChatStarted = ({ isStart }: { isStart: boolean }) => {
  return {
    type: 'AIM_WIDGET_OPERATOR_CHAT_STARTED' as const,
    payload: isStart,
  };
};

export const chatStateInitialized = (payload: ChatStateValue) => {
  return {
    type: 'CHAT_STATE_INITIALIZED' as const,
    payload,
  };
};
