/**
 * @fileOverview
 * @name usecase.ts<chat>
 * @author Taketoshi Aono
 * @license
 */

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import {
  chatLoadingChanged,
  inputLoadingChanged,
  chatOpened,
  chatClosed,
  operatorChatStarted,
  chatStateInitialized,
} from './action';
import { ReduxState } from '@w/domain/entities/State';
import { ThunkDeps } from '@w/store/ThunkDeps';
import { authSucceeded } from '../auth/action';
import {
  messagesReceived,
  welcomeMessageTransferingRequired,
  operatorTypingChanged,
  isCustomerImageUploadEnabledChanged,
  messageUpdated,
} from '../message/action';
import { displayMessage } from '../message/usecase';
import { required } from '@s/assertions';
import { createRetryHandler } from '@aim/shared/src/io/createRetryHandler';
import { Console } from '@w/util/Console';
import { convertMessageFormatToWidgetMessage } from '@s/components/atom/WidgetMessageConfig';

type Dispatch = ThunkDispatch<ReduxState, ThunkDeps, Action>;

export const initializeChatState =
  () =>
  async (dispatch: Dispatch, unused: any, { chatStateRepository }: ThunkDeps) => {
    const state = await chatStateRepository.create();
    dispatch(chatStateInitialized(state));
  };

export const reportCrashed =
  (error: Error) =>
  async (dispatch: Dispatch, getState: () => ReduxState, { reportCrashed }: ThunkDeps) => {
    reportCrashed({ error, state: getState() });
  };

export const completeChatPreparation = () => async (dispatch: Dispatch) => {
  dispatch(chatLoadingChanged(false));
};

export const haltChatPreparation = () => async (dispatch: Dispatch) => {
  dispatch(chatLoadingChanged(true));
};

export const completeInputPreparation = () => async (dispatch: Dispatch) => {
  dispatch(inputLoadingChanged(false));
};

export const haltInputPreparation = () => async (dispatch: Dispatch) => {
  dispatch(inputLoadingChanged(true));
};

export const openChat =
  () =>
  async (
    dispatch: Dispatch,
    getState: () => ReduxState,
    { chatStateRepository, firestoreService }: ThunkDeps
  ) => {
    await chatStateRepository.update({ isMinimized: false });
    firestoreService.getWelcomeMessageOnClosed({
      state: getState(),
      onAddMessage: message => {
        dispatch(displayMessage(message));
      },
      onWelcomeMessageTransferRequired: () => dispatch(welcomeMessageTransferingRequired()),
    });
    dispatch(chatOpened());
  };

export const closeChat =
  ({ shouldDisplayMiniFloat }: { shouldDisplayMiniFloat: boolean }) =>
  async (dispatch: Dispatch, unused: any, { chatStateRepository }: ThunkDeps) => {
    await chatStateRepository.update({ isMinimized: shouldDisplayMiniFloat });
    dispatch(chatClosed({ shouldDisplayMiniFloat }));
  };

const initializationRetryHandler = createRetryHandler();
export const initializeChatMessages =
  () =>
  async (
    dispatch: Dispatch,
    getState: () => ReduxState,
    { firestoreService, reportCrashed }: ThunkDeps
  ) => {
    Console.info('call initializeChatMessages');
    const init = async () => {
      const state = getState();
      if (!state.env.welcomeMessage) {
        return new Promise<void>(resolve => {
          return setTimeout(async () => {
            await init();
            resolve();
          }, 200);
        });
      }
    };
    await init();
    Console.info('call init');
    try {
      await initializationRetryHandler('initializeChatMessages', async () => {
        Console.info('call initializationRetryHandler');
        const state = getState();
        const isLoggedIn = await firestoreService.isLoggedIn();
        if (!isLoggedIn) {
          dispatch(welcomeMessageTransferingRequired());
        }
        // If persistency would be 'session', system will send reset event in the middle of initialization.
        // So, this time we should skip initialization.
        if ((!isLoggedIn && !state.auth.previewToken) || state.env.persistency === 'session') {
          dispatch(
            displayMessage(
              convertMessageFormatToWidgetMessage(required(getState().env.welcomeMessage))
            )
          );
          return dispatch(chatLoadingChanged(false));
        }
        Console.info('call initializeOnce');
        await firestoreService.initializeOnce({
          getState,
          onWelcomeMessageTransferRequired: () => dispatch(welcomeMessageTransferingRequired()),
          onOperatorChattingStateChange: ({ isStart }) =>
            dispatch(operatorChatStarted({ isStart })),
          onLogin: auth => {
            dispatch(authSucceeded(auth));
          },
          onBatchAddMessage: messages => {
            dispatch(messagesReceived(messages));
          },
          onModifyMessage: message => dispatch(messageUpdated(message)),
          onAddMessage: message => {
            dispatch(displayMessage(message));
          },
          onTyping: a => {
            dispatch(operatorTypingChanged(a));
          },
          onUpdateEnableCustomerImageUploadState: a => {
            dispatch(isCustomerImageUploadEnabledChanged(a));
          },
        });
        dispatch(chatLoadingChanged(false));
        Console.info('initializeChatMessages end');
      });
    } catch (error: any) {
      reportCrashed({ error, state: getState() });
    }
  };

export const cleanupChat =
  ({ onDelete }: { onDelete?(): void }) =>
  async (unused: Dispatch, unusedState: () => ReduxState, { firestoreService }: ThunkDeps) => {
    await firestoreService.delete();
    onDelete ? onDelete() : null;
  };
