/**
 * @fileOverview
 * @name reducer.ts<chat>
 * @author Taketoshi Aono
 * @license
 */

import { ChatState, initialState } from '@w/domain/entities/State';
import {
  chatLoadingChanged,
  inputLoadingChanged,
  chatOpened,
  chatClosed,
  operatorChatStarted,
  chatStateInitialized,
} from '@w/module/chat/action';
import produce from 'immer';

type ActionType = ReturnType<
  | typeof chatLoadingChanged
  | typeof inputLoadingChanged
  | typeof chatOpened
  | typeof chatClosed
  | typeof operatorChatStarted
  | typeof chatStateInitialized
>;

export default (state = initialState.chat, action: ActionType): ChatState => {
  return produce(state, draft => {
    switch (action.type) {
      case 'AIM_WIDGET_CHAT_LOADING_CHANGED':
        draft.inputLoading = draft.loading = action.loading;
        return;
      case 'AIM_WIDGET_INPUT_LOADING_CHANGED':
        draft.inputLoading = action.loading;
        return;
      case 'AIM_WIDGET_OPENED':
        draft.open = true;
        draft.shouldDisplayMiniFloat = false;
        return;
      case 'AIM_WIDGET_CLOSED':
        draft.open = false;
        draft.shouldDisplayMiniFloat = action.payload.shouldDisplayMiniFloat;
        return;
      case 'AIM_WIDGET_OPERATOR_CHAT_STARTED':
        draft.isOperatorChatting = action.payload;
        return;
      case 'CHAT_STATE_INITIALIZED':
        draft.shouldDisplayMiniFloat = action.payload.isMinimized;
        return;
      default:
        const unusedCheck: never = action;
        return draft;
    }
  });
};
