import React from 'react';
import { motion } from 'framer-motion';
import { WidgetChatWindowBackground } from '../molecule/WidgetChatWindowBackground';
import styled from '@emotion/styled';
import { WidgetTalkArea, WidgetTalkAreaProps } from './WidgetTalkArea';
import { WidgetMessageInput, WidgetMessageInputProps } from './WidgetMessageInput';
import { DisplayableMessageFormat } from '../atom/WidgetMessageConfig';
import { WidgetType } from '../atom/WidgetType';
import { useRefState } from '@s/reactHooks';
import { compareOnlyProperties } from '@s/compareOnlyProperties';

export type WidgetChatWindowProps = Omit<WidgetMessageInputProps, 'textInputState' | 'visible'> &
  WidgetTalkAreaProps & {
    isInputLoading: boolean;
    messages: DisplayableMessageFormat[];
    onMaximize: () => void;
    onMinimize: () => void;
    onClose: () => void;
  };

const WidgetChatWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const RootContainerElement = motion.div;

export const WidgetChatWindow = compareOnlyProperties(
  ({
    loading,
    type,
    messages,
    mediaCache,
    isDesignerMode = false,
    isInputLoading,
    isOperatorMode = false,
    onClose,
    onMaximize,
    onMinimize,
    onSendMessage = () => {},
    onScroll,
    onUserInput,
    onCacheMedia,
  }: WidgetChatWindowProps) => {
    let latestMessageFromBot;
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i].sender.type === 'Bot') {
        latestMessageFromBot = messages[i];
        break;
      }
    }
    const [isFocused, setFocused] = useRefState(false);

    const textInputState = latestMessageFromBot ? latestMessageFromBot.textInputState : 'show';

    const widgetChat = (
      <WidgetChatWindowBackground type={type}>
        <WidgetChatWindowContainer>
          <WidgetTalkArea
            loading={loading || !messages.length}
            messages={messages}
            mediaCache={mediaCache}
            isInputFocused={isFocused.current}
            isOperatorMode={isOperatorMode}
            onScroll={onScroll}
            onUserInput={onUserInput}
            onCacheMedia={onCacheMedia}
          />
          <WidgetMessageInput
            visible={true}
            loading={isInputLoading || loading || !messages.length}
            textInputState={textInputState}
            type={type}
            onSendMessage={onSendMessage}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onClose={onClose}
            onUserInput={onUserInput}
            onMaximize={onMaximize}
            onMinimize={onMinimize}
            isDesignerMode={isDesignerMode}
          />
        </WidgetChatWindowContainer>
      </WidgetChatWindowBackground>
    );
    return type === WidgetType.FLOATING ? (
      <RootContainerElement
        initial={{ translateX: 20, opacity: 0 }}
        animate={{ translateX: 0, opacity: 1 }}
        exit={{
          translateX: 20,
          opacity: 0,
          transition: { type: 'tween', duration: 0.2, ease: 'anticipate' },
        }}
        transition={{ type: 'spring', damping: 13 }}
        css={{
          transformOrigin: 'bottom right',
          background: 'transparent',
          width: '100%',
          height: '100%',
        }}
      >
        {widgetChat}
      </RootContainerElement>
    ) : (
      widgetChat
    );
  }
);
