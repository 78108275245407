import React, { useRef, useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { largeTextStyle } from '@s/components/atom/Text';
import { Button } from '@s/components/atom/Button';
import { scrollbarStyle } from '@s/components/atom/ScrollbarStyle';
import { motion, AnimatePresence } from 'framer-motion';
import { WidgetContext } from '@s/components/atom/WidgetContext';
import { WidgetEnvContext } from '@s/components/atom/WidgetEnvContext';
import { InputStateValues } from '@s/domain/values/InputStateValues';
import { isValidInputValueForInputState } from '@s/domain/specification/WidgetInputValueSpecification';
import { Box, Flex } from '@s/components/atom/Box';
import { CrossIcon } from './CrossIcon';
import { CenterIcon } from './CenterIcon';
import { CornerIcon } from './CornerIcon';
import { SendIcon } from './SendIcon';
import { WidgetType } from '@s/components/atom/WidgetType';

const WidgetMessageInputContainerElement = styled(motion.div)`
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  overflow: hidden;
  z-index: 2;
`;

const WidgetInternalRootContainerElement = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
`;

const MessageFormContainer = styled.div`
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  padding: 4px;
  margin: 10px;
  box-sizing: content-box;
`;

const MessageForm = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: content-box;
`;

const TextArea = styled.textarea`
  top: 0px;
  left: 0px;
  position: absolute;
  width: calc(100% - 50px);
  margin: 0;
  border: 0;
  padding: 10px;
  resize: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  ${largeTextStyle};
  height: 100%;
  outline: none;
  background: #fff;
  box-sizing: border-box;
  ${scrollbarStyle};
`;
const TextAreaHeightAdjuster = styled.div<{ isPc: boolean }>`
  visibility: hidden;
  padding: 10px;
  width: 100%;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  min-height: 24px;
  max-height: ${p => (p.isPc ? '300px' : '20vh')};
  ${largeTextStyle};
  box-sizing: content-box;
`;

const SendButtonContainerElement = styled.div`
  margin-right: 10px;
`;

const ButtonsWrapperElement = styled(Flex)`
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 10px;
  justify-content: space-between;
  width: 60px;
`;

const SVGIconButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  color: black;
  border: none;
  outline: none;

  &:focus {
    box-shadow: none;
  }
`;

export interface WidgetMessageInputProps {
  visible: boolean;
  loading: boolean;
  type: WidgetType;
  textInputState: InputStateValues;
  isDesignerMode?: boolean;
  onSendMessage?(msg: string): void;
  onUserInput(a: { text: string; isTyping: boolean }): void;
  onMaximize(): void;
  onMinimize(): void;
}

export const WidgetMessageInput = ({
  visible,
  loading,
  textInputState,
  isDesignerMode = false,
  type,
  onSendMessage = () => {},
  onFocus,
  onBlur,
  onClose,
  onUserInput,
  onMaximize,
  onMinimize,
}: WidgetMessageInputProps & { onFocus(): void; onBlur(): void; onClose(): void }) => {
  const [text, updateText] = useState('');
  const textArea = useRef<HTMLTextAreaElement | null>(null);
  const { environment } = useContext(WidgetEnvContext);
  const { config } = useContext(WidgetContext);
  const deleteInputTimerRef = useRef<any>(null);
  const updateInputTimerRef = useRef<any>(null);
  const [widgetStatus, setWidgetStatus] = useState<'min' | 'max'>('min');

  useEffect(() => {
    if (
      environment.isSupportedPcBrowser &&
      visible &&
      textArea.current &&
      !loading &&
      type == WidgetType.FLOATING
    ) {
      textArea.current.focus();
    }
  }, [loading, textArea.current, textInputState]);

  useEffect(() => {
    clearTimeout(deleteInputTimerRef.current);
    clearTimeout(updateInputTimerRef.current);
    if (text) {
      updateInputTimerRef.current = setTimeout(() => {
        onUserInput({ text: text, isTyping: true });
        deleteInputTimerRef.current = setTimeout(() => {
          clearTimeout(updateInputTimerRef.current);
          onUserInput({ text: '', isTyping: false });
        }, 20000);
      }, 500);
    } else {
      clearTimeout(updateInputTimerRef.current);
      onUserInput({ text: '', isTyping: false });
    }
  }, [text]);
  const tree = visible ? (
    <WidgetMessageInputContainerElement
      key="1"
      initial={{
        maxHeight: 0,
      }}
      animate={{
        maxHeight: 500,
      }}
      exit={{
        maxHeight: 0,
        transition: { type: 'tween', duration: 0.4 },
      }}
      transition={{ type: 'tween', duration: 1, delay: 0.6 }}
    >
      <WidgetInternalRootContainerElement>
        <MessageFormContainer>
          <MessageForm>
            <TextArea
              placeholder={'なんでも聞いてください'}
              ref={textArea}
              disabled={loading || isDesignerMode}
              value={text}
              tabIndex={loading ? -1 : 0}
              onFocus={() => onFocus()}
              onBlur={() => onBlur()}
              onChange={e => {
                const value = e.target.value;
                if (
                  isValidInputValueForInputState({
                    state: textInputState,
                    value,
                  })
                ) {
                  updateText(value);
                }
              }}
              onKeyUp={e => {
                if (e.key === 'Enter' && (e.shiftKey || e.ctrlKey)) {
                  if (text.length === 0) {
                    return;
                  }
                  onSendMessage(text);
                  updateText('');
                  e.preventDefault();
                }
              }}
            />
            <TextAreaHeightAdjuster isPc={environment.isSupportedPcBrowser} aria-hidden={true}>
              {text.replace(/\n/g, '\n_')}
            </TextAreaHeightAdjuster>
            <SendButtonContainerElement>
              <Button
                tabIndex={loading ? -1 : 0}
                type={{
                  color: config.chatColoring.common.color,
                  background: config.chatColoring.common.backgroundHover,
                }}
                iconSvg={<SendIcon color={config.chatColoring.common.color} />}
                label=""
                onClick={() => {
                  if (text.length === 0) {
                    return;
                  }
                  clearTimeout(updateInputTimerRef.current);
                  onUserInput({ text: '', isTyping: false });
                  onSendMessage(text);
                  updateText('');
                }}
              />
            </SendButtonContainerElement>
          </MessageForm>
        </MessageFormContainer>
        {type !== WidgetType.EMBEDDED && (
          <ButtonsWrapperElement
            css={
              environment.isSupportedMobileBrowser && {
                width: '14px',
              }
            }
          >
            {environment.isSupportedPcBrowser ? (
              widgetStatus === 'min' ? (
                <Box>
                  <SVGIconButton
                    onClick={() => {
                      onMaximize();
                      setWidgetStatus('max');
                    }}
                  >
                    <CenterIcon />
                  </SVGIconButton>
                </Box>
              ) : (
                <Box>
                  <SVGIconButton
                    onClick={() => {
                      onMinimize();
                      setWidgetStatus('min');
                    }}
                  >
                    <CornerIcon />
                  </SVGIconButton>
                </Box>
              )
            ) : null}
            <Box>
              <SVGIconButton onClick={onClose}>
                <CrossIcon />
              </SVGIconButton>
            </Box>
          </ButtonsWrapperElement>
        )}
      </WidgetInternalRootContainerElement>
    </WidgetMessageInputContainerElement>
  ) : null;

  return <AnimatePresence>{tree}</AnimatePresence>;
};
