/**
 * @fileoverview
 * @author
 */
import { DisplayableMessageFormat } from '@s/components/atom/WidgetMessageConfig';
import {
  ChatMessage,
  defaultWelcomeMessage,
  MessageFormat,
  TextInputState,
} from '@s/domain/entity/MessageFormat';
import { rng } from '@s/rng';

export interface WelcomeMessageFormat {
  messages: ChatMessage[];
  version: string;
  meta?: {
    textInputState?: TextInputState;
  };
}

export const WELCOME_MESSAGE_DATETIME = +new Date('1970/01/01 00:00:00');

export const convertToWelcomeMessageToWidgetMessageFormat = (
  welcomeMessages: ChatMessage[],
  textInputState?: TextInputState
): MessageFormat => {
  return {
    at: WELCOME_MESSAGE_DATETIME,
    id: 'welcome-message',
    messages: [welcomeMessages[0]],
    sender: {
      id: 'Bot',
      type: 'Bot',
    },
    meta: {
      uuid: 'welcome-message',
      textInputState,
    },
    type: 'message',
  };
};

export const uniquifyWelcomeMessageId = (welcomeMessage: DisplayableMessageFormat, at: number) => {
  const id = `welcome-message-${rng()}`;
  return {
    ...welcomeMessage,
    uuid: id,
    at,
    id,
    meta: {
      uuid: id,
      textInputState: welcomeMessage.textInputState ?? 'show',
    },
  };
};

export const defaultWelcomeMessageFormat = convertToWelcomeMessageToWidgetMessageFormat([
  defaultWelcomeMessage,
]);
