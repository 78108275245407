/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React from 'react';
import styled from '@emotion/styled';
import { MessageSender } from '@s/domain/entity/MessageFormat';

const AvatarContainer = styled.div`
  border-radius: 50px;
  width: 50px;
  height: 50px;
`;

export interface WidgetAvatarProps {
  sender: MessageSender;
  pictureUrl: string;
}

export const WidgetAvatar = ({ pictureUrl, sender }: WidgetAvatarProps) => {
  return (
    <AvatarContainer
      role="img"
      aria-label="avatar"
      aria-roledescription={sender === 'Bot' ? 'Chatbotのアイコン' : 'オペレータのアイコン'}
      css={{
        backgroundImage: `url(${pictureUrl})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    />
  );
};
