/**
 * @fileOverview
 * @name reducer.ts<message>
 */

import { MessageState, initialState } from '@w/domain/entities/State';
import {
  messagesReceived,
  eventRequested,
  eventRequestSucceeded,
  eventRequestFailed,
  messageReceived,
  messageDeleted,
  welcomeMessageTransferingRequired,
  operatorTypingChanged,
  mediaCached,
  isCustomerImageUploadEnabledChanged,
  messageUpdated,
} from './action';
import produce from 'immer';
import { MessageService } from '@w/domain/service/message/MessageService';
import { Console } from '@w/util/Console';

type ActionType = ReturnType<
  | typeof messagesReceived
  | typeof eventRequested
  | typeof eventRequestSucceeded
  | typeof eventRequestFailed
  | typeof messageReceived
  | typeof messageUpdated
  | typeof messageDeleted
  | typeof welcomeMessageTransferingRequired
  | typeof operatorTypingChanged
  | typeof mediaCached
  | typeof isCustomerImageUploadEnabledChanged
>;

export default (createMessageService: (state: MessageState) => MessageService) =>
  (state: MessageState = initialState.message, action: ActionType): MessageState => {
    return produce(state, draft => {
      Console.info(action.type);
      switch (action.type) {
        case 'AIM_WIDGET_EVENT_REQUESTED': {
          draft.loading = true;
          if (action.payload.eventRequest.type === 'reset') {
            return draft;
          }
          // 再リクエストしたuuidをエラーから削除する
          const ids = draft.error.uuid.filter(id => {
            return id !== action.payload.eventRequest.uuid;
          });
          draft.error.uuid = ids;
          const updater = createMessageService(draft);
          updater.insertDummy(action.payload.eventRequest);
          if (!action.payload.isOperatorChatting) {
            updater.setTyping();
          } else {
            updater.removeTyping();
          }
          return draft;
        }
        case 'AIM_WIDGET_MESSAGE_SUCCEEDED':
          draft.shouldTransferWelcomeMessage = false;
          draft.loading = false;
          return draft;
        case 'AIM_WIDGET_EVENT_FAILED': {
          draft.loading = false;
          const arr: string[] = state.error.uuid.slice();
          arr.push(action.uuid);
          draft.error = {
            msg: action.error,
            uuid: arr,
          };
          createMessageService(draft).removeTyping();
          return draft;
        }
        case 'AIM_WIDGET_MESSAGES_RECEIVED': {
          const updater = createMessageService(draft);
          updater.batchUpdateMessages(action.messages);
          return;
        }
        case 'AIM_WIDGET_MESSAGE_RECEIVED':
          createMessageService(draft).updateMessage(action.message);
          return;

        case 'AIM_WIDGET_MESSAGE_UPDATED': {
          const updater = createMessageService(draft);
          action.message.forEach(m => {
            updater.modifyMessage(m);
          });
          return;
        }

        case 'AIM_WIDGET_MESSAGE_DELETED':
          createMessageService(draft).deleteDummyMessage(action.uuid);
          return;
        case 'WELCOME_MESSAGE_TRANSFERING_REQUIRED':
          draft.shouldTransferWelcomeMessage = true;
          draft.isCustomerImageUploadEnabled = false;
          return;
        case 'AIM_WIDGET_OPERATOR_TYPING_CHANGED':
          const updater = createMessageService(draft);
          if (action.payload) {
            updater.setTyping();
          } else {
            updater.removeTyping();
          }
          return;
        case 'AIM_WIDGET_MEDIA_CACHED':
          draft.mediaCache[action.payload.url] = action.payload.cacheUrl;
          return draft;
        case 'AIM_WIDGET_IS_CUSTOMER_IMAGE_UPLOAD_ENABLED_CHANGED':
          draft.isCustomerImageUploadEnabled = action.payload;
          return draft;
        default:
          return draft;
      }
    });
  };
