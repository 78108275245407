import { createContext, useCallback, useState } from 'react';

type Layout = 'corner' | 'center';

type WidgetLayoutContext = {
  layout: Layout;
  setLayout: (layout: Layout) => void;
};

const defaultContext: WidgetLayoutContext = {
  layout: 'corner',
  setLayout: () => {},
};

export const WidgetLayoutContext = createContext<WidgetLayoutContext>(defaultContext);

export const useWidgetLayout = (): WidgetLayoutContext => {
  const [layout, _setLayout] = useState<Layout>('corner');
  const setLayout = useCallback((layout: Layout): void => {
    _setLayout(layout);
  }, []);
  return {
    layout,
    setLayout,
  };
};
