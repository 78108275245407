/// <reference types="vite/client" />

export const FIRESTORE_MESSAGE_FETCH_LIMIT = 20;

export const FRAME_NAME = 'aim-widget-frame';
export const FRAME_EMBED_NAME = 'aim-widget-frame-embed';
export const VERSION_KEY = 'AIM_VERSION';

export interface AimWidgetStaticConfig {
  apiKey: string;
  authDomain: string;
  databaseUrl: string;
  storageBucket: string;
  envPrefix: string;
  env: string;
  messageEndpoint: string;
  llmMessageEndpoint: string;
  customerEndpoint: string;
  mediaEndpoint: string;
  scriptEndpoint: string;
  projectId: string;
  appId: string;
  debuggerSubjectOrigin: string;
  sentry: {
    dsn: string;
  };
}

// export const staticConfig: AimWidgetStaticConfig = (env as any)({
export const staticConfig: AimWidgetStaticConfig = {
  env: import.meta.env.VITE_ENV,
  envPrefix: import.meta.env.VITE_ENV_PREFIX,
  scriptEndpoint: import.meta.env.VITE_SCRIPT_ENDPOINT,
  messageEndpoint: import.meta.env.VITE_MESSAGE_ENDPOINT,
  llmMessageEndpoint: import.meta.env.VITE_LLM_MESSAGE_ENDPOINT,
  customerEndpoint: import.meta.env.VITE_CUSTOMER_ENDPOINT,
  mediaEndpoint: import.meta.env.VITE_MEDIA_ENDPOINT,
  apiKey: import.meta.env.VITE_APIKEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  databaseUrl: import.meta.env.VITE_DATABASE_URL,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  appId: import.meta.env.VITE_APP_ID,
  debuggerSubjectOrigin: import.meta.env.VITE_DEBUGGER_SUBJECT_ORIGIN,
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
  },
};

const runtimeConfigs = {
  isLoggingEnabled: false,
};
export const runtimeConfig = {
  isLoggingEnabled() {
    return runtimeConfigs.isLoggingEnabled;
  },
  enableLogging() {
    runtimeConfigs.isLoggingEnabled = true;
  },
  disableLogging() {
    runtimeConfigs.isLoggingEnabled = false;
  },
};
