/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button } from '../atom/Button';
import { WidgetContext } from '../atom/WidgetContext';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { WidgetEnvContext } from '../atom/WidgetEnvContext';
import { WIDGET_WIDTH } from '../atom/WidgetConstant';

const MessageContainer = styled.div`
  display: inline-block;
  margin-bottom: 10px;
`;

const QuickRepliesButtonContainerElement = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const QuickRepliesButtonItemContainerElement = styled.div<{ isOldBrowser: boolean }>`
  margin: 0 10px 10px 0;
  max-width: 100%;
  > button {
    ${p => (p.isOldBrowser ? `max-width: ${WIDGET_WIDTH - 115}px` : '')};
  }
`;
const buttonStyle = css`
  max-width: 100%;
  display: block;
  line-height: 2px;
  min-height: 35px;
  height: auto;
  > span > span {
    max-width: 100%;
    word-break: break-all;
    overflow-wrap: anywhere;
    display: block;
    width: 100%;
    white-space: break-spaces;
    line-height: 1.3rem;
  }
`;

export interface WidgetPlainTextMessageProps {
  children: React.ReactNode;
  buttons: { type: string; label: string; payload: string }[];
  isOperatorMode: boolean;
  onSendQuickRepliesPostPack?(msg: string, payload: string): void;
}

export const WidgetQuickRepliesMessage = compareOnlyProperties(
  ({
    children,
    buttons,
    isOperatorMode,
    onSendQuickRepliesPostPack = () => {},
  }: WidgetPlainTextMessageProps) => {
    const { config } = useContext(WidgetContext);
    const { environment } = useContext(WidgetEnvContext);
    return (
      <div css={{ width: '100%' }}>
        <MessageContainer>{children}</MessageContainer>
        <QuickRepliesButtonContainerElement
          css={
            environment.platform.browser.name === 'IE' &&
            environment.platform.browser.major === '11'
              ? { maxWidth: WIDGET_WIDTH - 115 }
              : {}
          }
        >
          {buttons.map(button => {
            return (
              <QuickRepliesButtonItemContainerElement
                key={button.label}
                isOldBrowser={
                  environment.platform.browser.name === 'IE' &&
                  environment.platform.browser.major === '11'
                }
              >
                <Button
                  disabled={isOperatorMode}
                  additionalCss={buttonStyle}
                  label={button.label}
                  type={'quickReplies'}
                  onClick={() => {
                    onSendQuickRepliesPostPack(button.label, button.payload);
                    config.dispatch('buttonClick', { label: button.label });
                  }}
                />
              </QuickRepliesButtonItemContainerElement>
            );
          })}
        </QuickRepliesButtonContainerElement>
      </div>
    );
  },
  'WidgetQuickRepliesMessage'
);
