/**
 * @fileoverview
 * @author
 */

export const WIDGET_WIDTH = 420;
export const WIDGET_HEIGHT = 660;
export const WIDGET_RADIUS = 14;
export const WIDGET_HEADER_HEIGHT = 70;
export const BALLOON_MARGIN = 20;
export const BALLOON_WIDTH = 290;
export const BALLOON_HEIGHT = 70;

export const getWidgetHeight = (positionFromBottomEdge: number, height: number) => {
  return window.innerHeight < height + positionFromBottomEdge
    ? window.innerHeight > 300
      ? window.innerHeight - positionFromBottomEdge
      : 300
    : height;
};
