/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { regularTextStyle } from '../atom/Text';
import { compareOnlyProperties } from '@s/compareOnlyProperties';

export interface WidgetIconBalloonProps {
  text: string;
}

const leftBottomStyle = css`
  left: -5px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
`;
const rightBottomStyle = css`
  right: -5px;
  box-shadow: -2px 2px 20px rgba(0, 0, 0, 0.2);
`;

const Balloon = styled.section`
  width: 290px;
  height: 70px;
  background: #fff;
  box-shadow: -1px 1px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: relative;
  &:before {
    content: '';
    display: block;
    transform: rotate(45deg);
    background: #fff;
    position: absolute;
    top: calc(50% - 5px);
    height: 10px;
    width: 10px;
    ${p => (p.theme.iconPosition === 'left-bottom' ? leftBottomStyle : rightBottomStyle)};
  }

  &:after {
    border-radius: 8px;
    content: '';
    display: block;
    background: #fff;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 70px;
    width: 20px;
  }
`;

const TextElement = styled.article`
  ${regularTextStyle};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

const TextElementInner = styled.div`
  margin-left: 10px;
  width: calc(100% - 30px);
  word-break: break-word;
  word-wrap: break-word;
  text-overflow: elipsis;
`;

export const WidgetIconBalloon = compareOnlyProperties(
  ({ text = 'ようこそ' }: WidgetIconBalloonProps) => {
    return (
      <Balloon>
        <TextElement>
          <TextElementInner>{text.slice(0, 60)}</TextElementInner>
        </TextElement>
      </Balloon>
    );
  }
);
