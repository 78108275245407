/**
 * @fileOverview
 * @author Taketoshi Aono
 * @license
 */

import { AIM_WIDGET_ROOT_ID } from './widgetRootId';
import { createStyleProxyPolyfill } from './styleAttributesProxyPolyfill';

const HAS_PROXY = typeof Proxy !== 'undefined';

const styleHolder: any = HTMLElement.prototype.hasOwnProperty('style')
  ? HTMLElement.prototype
  : Element.prototype;
const styleDescriptor: PropertyDescriptor | undefined = Object.getOwnPropertyDescriptor(
  styleHolder,
  'style'
);
const styleAttributeProxyPolyfill = !HAS_PROXY ? createStyleProxyPolyfill() : () => {};

const styleProxy = (target: HTMLElement, style: CSSStyleDeclaration) => {
  return new Proxy(style, {
    get(target: any, name: string) {
      return typeof target[name] === 'function' ? target[name].bind(target) : target[name];
    },
    set(target: any, name: string, value: any) {
      if (name !== 'cssText') {
        target.setProperty(
          name.replace(/([a-z][A-Z])/g, ($0, $1) => {
            if ($1) {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              return `${$1.slice(0, 1)}-${$1.slice(1).toLowerCase()}`;
            }
            return $0;
          }),
          value,
          'important'
        );
      } else {
        target.cssText = value;
      }
      return true;
    },
  });
};

const isContainsInRoot = (root: HTMLElement | null, child: HTMLElement) => {
  if (root) {
    return root.contains(child);
  }
  if (child.getAttribute('data-widget-contains')) {
    return true;
  }
  let n: HTMLElement | null = child;
  while (n && n !== document.body) {
    if (n.id === AIM_WIDGET_ROOT_ID) {
      child.setAttribute('data-widget-contains', '1');
      return true;
    }
    n = n.parentElement;
  }
  return false;
};

if (styleDescriptor) {
  let rootElementCache: HTMLElement | null = null;
  const disableStyleGetter = () => {
    Object.defineProperty(styleHolder, 'style', styleDescriptor);
  };
  const enableStyleGetter = () => {
    Object.defineProperty(styleHolder, 'style', {
      configurable: true,
      enumerable: true,
      get(this: HTMLElement): any {
        disableStyleGetter();
        const original = this.style;
        enableStyleGetter();
        const root = rootElementCache
          ? (rootElementCache = document.getElementById(AIM_WIDGET_ROOT_ID))
          : rootElementCache;
        if (this.id !== AIM_WIDGET_ROOT_ID && !isContainsInRoot(root, this)) {
          return original;
        }

        return HAS_PROXY ? styleProxy(this, original) : styleAttributeProxyPolyfill(this, original);
      },
    });
  };
  enableStyleGetter();
}
