/**
 * @fileOverview
 * @name widgetImagePopupConfig.ts
 * @author Taketoshi Aono
 * @license
 */

import { css } from '@emotion/react';
import { unsetStyles } from '@s/components/atom/unsetStyles';

export const IMAGEBOX_CONTAINER_ID = 'aim__image-box-container';
export const IMAGEBOX_ID = 'aim__container__image-box';

const globalStyles = css`
  html body #${IMAGEBOX_CONTAINER_ID} {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 2147483646;
  }

  html body #${IMAGEBOX_ID} {
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #fff;
    position: relative;
    box-shadow: 0 0.9px 0.9px rgba(0, 0, 0, 0.028), 0 2.1px 2.2px rgba(0, 0, 0, 0.041),
      0 3.8px 3.9px rgba(0, 0, 0, 0.051), 0 6.3px 6.4px rgba(0, 0, 0, 0.059),
      0 10.3px 10.6px rgba(0, 0, 0, 0.069), 0 18px 18.5px rgba(0, 0, 0, 0.082),
      0 39px 40px rgba(0, 0, 0, 0.11);
  }

  html body #aim__widget-image-box__close-icon-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background: #ddd;
    > svg {
      width: 10px;
      height: 10px;
    }
    path {
      transition: all 0.3s;
    }
    &:hover {
      path {
        fill: #0099ff;
      }
    }
    &:focus {
      path {
        fill: #0099ff;
      }
      box-shadow: 0px 0px 4px rgba(0, 0, 255, 0.6);
    }
  }
`;
export const imagePopupGlobalStyles = css(globalStyles, unsetStyles(`#${IMAGEBOX_ID}`));
