/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React from 'react';
import styled from '@emotion/styled';

const PathElement = styled.path<{ fill: string }>`
  transition: fill 0.2s;
  fill: ${p => p.fill};
`;
export const RefreshIcon = ({ color }: { color: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <PathElement
      d="M492.66,222.28,465.54,45.37l-41.26,30A246.34,246.34,0,0,0,253.35,6.69C117.7,6.69,7.34,115.84,7.34,250s110.36,243.31,246,243.31c113.32,0,208.93-76.19,237.36-179.48,0,0-6-14.55-24.47-20.71s-37.33,11.45-37.33,11.45C405.42,378.35,335.61,432,253.35,432c-101.47,0-184-81.64-184-182s82.56-182,184-182a184.32,184.32,0,0,1,120.19,44.35l-48.88,34.82Z"
      fill={color}
    />
  </svg>
);
