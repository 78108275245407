/**
 * @fileoverview
 * @author
 */

export interface RgbColor {
  r: number;
  g: number;
  b: number;
  a?: number;
}

const hex2rgb = (hex: string) => {
  if (hex.startsWith('#')) {
    hex = hex.slice(1);
  }
  if (hex.length <= 3) {
    return [hex.slice(0, 1), hex.slice(1, 2), hex.slice(2, 3)].map(str => {
      return parseInt(`${str}${str}`, 16);
    });
  }
  return [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)].map(str => {
    return parseInt(str, 16);
  });
};

export const parseHexToRgbColor = (hex: string): RgbColor => {
  const color = hex2rgb(hex);
  return {
    r: color[0],
    g: color[1],
    b: color[2],
    a: 1,
  };
};

type ColorMapper = { [P in 'a' | 'r' | 'g' | 'b']?: (value: number) => number };

export const serializeRgbColor = (obj: RgbColor, map: ColorMapper = {}) => {
  const a = obj.a && obj.a > 0 ? obj.a : 0;
  return `rgba(${map['r'] ? map['r'](obj.r) : obj.r},${map['g'] ? map['g'](obj.g) : obj.g},${
    map['b'] ? map['b'](obj.b) : obj.b
  },${map['a'] ? map['a'](a) : a})`;
};

export const getRgbColor = (hex: string, map: ColorMapper = {}) => {
  if (!hex.startsWith('#')) {
    return hex;
  }
  const color = parseHexToRgbColor(hex);
  return serializeRgbColor(color, map);
};
