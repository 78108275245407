/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { largeTextStyle } from '../atom/Text';
import { WidgetClose, WidgetCloseProps } from '../atom/WidgetClose';
import { WIDGET_HEADER_HEIGHT } from '../atom/WidgetConstant';
import { WidgetContext } from '../atom/WidgetContext';
import { WidgetRefreshIcon } from '../atom/WidgetRefreshIcon';
import { AnimatePresence, motion } from 'framer-motion';

const HeaderContainerElement = styled.header`
  flex-shrink: 0;
  width: 100%;
`;

const HeaderTextContainerElement = styled.h1`
  font-weight: bold;
  text-align: center;
  padding: 10px 90px;
  overflow: hidden;
  ${largeTextStyle};
`;

const HeaderUpperElement = styled.div`
  width: 100%;
  height: ${WIDGET_HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WidgetIconContainer = styled.div`
  display: flex;
  position: absolute;
  top: 25px;
  right: 25px;
`;

const WidgetCloseIconContainer = styled.div``;
const WidgetRefreshIconContainer = styled(motion.div)`
  margin-right: 10px;
`;

export type WidgetHeaderProps = Pick<WidgetCloseProps, 'onClose'> & {
  isDesignerMode?: boolean;
  headerText: string;
  enableRefreshButton: boolean;
  enableCloseButton: boolean;
  onRefresh?(): void;
};

const MAX_TEXT_LENGTH = 32;

export const WidgetHeader = ({
  headerText,
  enableRefreshButton,
  enableCloseButton,
  onClose,
  isDesignerMode = false,
  onRefresh = () => {},
}: WidgetHeaderProps) => {
  const { config } = useContext(WidgetContext);
  return (
    <HeaderContainerElement>
      <HeaderUpperElement css={{ background: config.chatColoring.common.background }}>
        <HeaderTextContainerElement
          css={{
            color: config.chatColoring.common.color,
            // For safari
            WebkitTextFillColor: config.chatColoring.common.color,
          }}
        >
          {headerText.slice(0, MAX_TEXT_LENGTH)}
        </HeaderTextContainerElement>
        <WidgetIconContainer>
          <AnimatePresence>
            {enableRefreshButton ? (
              <WidgetRefreshIconContainer
                key="icon"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'tween', duration: 0.4 }}
                onClick={() => {
                  onRefresh();
                }}
              >
                <WidgetRefreshIcon width={18} height={18} isDesignerMode={isDesignerMode} />
              </WidgetRefreshIconContainer>
            ) : null}
            {enableCloseButton ? (
              <WidgetCloseIconContainer>
                <WidgetClose
                  width={18}
                  height={18}
                  onClose={onClose}
                  isDesignerMode={isDesignerMode}
                />
              </WidgetCloseIconContainer>
            ) : null}
          </AnimatePresence>
        </WidgetIconContainer>
      </HeaderUpperElement>
    </HeaderContainerElement>
  );
};
