import React, { useEffect, useRef } from 'react';
import * as smoothscroll from 'smoothscroll-polyfill';
import { PASSIVE } from '@s/detectDomFeature';
import { useScrollFix } from '@s/platform/genericMobile/useScrollFix';
import { useAndroidKeyboardScrollFix } from '@s/platform/android/useAndroidKeyboardScrollFix';
import { useAnimationFrameFixer } from '@s/dom/useAnimationFrameFixer';
import { useOrientationChange } from '@s/platform/genericMobile/useOrientationChange';
import { useRefState } from '@s/reactHooks';
import { DisplayableMessageFormat } from '@s/components/atom/WidgetMessageConfig';
import { Environment } from '@s/platform/Environment';
import { useKeyboardAppearanceWaiter } from '@s/platform/generic/useKeyboardAppearanceWaiter';

class WidgetScrollDataCache {
  public clientHeight = 0;
  public height = 0;
  public isAutoScrolling = false;
  public isScrollToBottom = true;
  public isUserScrollingOnMobileDevice = false;
  public scrollHeight = 0;
  public top = 0;
}

export const useWidgetScrolling = ({
  messages,
  environment,
  rootElementRef,
  isInputFocused,
  onScroll,
}: {
  messages: ReadonlyDeep<DisplayableMessageFormat[]>;
  environment: Environment;
  rootElementRef: React.RefObject<HTMLElement | null>;
  isInputFocused: boolean;
  onScroll(messages: ReadonlyDeep<DisplayableMessageFormat[]>): void;
}) => {
  // スクロールの情報
  const [scrollDimension, setScrollDimension] = useRefState(new WidgetScrollDataCache());
  // 現在取得している最古のメッセージの timestamp
  const currentOldestPastMessageTime = useRef(0);
  const messagesRef = useRef(messages);
  const [prevMessageLength, setPrevMessageLength] = useRefState(0);
  const shouldStopInterScrollingProcess = useRef(false);
  messagesRef.current = messages;
  const keyboardAppearanceWaiter = useKeyboardAppearanceWaiter({
    isInputFocused: isInputFocused,
    containerElementRef: rootElementRef,
  });

  // スクロールイベントのコールバックを設定
  const scrollCallback = useScrollFix({
    environment,
    scrollCallback: useAnimationFrameFixer(() => {
      if (!rootElementRef.current || shouldStopInterScrollingProcess.current) {
        return;
      }
      if (!scrollDimension.current.isAutoScrolling) {
        scrollDimension.current.top = rootElementRef.current.scrollTop;
        scrollDimension.current.isScrollToBottom = shouldAutoScroll();
        if (rootElementRef.current.scrollTop <= 10) {
          onScroll(messagesRef.current);
        }
      } else if (
        environment.isSupportedMobileBrowser &&
        scrollDimension.current.isAutoScrolling &&
        shouldAutoScroll()
      ) {
        scrollDimension.current.isAutoScrolling = false;
      }
    }),
    containerElementRef: rootElementRef,
  });

  const scrollCallbackRef = useRef(scrollCallback);
  scrollCallbackRef.current = scrollCallback;

  const scrollToBottom = ({ behavior }: { behavior?: 'smooth' | 'auto' } = {}) => {
    if (!rootElementRef.current) {
      return;
    }
    const scrollHeight = rootElementRef.current.scrollHeight;
    const scrollTo = window.scrollTo;
    smoothscroll.polyfill();
    scrollDimension.current.top = scrollHeight - rootElementRef.current.clientHeight;
    scrollDimension.current.isAutoScrolling = true;
    rootElementRef.current.scrollTo({
      top: scrollDimension.current.top,
      behavior: behavior ?? currentOldestPastMessageTime.current ? 'smooth' : 'auto',
    });
    scrollDimension.current.height = scrollHeight;
    window.scrollTo = scrollTo;

    if (!currentOldestPastMessageTime.current) {
      const update = () => {
        setTimeout(() => {
          if (!rootElementRef.current) {
            return;
          }
          const next = rootElementRef.current.scrollHeight - rootElementRef.current.clientHeight;
          if (rootElementRef.current.scrollTop < next - 20) {
            rootElementRef.current.scrollTo({
              top: next,
            });
            update();
          }
        }, 400);
      };
      update();
    }
  };

  /**
   * この関数の役割
   * - 新しいメッセージが追加された際に自動的に最新のメッセージにスクロールする
   * - ユーザーが過去のメッセージを読むためにスクロールした際に適切に表示を更新する
   */
  const interScrollingProcess = useAnimationFrameFixer(() => {
    if (!rootElementRef.current) {
      return;
    }

    if (messages.length === 0) {
      scrollDimension.current.top = 0;
      return;
    }

    if (messages[0].at < currentOldestPastMessageTime.current) {
      // 上にスクロールして過去のメッセージを取得したときの処理
      const scrollHeight = rootElementRef.current.scrollHeight;
      const nextScrollTop = scrollHeight - scrollDimension.current.height;
      rootElementRef.current.scrollTop = nextScrollTop;
      scrollDimension.current.top = nextScrollTop;
      scrollDimension.current.height = scrollHeight;
    } else if (
      scrollDimension.current.isScrollToBottom &&
      !scrollDimension.current.isUserScrollingOnMobileDevice
    ) {
      scrollToBottom();
    }

    if (messages.length > 0) {
      currentOldestPastMessageTime.current = messages[0].at;
    }
  });

  /**
   * 自動スクロールすべきかを判定する
   * 最新のメッセージに近い位置のときは場合は自動スクロールする
   */
  const shouldAutoScroll = (): boolean => {
    if (!rootElementRef.current) {
      return true;
    }
    return (
      rootElementRef.current.scrollTop >
      scrollDimension.current.scrollHeight - scrollDimension.current.clientHeight - 20
    );
  };

  useAndroidKeyboardScrollFix({
    environment,
    isInputFocused: true,
    onTick() {
      if (
        !scrollDimension.current.isUserScrollingOnMobileDevice &&
        scrollDimension.current.isScrollToBottom
      ) {
        scrollToBottom({ behavior: 'auto' });
      }
    },
  });

  useOrientationChange({
    environment,
    onChange() {
      if (scrollDimension.current.isScrollToBottom) {
        scrollToBottom();
      }
    },
  });

  useEffect(() => {
    if (!rootElementRef.current) {
      return;
    }

    /* --- モバイル用のスクロールイベント --- */
    const touchStartHandler = () => {
      shouldStopInterScrollingProcess.current = false;
      scrollDimension.current.isUserScrollingOnMobileDevice = true;
    };
    const touchMoveHandler = () => {
      scrollDimension.current.isAutoScrolling = false;
    };
    const touchEndHandler = () => {
      scrollDimension.current.isUserScrollingOnMobileDevice = false;
    };
    rootElementRef.current.addEventListener('touchstart', touchStartHandler, PASSIVE);
    rootElementRef.current.addEventListener('touchmove', touchMoveHandler, PASSIVE);
    rootElementRef.current.addEventListener('touchend', touchEndHandler, PASSIVE);

    /* --- PCのホイールスクロールイベント --- */
    const wheelHandler = () => {
      scrollDimension.current.isAutoScrolling = false;
      shouldStopInterScrollingProcess.current = false;
    };
    rootElementRef.current.addEventListener('wheel', wheelHandler, PASSIVE);

    /* --- スクロールイベント --- */
    const scrollHandler = (e: Event) => {
      scrollCallbackRef.current(e);
    };
    rootElementRef.current.addEventListener('scroll', scrollHandler, PASSIVE);

    /* --- イベントのクリーンアップ --- */
    return () => {
      if (rootElementRef.current) {
        rootElementRef.current.removeEventListener('touchmove', touchMoveHandler);
        rootElementRef.current.removeEventListener('touchstart', touchStartHandler);
        rootElementRef.current.removeEventListener('touchend', touchEndHandler);
        rootElementRef.current.removeEventListener('scroll', scrollHandler);
        rootElementRef.current.removeEventListener('wheel', wheelHandler);
      }
    };
  }, []);

  useEffect(() => {
    if (messages.length && rootElementRef.current) {
      shouldStopInterScrollingProcess.current = true;
    }
  }, [messages]);

  useEffect(() => {
    setTimeout(interScrollingProcess, 300);
    if (messages.length > prevMessageLength.current && rootElementRef.current) {
      setScrollDimension({
        ...scrollDimension.current,
        scrollHeight: rootElementRef.current.scrollHeight,
        clientHeight: rootElementRef.current.clientHeight,
      });
      setPrevMessageLength(messages.length);
    }
  }, [
    messages,
    rootElementRef.current,
    rootElementRef.current?.scrollHeight,
    scrollDimension.current,
  ]);

  useEffect(() => {
    const cacheHeight = () => {
      if (rootElementRef.current) {
        setScrollDimension({
          ...scrollDimension.current,
          scrollHeight: rootElementRef.current.scrollHeight,
          clientHeight: rootElementRef.current.clientHeight,
        });
        shouldStopInterScrollingProcess.current = false;
        scrollCallbackRef.current({} as any);
      }
    };
    if (messages.length && rootElementRef.current) {
      shouldStopInterScrollingProcess.current = true;
      keyboardAppearanceWaiter(cacheHeight);
    }
  }, [messages, isInputFocused]);
};
