/**
 * @fileOverview
 * @name FirebaseSnapshotQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { GetAndFindableQuery } from '@s/Query';
import { FIRESTORE_MESSAGE_FETCH_LIMIT } from '@w/config';
import { FirestoreDatabaseHolder } from '@w/firebase/FirestoreDatabase';
import { getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore';

export type FirebaseSnapshotFindableQuery<FirestoreSchemaType> = GetAndFindableQuery<
  {
    database: FirestoreDatabaseHolder;
  },
  { startAt: number; snapshot: FirestoreSchemaType[]; isCustomerImageUploadEnabled: boolean },
  {
    database: FirestoreDatabaseHolder;
    startAt: number;
  },
  FirestoreSchemaType[]
>;

export class FirebaseSnapshotQuery<FirestoreSchemaType>
  implements FirebaseSnapshotFindableQuery<FirestoreSchemaType>
{
  public async find({
    startAt,
    database,
  }: Parameters<FirebaseSnapshotFindableQuery<FirestoreSchemaType>['find']>[0]): ReturnType<
    FirebaseSnapshotFindableQuery<FirestoreSchemaType>['find']
  > {
    return new Promise(async (resolve, reject) => {
      const snapshot = await getDocs(
        query(
          database.collection,
          where('type', 'in', ['message', 'postback']),
          orderBy('at', 'desc'),
          startAfter(startAt),
          limit(FIRESTORE_MESSAGE_FETCH_LIMIT)
        )
      ).catch(reject);

      if (snapshot) {
        resolve(snapshot.docs.map(doc => doc.data() as FirestoreSchemaType));
      }
    });
  }

  public async get({
    database,
  }: Parameters<FirebaseSnapshotFindableQuery<FirestoreSchemaType>['get']>[0]): ReturnType<
    FirebaseSnapshotFindableQuery<FirestoreSchemaType>['get']
  > {
    return new Promise(async (resolve, reject) => {
      const snapshot = await getDocs(
        query(
          database.collection,
          where('type', 'in', ['message', 'postback']),
          orderBy('at', 'desc'),
          limit(FIRESTORE_MESSAGE_FETCH_LIMIT + 1)
        )
      ).catch(reject);

      if (snapshot) {
        const lastEvent = await getDocs(
          query(database.collection, orderBy('at', 'desc'), limit(1))
        );

        const startAt = lastEvent.docs.length ? lastEvent.docs[0].data().at : 0;
        const snapshotDocs = snapshot.docs.slice().reverse();

        const lastCustomerImageUpRequestEvent = await getDocs(
          query(
            database.collection,
            where('type', 'in', ['customerImageUpEnableRequest', 'customerImageUpDisableRequest']),
            orderBy('at', 'desc'),
            limit(1)
          )
        );
        const isCustomerImageUploadEnabled = lastCustomerImageUpRequestEvent.docs.length
          ? lastCustomerImageUpRequestEvent.docs[0].data().type === 'customerImageUpEnableRequest'
          : false;

        resolve({
          startAt,
          snapshot: snapshotDocs.map(doc => doc.data() as FirestoreSchemaType),
          isCustomerImageUploadEnabled,
        });
      }
    });
  }
}
