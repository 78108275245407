/**
 * @fileOverview
 * @name action.ts
 * @author Taketoshi Aono
 * @license
 */

import { Auth } from '@w/domain/entities/Auth';

export const authSucceeded = (payload: Auth) => {
  return {
    type: 'AUTH_SUCCEEDED' as const,
    payload,
  };
};

export const previewTokenStored = (payload: { token: string }) => {
  return {
    type: 'PREVIEW_TOKEN_STORED' as const,
    payload,
  };
};
