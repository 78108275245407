import React from 'react';
import { motion } from 'framer-motion';
import { WidgetChatWindowBackground } from '../molecule/WidgetChatWindowBackground';
import styled from '@emotion/styled';
import { WidgetHeader, WidgetHeaderProps } from '../molecule/WidgetHeader';
import { WidgetTalkArea, WidgetTalkAreaProps } from './WidgetTalkArea';
import { WidgetMessageInput, WidgetMessageInputProps } from '../molecule/WidgetMessageInput';
import { DisplayableMessageFormat } from '../atom/WidgetMessageConfig';
import { WidgetType } from '../atom/WidgetType';
import { useRefState } from '@s/reactHooks';
import { compareOnlyProperties } from '@s/compareOnlyProperties';

export type WidgetChatWindowProps = WidgetHeaderProps &
  Omit<WidgetMessageInputProps, 'textInputState' | 'visible'> &
  WidgetTalkAreaProps & {
    isChattingWithOperator: boolean;
    isInputLoading: boolean;
    type: WidgetType;
    messages: DisplayableMessageFormat[];
  };

const WidgetChatWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const RootContainerElement = motion.div;

export const WidgetChatWindow = compareOnlyProperties(
  ({
    headerText,
    enableRefreshButton,
    loading,
    enableImageUpload,
    enableCloseButton,
    type,
    messages,
    mediaCache,
    isChattingWithOperator,
    isDesignerMode = false,
    isInputLoading,
    isOperatorMode = false,
    isDisplayTyping,
    onClose,
    onSendMessage = () => {},
    onSendImage = () => {},
    onSendQuickRepliesPostPack,
    onDeleteMessage = () => {},
    onRefresh,
    onScroll,
    onUserInput,
    onCacheMedia,
  }: WidgetChatWindowProps) => {
    const [isFocused, setFocused] = useRefState(false);
    let latestMessageFromBot;
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i].sender.type === 'Bot') {
        latestMessageFromBot = messages[i];
        break;
      }
    }

    const textInputState = latestMessageFromBot ? latestMessageFromBot.textInputState : 'show';
    const isInputVisible = isChattingWithOperator || textInputState !== 'hide';

    const widgetChat = (
      <WidgetChatWindowBackground type={type}>
        <WidgetChatWindowContainer>
          <WidgetHeader
            headerText={headerText}
            enableRefreshButton={enableRefreshButton}
            enableCloseButton={enableCloseButton}
            onClose={onClose}
            onRefresh={onRefresh}
            isDesignerMode={isDesignerMode}
          />
          <WidgetTalkArea
            isDisplayTyping={isDisplayTyping}
            loading={loading || !messages.length}
            messages={messages}
            mediaCache={mediaCache}
            isInputFocused={isFocused.current}
            isShowInput={isInputVisible}
            isOperatorMode={isOperatorMode}
            shouldDisplayDate={true}
            onSendMessage={onSendMessage}
            onSendQuickRepliesPostPack={onSendQuickRepliesPostPack}
            onDeleteMessage={onDeleteMessage}
            onScroll={onScroll}
            onUserInput={onUserInput}
            onCacheMedia={onCacheMedia}
          />
          <WidgetMessageInput
            visible={isInputVisible}
            loading={isInputLoading || loading || !messages.length}
            textInputState={textInputState}
            type={type}
            enableImageUpload={enableImageUpload}
            onSendMessage={onSendMessage}
            onSendImage={onSendImage}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onUserInput={onUserInput}
            isDesignerMode={isDesignerMode}
          />
        </WidgetChatWindowContainer>
      </WidgetChatWindowBackground>
    );
    return type === WidgetType.FLOATING ? (
      <RootContainerElement
        initial={{ translateX: 20, opacity: 0 }}
        animate={{ translateX: 0, opacity: 1 }}
        exit={{
          translateX: 20,
          opacity: 0,
          transition: { type: 'tween', duration: 0.2, ease: 'anticipate' },
        }}
        transition={{ type: 'spring', damping: 13 }}
        css={{
          transformOrigin: 'bottom right',
          background: 'transparent',
          width: '100%',
          height: '100%',
        }}
      >
        {widgetChat}
      </RootContainerElement>
    ) : (
      widgetChat
    );
  }
);
