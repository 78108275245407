import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducer';
import { ThunkDeps } from '@w/store/ThunkDeps';
import { FirebaseAuthRepository } from '@w/repository/FirebaseAuthRepository';
import { FirebaseHandler } from '@w/firebase/FirebaseHandler';
import { FirestoreConnector } from '@w/firebase/FirestoreConnection';
import { FirebaseSnapshotQuery } from '@w/query/FirebaseSnapshotQuery';
import { MessageEventRepository, ImageEventRepository } from '@s/repository/EventRepository';
import { WelcomeMessageQuery } from '@w/query/WelcomeMessageQuery';
import { VersionRepository } from '@w/repository/VersionRepository';
import { FirestoreService } from '@w/domain/service/firestore/FirestoreService';
import { AimWidgetInitialConfig } from '@w/window';
import { EventRequestService } from '@w/application/service/EventRequestService';
import { EventQueuingService } from '@w/application/service/EventQueuingService';
// #if process.env.NODE_ENV !== 'production'
import { CrashReportable } from '@aim/shared/src/crashreporting/CrashReporterForWidget';
// #endif
import { staticConfig } from '@w/config';
import { ActionLogQueuingService } from '@s/application/service/ActionLogQueuingService';
import { crashReporterFactory } from '@w/crashReporterFactory';
import { ChatStateRepository } from '@w/repository/ChatStateRepository';
import { MediaStorageRepository } from '@s/repository/MediaStorageRepository';
import { CustomerMediaQuery } from '@s/query/CustomerMediaQuery';
import { UserDataRepository } from '@w/repository/UserDataRepository';
import { LLMMessageFormat, MessageFormat } from '@s/domain/entity/MessageFormat';
import { LLMMessageRepository } from '@s/repository/LLMMessageRepository';
import {
  convertLLMMessageFormatToWidgetMessage,
  convertMessageFormatToWidgetMessage,
} from '@s/components/atom/WidgetMessageConfig';

const extentionCompose = (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'];
const composeEnhancer =
  process.env.NODE_ENV !== 'production' ? (extentionCompose ? extentionCompose : compose) : compose;

export const initStore = (
  config: AimWidgetInitialConfig,
  enableDebug = false,
  enableLongPolling = false
) => {
  const firebaseHandler = new FirebaseHandler(
    config.tenantId,
    enableDebug,
    enableLongPolling,
    config.dedicatedEnvConfigJson?.firebaseConfig
  );

  const firestoreService =
    config.mode === 'llm'
      ? new FirestoreService<LLMMessageFormat>({
          firebaseAuthRepository: new FirebaseAuthRepository(firebaseHandler),
          firestoreConnector: new FirestoreConnector(firebaseHandler),
          firebaseSnapshotQuery: new FirebaseSnapshotQuery<LLMMessageFormat>(),
          persistency: config.persistency ?? 'local',
          converter: convertLLMMessageFormatToWidgetMessage,
        })
      : new FirestoreService<MessageFormat>({
          firebaseAuthRepository: new FirebaseAuthRepository(firebaseHandler),
          firestoreConnector: new FirestoreConnector(firebaseHandler),
          firebaseSnapshotQuery: new FirebaseSnapshotQuery<MessageFormat>(),
          persistency: config.persistency ?? 'local',
          converter: convertMessageFormatToWidgetMessage,
        });

  let cache: CrashReportable | undefined;
  const crashReporter = async () => {
    if (!cache) {
      try {
        const CrashReporter = await import(
          /* webpackChunkName: 'crash-reporter' */ '@aim/shared/src/crashreporting/CrashReporterForWidget'
        ).then(({ CrashReporter }) => CrashReporter);
        cache = CrashReporter.getInstance().init({
          dsn: staticConfig.sentry.dsn,
          environment: staticConfig.env,
          release: process.env.COMMIT_HASH ?? '',
        });
      } catch (e) {
        return new Promise<CrashReportable>(resolve => {
          setTimeout(async () => {
            resolve(await crashReporter());
          }, 1000);
        });
      }
    }
    return cache;
  };
  const thunkDeps: ThunkDeps = {
    firestoreService,
    eventRequestService: new EventRequestService(),
    messageEventRepository: new MessageEventRepository(() => staticConfig.messageEndpoint),
    llmMessageRepository: new LLMMessageRepository(() => staticConfig.llmMessageEndpoint),
    eventQueuingService: new EventQueuingService(),
    imageEventRepository: new ImageEventRepository(() => staticConfig.messageEndpoint),
    welcomeMessageQuery: new WelcomeMessageQuery(config.dedicatedEnvConfigJson),
    versionRepository: new VersionRepository(),
    reportCrashed: crashReporterFactory({
      crashReporter,
      firestoreService,
      actionLogQueuingService: ActionLogQueuingService.getInstance(),
    }),
    actionLogQueuingService: ActionLogQueuingService.getInstance(),
    chatStateRepository: new ChatStateRepository(),
    mediaStorageRepository: new MediaStorageRepository(),
    customerMediaQuery: new CustomerMediaQuery(),
    userDataRepository: new UserDataRepository(),
  };

  return {
    store: createStore(
      rootReducer(ActionLogQueuingService.getInstance()),
      composeEnhancer(applyMiddleware(thunk.withExtraArgument(thunkDeps)))
    ),
    deps: thunkDeps,
  };
};
