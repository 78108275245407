/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React from 'react';
import styled from '@emotion/styled';

const PathElement = styled.path<{ fill: string }>`
  transition: fill 0.2s;
  fill: ${p => p.fill};
`;

export const CrossbarIcon = ({ color }: { color: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <PathElement
      fill={color}
      d="M299.68,250,488.84,60.84a35.13,35.13,0,0,0-49.68-49.68L250,200.32,60.84,11.16A35.13,35.13,0,0,0,11.16,60.84L200.32,250,11.16,439.16a35.13,35.13,0,0,0,49.68,49.68L250,299.68,439.16,488.84a35.13,35.13,0,0,0,49.68-49.68Z"
    />
  </svg>
);
