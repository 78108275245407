/**
 * @fileOverview
 * @name detectDomFeature.ts
 * @author Taketoshi Aono
 * @license
 */

export const IS_SUPPORT_PASSIVE_EVENTLISTENER = (() => {
  let isSupportsPassive = false;
  try {
    const h = () => {};
    document.addEventListener('test' as string, h, {
      get passive() {
        isSupportsPassive = true;
        return true;
      },
    });
    document.removeEventListener('test', h);
  } catch (e) {
    return false;
  }

  return isSupportsPassive;
})();

export const PASSIVE = IS_SUPPORT_PASSIVE_EVENTLISTENER ? { passive: true } : false;
export const UNPASSIVE = IS_SUPPORT_PASSIVE_EVENTLISTENER ? { passive: false } : false;
