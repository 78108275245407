import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { WidgetContext } from '../atom/WidgetContext';
import styled from '@emotion/styled';

export type WidgetFloatProps = {
  onClose?(): void;
};

const WidgetMiniFloatContainer = styled.div`
  overflow: hidden;
  visibility: visible;
`;

const MiniFloat = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;

const MinimizeButton = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 20;
  cursor: pointer;
`;

const Float = styled.div`
  width: 100%;
  height: 32px;
  border-radius: 4px 4px 0px 0px;
  padding: 4px 0px;
  background-position: center 5px;
  background-size: 45%;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAAqCAYAAADrhujJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAolJREFUeNrkWu1xgzAMBa4DsEE9AhuETNB0AzJB6QSlE6SdwGxAMgFskHQCsgHZwJXvROrzERI7/spVdzrCjxg/S5aeZEfRP5XYxCCMsRQeOWgGugBN8fecHFEPoD/8GcfxIfgVA7AEtATdM3MygFLQVYiAC9CW2ZcetEJv8gp4hZNxLYOXBUCXbpl/GZxtAbTywMIS6mIvhyr7e10/vgQaHjTwxMJT3xJS4Ennz8mUez8A6Ah5gvY8EzmQPQjoUXgMKnX++CS9fyDrClFO6N6yLAB8rerysWTtPiCgHegOqWxnenDR4m8BgOXc/Ru01g1aOhbn1iYe3fgdwNYTwXYsfp4vFD4nLHL4onUwxlE1b3sjJHJORuJENckTp9Ub3LpXQeeeQBcTpKk3vKgkNOCFw3qgCgV4KXw7c1QPNOKWEoMbc5WmIAAtR9DwaB1yh/O3E4n7upC10K5qHBMm7tkbGfjOwYe3QropPaVP3i4jrpnbeszVfF97pMf12eJoiS8HNHQkJT5rglwuSz+RAdmhiX9unkV+hSTSxDj9e0UaaFO8V4DJhFUOY+S10M5KRZcPCjiC3/K2jgXL50IV5lO2ycx+7BC8yfz+Iux1n1bf3eqilcHeeCpQVS8dWtX9SbDaMVYwGFxQlYXPdAMUwXr3nsIiE8ajwYOeakXjxHuNSRBhnNLBAWRmI00RRQ9o5aMg3PM2anJ7B45Y6xo5+MPeQGPArW9rP90BulCwMlEYN8WxqcLlA4p1gF25MSW1piaDizEXDFsXoK8dIVtb/SuXFCqboMuZ49vC1e2FmWsppekPpVLgGfDdGdiZrCJfRKJG5oTRdo9ASyt50dwijEGxCXWe3uRXgAEA5SdRBeQjcaIAAAAASUVORK5CYII=');
  cursor: pointer;
`;

export const WidgetMiniFloat = ({ onClose }: WidgetFloatProps) => {
  const { config } = useContext(WidgetContext);

  return (
    <motion.div
      initial={{ translateY: 20, opacity: 0 }}
      animate={{ translateY: 0, opacity: 1 }}
      exit={{ translateY: 20, opacity: 0 }}
      transition={{ duration: 0.2 }}
      css={{
        transformOrigin: 'bottom right',
      }}
    >
      <WidgetMiniFloatContainer>
        <MiniFloat>
          <MinimizeButton onClick={onClose} />
          <Float css={{ backgroundColor: config.chatColoring.common.background }} />
        </MiniFloat>
      </WidgetMiniFloatContainer>
    </motion.div>
  );
};
