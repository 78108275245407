import { WidgetProps } from '@s/components/ecosystem/Widget';
import {
  requestQuickRepliesPostbackEvent,
  requestImageEvent,
  deleteMessage,
  requestResetEvent,
  preparePastMessages,
  sendTyping,
  cacheMedia,
  requestLLMMessage,
} from '@w/module/message/usecase';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { ReduxState } from '@w/domain/entities/State';
import { openChat, closeChat } from '@w/module/chat/usecase';
import { Container } from './Container';

const mapStateToProps = (
  state: ReduxState,
  props: WidgetProps
): Omit<WidgetProps, 'onReboot' | 'onOpen' | 'onClose' | 'onUserInput'> => {
  return {
    config: props.config,
    backgroundColor: props.backgroundColor,
    borderRadius: props.borderRadius,
    tabIndex: props.tabIndex,
    enableCloseButton: props.enableCloseButton,
    enableRefreshButton: props.enableRefreshButton,
    enableImageUpload: state.message.isCustomerImageUploadEnabled,
    type: props.type,
    messages: state.message.messages,
    mediaCache: state.message.mediaCache,
    isChattingWithOperator: state.chat.isOperatorChatting,
    isChatLoading: state.chat.loading,
    isInputLoading: state.chat.inputLoading,
    error: state.message.error,
    isChatOpen: state.chat.open,
    shouldDisplayMiniFloat: state.chat.shouldDisplayMiniFloat,
    isDisplayTyping: state.message.typing.visible,
    isOperatorChatting: state.chat.isOperatorChatting,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSendMessage: bindActionCreators(requestLLMMessage, dispatch),
    onSendQuickRepliesPostPack: bindActionCreators(requestQuickRepliesPostbackEvent, dispatch),
    onSendImage: bindActionCreators(requestImageEvent, dispatch),
    onDeleteMessage: bindActionCreators(deleteMessage, dispatch),
    onReset: bindActionCreators(requestResetEvent, dispatch),
    onScroll: bindActionCreators(preparePastMessages, dispatch),
    onOpen: bindActionCreators(openChat, dispatch),
    onClose: bindActionCreators(closeChat, dispatch),
    onUserInput: bindActionCreators(sendTyping, dispatch),
    onCacheMedia: bindActionCreators(cacheMedia, dispatch),
  };
};

export const LLMWidgetContainer = compose(connect(mapStateToProps, mapDispatchToProps))(
  Container
) as any;
