/**
 * @fileOverview
 * @name WelcomeMessageQuery.ts<widget>
 * @author Taketoshi Aono
 * @license
 */

import { GettableQuery } from '@s/Query';
import { fetchService } from '@aim/shared/src/io/fetchService';
import { staticConfig } from '@w/config';
import { defaultWelcomeMessage } from '@s/domain/entity/MessageFormat';
import { WelcomeMessageFormat } from '@w/application/WelcomeMessage';
import { AimWidgetInitialConfig } from '@w/window';

export type WelcomeMessageGettableQuery = GettableQuery<
  { projectId: string; tenantName: string },
  WelcomeMessageFormat
>;

export class WelcomeMessageQuery implements WelcomeMessageGettableQuery {
  public constructor(
    private dedicatedEnvConfigJson: AimWidgetInitialConfig['dedicatedEnvConfigJson']
  ) {}

  public async get({
    projectId,
    tenantName,
  }: Parameters<WelcomeMessageGettableQuery['get']>[0]): ReturnType<
    WelcomeMessageGettableQuery['get']
  > {
    try {
      return await fetchService<WelcomeMessageFormat>(
        `https://${
          this.dedicatedEnvConfigJson?.serverName
            ? `${this.dedicatedEnvConfigJson.serverName}-`
            : ''
        }${
          staticConfig.scriptEndpoint
        }/welcome_message/${tenantName}/${projectId}/welcome_message.json?_=${Date.now()}`,
        { method: 'GET' }
      );
    } catch (e) {}

    return { version: '0', messages: [defaultWelcomeMessage] };
  }
}
