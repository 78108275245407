/**
 * @fileOverview
 * @name usecase.ts<env>
 * @author Taketoshi Aono
 * @license
 */

import { Dispatch } from 'redux';
import { ReduxState } from '@w/domain/entities/State';
import { ThunkDeps } from '@w/store/ThunkDeps';
import { welcomeMessagePrepared, envInitialized } from './action';
import { AimWidgetInitialConfig } from '@w/window';
import { WelcomeMessageFormat } from '@w/application/WelcomeMessage';

export const prepareWelcomeMessage =
  ({
    welcomeMessage,
    onComplete,
  }: {
    welcomeMessage?: WelcomeMessageFormat;
    onComplete?(): void;
  }) =>
  async (
    dispatch: Dispatch,
    getState: () => ReduxState,
    { welcomeMessageQuery, versionRepository, reportCrashed }: ThunkDeps
  ) => {
    try {
      const state = getState();
      const remoteWelcomeMessage = !welcomeMessage
        ? await welcomeMessageQuery.get(state.env)
        : welcomeMessage;
      dispatch(
        welcomeMessagePrepared({
          messages: remoteWelcomeMessage.messages,
          textInputState: remoteWelcomeMessage.meta?.textInputState,
        })
      );
      await versionRepository.create({ version: remoteWelcomeMessage.version });
      onComplete && onComplete();
    } catch (error: any) {
      reportCrashed({ error, state: getState() });
    }
  };

export const storeEnvToState = (config: AimWidgetInitialConfig) => async (dispatch: Dispatch) => {
  dispatch(envInitialized(config));
};
