/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React from 'react';
import { WidgetInternalConfig, defaultWidgetInternalConfig } from './WidgetConfig';

export type WidgetRequestError = {
  msg?: any;
  uuid: string[];
};

export const WidgetContext = React.createContext<{
  config: WidgetInternalConfig;
  isMobile: boolean;
  isOperatorChatting: boolean;
  error?: WidgetRequestError;
}>({
  config: defaultWidgetInternalConfig,
  isMobile: false,
  isOperatorChatting: false,
  error: undefined,
});
