/**
 * @fileOverview
 * @name FirebaseAuthRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { Auth } from '@w/domain/entities/Auth';
import { FirebaseHandleable } from '@w/firebase/FirebaseHandler';
import { fetchService } from '@s/io/fetchService';
import { staticConfig } from '@w/config';
import { required } from '@s/assertions';
import {
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
  signInWithCustomToken,
  signOut,
} from 'firebase/auth';
import { deleteApp } from 'firebase/app';

export interface FirebaseAuthentificatableRepository {
  login({
    tenantId,
    previewToken,
  }: {
    tenantId: string;
    previewToken?: string;
  }): Promise<{ auth: Auth; isCreated: boolean }>;
  isLoggedIn(): Promise<boolean>;
  syncWithServer(auth: Auth, projectId: string, userData: { [key: string]: string }): Promise<Auth>;
  checkUpdate(a: { auth: Auth }): Promise<string | null>;
  delete(): Promise<void>;
}

export class FirebaseAuthRepository implements FirebaseAuthentificatableRepository {
  public constructor(private readonly firebaseHandler: FirebaseHandleable) {}

  public async checkUpdate({
    auth,
  }: Parameters<FirebaseAuthentificatableRepository['checkUpdate']>[0]): ReturnType<
    FirebaseAuthentificatableRepository['checkUpdate']
  > {
    // Firebase auth used old closure library promise that throw error asynchronously.
    // So we need to handle it with Promise constructor and thenable.
    return new Promise<string | null>(async (resolve, reject) => {
      try {
        const user = getAuth(this.firebaseHandler.app).currentUser;
        if (user) {
          let result = await user.getIdTokenResult().catch(e => reject(e));
          if (result && auth.token !== result.token) {
            result = await user.getIdTokenResult(true).catch(e => reject(e));
            if (result) {
              resolve(result.token);
            }
          }
        }
      } catch (e: any) {
        if (e?.code !== 'app/app-deleted') {
          reject(e);
          return;
        }
      }

      return resolve(null);
    });
  }

  public async delete(): Promise<void> {
    // Firebase auth used old closure library promise that throw error asynchronously.
    // So we need to handle it with Promise constructor and thenable.
    return new Promise(async (resolve, reject) => {
      const rejectIfNotAppDeletedError = (e: any) => {
        if (e?.code !== 'app/app-deleted') {
          reject(e);
        }
      };
      try {
        await signOut(getAuth(this.firebaseHandler.app))
          .then(async () => {
            deleteApp(this.firebaseHandler.app).then(resolve).catch(rejectIfNotAppDeletedError);
          })
          .catch(rejectIfNotAppDeletedError);
      } catch (e) {
        rejectIfNotAppDeletedError(e);
      }
    });
  }

  public async isLoggedIn(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        const unsubscribe = onAuthStateChanged(getAuth(this.firebaseHandler.app), user => {
          unsubscribe();
          resolve(!!user);
        });
      } catch (e: any) {
        if (e?.code !== 'app/app-deleted') {
          reject(e);
        }
      }
    });
  }

  public async login({
    tenantId,
    previewToken,
  }: Parameters<FirebaseAuthentificatableRepository['login']>[0]): Promise<{
    auth: Auth;
    isCreated: boolean;
  }> {
    const auth = getAuth(this.firebaseHandler.app);
    auth.tenantId = tenantId;

    // Firebase auth used old closure library promise that throw error asynchronously.
    // So we need to handle it with Promise constructor and thenable.
    return new Promise<{ isCreated: boolean; auth: Auth }>(async (resolve, reject) => {
      let isCreated = false;

      const unsubscribe = onAuthStateChanged(auth, async user => {
        if (user) {
          unsubscribe();
          const result = await user.getIdTokenResult().catch(e => reject(e));
          if (result) {
            resolve({
              isCreated,
              auth: { token: result.token, claims: result.claims },
            });
          }
        } else {
          isCreated = true;
          const handleError = (error: any, count: number) => {
            if (error?.code === 'app/app-deleted') {
              return;
            }
            if (count === 10) {
              return reject(error);
            }
            setTimeout(() => login(count + 1), 1000);
          };
          const login = previewToken
            ? (count: number) => {
                signInWithCustomToken(auth, previewToken).catch(error => handleError(error, count));
              }
            : (count: number) => {
                // 匿名認証
                // ログイン情報はindexedDBに保持される。
                // 再度ログインしてもindexedDBに保存されていれば同じユーザーでログインされる
                // indexedDBから削除すれば別ユーザとしてログインされる
                signInAnonymously(auth).catch(err => handleError(err, count));
              };
          login(0);
        }
      });
    });
  }

  public async syncWithServer(
    auth: Auth,
    projectId: string,
    userData: { [key: string]: string }
  ): Promise<Auth> {
    // Firebase auth used old closure library promise that throw error asynchronously.
    // So we need to handle it with Promise constructor and thenable.
    return new Promise(async (resolve, reject) => {
      const handleError = (e: any, tryCount: number) => {
        if (e?.code === 'app/app-deleted') {
          return;
        }
        if (tryCount === 10) {
          return reject(e);
        }
        setTimeout(sync, 1000);
      };
      const sync = async (tryCount: number) => {
        try {
          await fetchService(`${staticConfig.customerEndpoint}/customer`, {
            method: 'POST',
            headers: {
              authorization: `Bearer ${auth.token}`,
              accept: 'application/json',
            },
            data: { projectId, additionals: userData },
          });
          const user = required(getAuth(this.firebaseHandler.app).currentUser);
          const result = await user.getIdTokenResult(true).catch(e => {
            handleError(e, tryCount);
          });

          if (result) {
            resolve({
              token: result.token,
              claims: result.claims,
            });
          }
        } catch (e) {
          handleError(e, tryCount);
        }
      };
      await sync(0);
    });
  }
}
