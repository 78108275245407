/**
 * @fileOverview
 * @name usecase.ts<message>
 * @author Taketoshi Aono
 * @license
 */

import { Action } from 'redux';
import { ThunkDeps } from '@w/store/ThunkDeps';
import {
  eventRequested,
  eventRequestFailed,
  eventRequestSucceeded,
  isCustomerImageUploadEnabledChanged,
  mediaCached,
  messageDeleted,
  messageReceived,
  messagesReceived,
  operatorTypingChanged,
  messageUpdated,
  welcomeMessageTransferingRequired,
} from './action';
import { chatLoadingChanged, operatorChatStarted } from '@w/module/chat/action';
import { ReduxState } from '@w/domain/entities/State';
import { EventRequestParam } from '@s/domain/values/EventRequestParam';
import { authSucceeded } from '../auth/action';
import {
  completeChatPreparation,
  completeInputPreparation,
  haltChatPreparation,
  haltInputPreparation,
} from '../chat/usecase';
import { ThunkDispatch } from 'redux-thunk';
import { ReportCrashed } from '@w/crashReporterFactory';
import { required } from '@s/assertions';
import { staticConfig } from '@w/config';
import { Console } from '@w/util/Console';
import { rng } from '@w/util/md5';
import {
  convertMessageFormatToWidgetMessage,
  DisplayableMessageFormat,
} from '@s/components/atom/WidgetMessageConfig';

type Dispatch = ThunkDispatch<ReduxState, ThunkDeps, Action>;

const firestoreSetupIfRequired = async ({
  dispatch,
  getState,
  firestoreService,
  reportCrashed,
}: {
  dispatch: Dispatch;
  getState(): ReduxState;
  firestoreService: ThunkDeps['firestoreService'];
  reportCrashed: ReportCrashed;
}) => {
  if (!(await firestoreService.isLoggedIn()) || !firestoreService.isInitialized) {
    try {
      dispatch(haltInputPreparation());
      await firestoreService.initializeOnce({
        getState,
        onOperatorChattingStateChange: ({ isStart }) => dispatch(operatorChatStarted({ isStart })),
        onWelcomeMessageTransferRequired: () => dispatch(welcomeMessageTransferingRequired()),
        onLogin: auth => dispatch(authSucceeded(auth)),
        onBatchAddMessage: messages => dispatch(messagesReceived(messages)),
        onAddMessage: message => dispatch(displayMessage(message) as any),
        onModifyMessage: message => dispatch(messageUpdated(message)),
        onTyping: a => dispatch(operatorTypingChanged(a)),
        onUpdateEnableCustomerImageUploadState: a =>
          dispatch(isCustomerImageUploadEnabledChanged(a)),
      });
      dispatch(completeInputPreparation());
    } catch (error: any) {
      reportCrashed({ error, state: getState() });
    }
  }
};

const sendEvent = async ({
  dispatch,
  messageEventRepository,
  imageEventRepository,
  getState,
  firestoreService,
  projectId,
  param,
  reportCrashed,
}: {
  dispatch: Dispatch;
  getState(): ReduxState;
  messageEventRepository?: ThunkDeps['messageEventRepository'];
  imageEventRepository?: ThunkDeps['imageEventRepository'];
  firestoreService: ThunkDeps['firestoreService'];
  projectId: string;
  param: EventRequestParam;
  reportCrashed: ReportCrashed;
}) => {
  dispatch(
    eventRequested({ eventRequest: param, isOperatorChatting: getState().chat.isOperatorChatting })
  );

  await firestoreSetupIfRequired({
    dispatch,
    getState,
    firestoreService,
    reportCrashed,
  });

  try {
    const state = getState();
    if (
      state.message.shouldTransferWelcomeMessage &&
      (param.type === 'message' || param.type === 'postback')
    ) {
      param.meta.welcomeMessage = state.env.welcomeMessage!.messages[0].attachment;
    }
    if (param.type === 'image') {
      if (imageEventRepository === undefined) {
        throw new Error('No repository in the argument');
      }
      await imageEventRepository.create({
        param,
        projectId,
      });
    } else {
      if (messageEventRepository === undefined) {
        throw new Error('No repository in the argument');
      }
      Console.info('call messageEventRepository.create');
      await messageEventRepository.create({
        param,
        projectId,
      });
    }
    dispatch(eventRequestSucceeded());
    Console.info('call eventRequestSucceeded');
  } catch (error: any) {
    reportCrashed({ error, state: getState() });
    dispatch(eventRequestFailed(error, param.uuid));
  }
};

export const requestMessageEvent =
  (message: string, uuid?: string) =>
  async (
    dispatch: Dispatch,
    getState: () => ReduxState,
    { eventRequestService, messageEventRepository, firestoreService, reportCrashed }: ThunkDeps
  ) => {
    const param = eventRequestService.initRequestPayload({
      type: 'message',
      message,
      uuid,
    });
    await sendEvent({
      getState,
      firestoreService,
      dispatch,
      messageEventRepository,
      projectId: getState().env.projectId,
      param,
      reportCrashed,
    });
  };

// llm モードのときのメッセージ送信 usecase
export const requestLLMMessage =
  (message: string, uuid?: string) =>
  async (
    dispatch: Dispatch,
    getState: () => ReduxState,
    { eventRequestService, firestoreService, llmMessageRepository, reportCrashed }: ThunkDeps
  ) => {
    const uuid_ = uuid ?? rng();

    const param = eventRequestService.initRequestPayload({
      type: 'message',
      message,
      uuid: uuid_,
    });

    dispatch(
      eventRequested({
        eventRequest: param,
        isOperatorChatting: getState().chat.isOperatorChatting,
      })
    );

    await firestoreSetupIfRequired({
      dispatch,
      getState,
      firestoreService,
      reportCrashed,
    });

    const data = {
      message,
      senderMessageId: uuid_,
    };

    try {
      await llmMessageRepository.create({
        data,
        projectId: getState().env.projectId,
      });
      dispatch(eventRequestSucceeded());
    } catch (error: any) {
      reportCrashed({ error, state: getState() });
      dispatch(eventRequestFailed(error, data.senderMessageId));
    }
  };

export const requestQuickRepliesPostbackEvent =
  (message: string, payload: string, uuid?: string) =>
  async (
    dispatch: Dispatch,
    getState: () => ReduxState,
    { eventRequestService, messageEventRepository, firestoreService, reportCrashed }: ThunkDeps
  ) => {
    const param = eventRequestService.initRequestPayload({
      type: 'postback',
      message,
      uuid,
      payload,
    });
    await sendEvent({
      getState,
      firestoreService,
      dispatch,
      messageEventRepository,
      projectId: getState().env.projectId,
      param,
      reportCrashed,
    });
  };

export const requestResetEvent =
  () =>
  async (
    dispatch: Dispatch,
    getState: () => ReduxState,
    { eventRequestService, messageEventRepository, firestoreService, reportCrashed }: ThunkDeps
  ) => {
    Console.info('call requestResetEvent');
    const param = eventRequestService.initRequestPayload({
      type: 'reset',
      message: '',
    });
    dispatch(haltChatPreparation());
    Console.info('call haltChatPreparation');
    await sendEvent({
      getState,
      firestoreService,
      dispatch,
      messageEventRepository,
      projectId: getState().env.projectId,
      param,
      reportCrashed,
    });
    dispatch(completeChatPreparation());
    Console.info('call completeChatPreparation');
  };

export const requestImageEvent =
  (file: File, uuid?: string) =>
  async (
    dispatch: Dispatch,
    getState: () => ReduxState,
    {
      eventRequestService,
      imageEventRepository,
      mediaStorageRepository,
      firestoreService,
      reportCrashed,
    }: ThunkDeps
  ) => {
    try {
      const mediaEntity = await mediaStorageRepository.create({
        file,
        description: '',
        isForOperator: false,
        endpoint: `${staticConfig.mediaEndpoint}/media/image`,
      });
      const param = eventRequestService.initRequestPayload({
        type: 'image',
        mediaEntity,
        uuid,
      });
      await sendEvent({
        getState,
        firestoreService,
        dispatch,
        imageEventRepository,
        projectId: getState().env.projectId,
        param,
        reportCrashed,
      });
    } catch (error: any) {
      reportCrashed({ error, state: getState() });
    }
  };

export const preparePastMessages =
  () =>
  async (
    dispatch: Dispatch,
    getState: () => ReduxState,
    { firestoreService, reportCrashed }: ThunkDeps
  ) => {
    const state = getState();
    if (state.env.persistency === 'session') {
      return;
    }
    const messages = state.message.messages;
    if (messages.length && messages[0].uuid !== 'welcome-message_0') {
      dispatch(chatLoadingChanged(true));
      try {
        const messages = await firestoreService.fetchPastMessages({
          startAt: state.message.messages[0].at,
          welcomeMessage: convertMessageFormatToWidgetMessage(
            required(state.env.welcomeMessage)
          )[0],
        });
        dispatch(messagesReceived(messages));
      } catch (error: any) {
        reportCrashed({ error, state: getState() });
      }
      dispatch(chatLoadingChanged(false));
    }
  };

export const displayMessage =
  (message: DisplayableMessageFormat[]) =>
  async (
    dispatch: Dispatch,
    getState: () => ReduxState,
    { eventQueuingService, reportCrashed }: ThunkDeps
  ) => {
    try {
      message.map(async m => {
        await eventQueuingService.enqueue({
          message: m,
          onSendEvent(message) {
            dispatch(messageReceived(message));
          },
        });
      });
    } catch (error: any) {
      reportCrashed({ error, state: getState() });
    }
  };

export const deleteMessage = (uuid: string) => async (dispatch: Dispatch) => {
  dispatch(messageDeleted(uuid));
};

export const sendTyping =
  ({ text, isTyping }: { text: string; isTyping: boolean }) =>
  async (
    dispatch: Dispatch,
    getState: () => ReduxState,
    { firestoreService, reportCrashed }: ThunkDeps
  ) => {
    const state = getState();
    if (!state.chat.isOperatorChatting) {
      return;
    }
    try {
      await firestoreService.updateTyping({
        customerId: state.auth.auth.claims.aim_user_id,
        tenantId: state.env.tenantId,
        projectId: state.env.projectId,
        isTyping,
        text,
      });
    } catch (error: any) {
      reportCrashed({ error, state });
    }
  };

export const cacheMedia =
  (url: string) =>
  async (
    dispatch: Dispatch,
    getState: () => ReduxState,
    { reportCrashed, customerMediaQuery }: ThunkDeps
  ) => {
    try {
      const cacheUrl = await customerMediaQuery.get({ url });
      dispatch(mediaCached({ url, cacheUrl }));
    } catch (error: any) {
      dispatch(mediaCached({ url, cacheUrl: url }));
      reportCrashed({ error, state: getState() });
    }
  };
