import React, { useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { scrollbarStyle } from '@s/components/atom/ScrollbarStyle';
import { AnimatePresence } from 'framer-motion';
import { Loading } from '@s/components/atom/Loading';
import { WidgetMessageContainer } from './components/WidgetMessageContainer';
import { BotIcon } from '@s/components/atom/BotIcon';
import { WidgetPlainTextMessage } from './components/WidgetPlainTextMessage';
import { UserIcon } from '@s/components/atom/UserIcon';
import { WidgetContext } from '@s/components/atom/WidgetContext';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { WidgetEnvContext } from '@s/components/atom/WidgetEnvContext';
import { useiOSOverScrollFreezeFix } from '@s/platform/ios/useiOSOverScrollFreezeFix';
import { useRefState } from '@s/reactHooks';
import { DisplayableMessageFormat } from '@s/components/atom/WidgetMessageConfig';
import { MediaCache } from '@s/domain/entity/MediaCache';
import { AudioStatus } from '@s/domain/entity/Audio';
import { WidgetTalkAreaMessageElement } from './components/WidgetTalkAreaMessageElement';
import { useWidgetScrolling } from './hooks/useWidgetScroling';

const WidgetTalkAreaContainerElement = styled.section`
  height: 100%;
  flex-shrink: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
  ${scrollbarStyle};
  position: relative;
`;

const WidgetTalkAreaOrderListElement = styled.ol`
  position: relative;
  padding: 0px;
  > li {
    padding: 0;
    margin: 0;
    &:not(:last-child) {
    }
  }
`;

export type WidgetTalkAreaProps = {
  loading: boolean;
  messages: ReadonlyDeep<DisplayableMessageFormat[]>;
  mediaCache: MediaCache;
  isOperatorMode?: boolean;
  onDeleteMessage?(uuid: string): void;
  onScroll?(messages: DisplayableMessageFormat[]): void;
  onCacheMedia?(url: string): void;
};

export const WidgetTalkArea = compareOnlyProperties(
  ({
    loading,
    messages,
    isOperatorMode = false,
    isInputFocused,
    onScroll = () => {},
  }: WidgetTalkAreaProps & {
    isInputFocused: boolean;
    audioStatus?: AudioStatus;
    onUserInput?(a: { isTyping: boolean; text: string }): void;
  }) => {
    const { environment } = useContext(WidgetEnvContext);
    const { config } = useContext(WidgetContext);
    const [scrollTop, setScrollTop] = useRefState(0);
    const rootRef = useRef<HTMLDivElement>(null);
    const [timerId, setTimerId] = useRefState<number | null>(null);
    const renderContents = (msg: ReadonlyDeep<DisplayableMessageFormat>) => {
      const sender = msg.sender.type;
      switch (msg.type) {
        case 'plainText':
        case 'postback':
          return (
            <WidgetPlainTextMessage
              sender={sender}
              text={msg.text !== null ? msg.text : '(data format error)'}
            />
          );
        default:
          return;
      }
    };

    // メッセージコンテンツ出し分け
    const { error } = useContext(WidgetContext);
    const renderTalkArea = (messages: ReadonlyDeep<DisplayableMessageFormat[]>) => {
      return messages.flatMap((msg, index) => {
        let isError = false;
        error?.uuid.forEach(id => {
          if (!isError) {
            isError = msg.uuid === id;
          }
        });
        const sender = msg.sender.type;
        const key = msg.uuid;
        const messageContent = renderContents(msg);
        if (!messageContent) {
          return [];
        }

        return [
          <WidgetTalkAreaMessageElement isSendFailed={isError} key={key} sender={sender} label={''}>
            <WidgetMessageContainer
              key={key}
              setsize={5}
              index={index}
              sender={sender}
              isSendFailed={isError}
              pictureUrl={
                sender === 'Customer'
                  ? config.customerIconSrc
                  : sender === 'Bot'
                  ? config.botIconSrc
                  : ''
              }
              pictureSvg={
                sender === 'Customer' ? <UserIcon /> : sender === 'Bot' ? <BotIcon /> : undefined
              }
            >
              {messageContent}
            </WidgetMessageContainer>
          </WidgetTalkAreaMessageElement>,
        ];
      });
    };

    useWidgetScrolling({
      rootElementRef: rootRef,
      isInputFocused,
      environment,
      messages,
      onScroll,
    });

    useiOSOverScrollFreezeFix({
      containerElementRef: rootRef,
      environment,
    });

    const cancelAnimationFrameId = useRef(0);
    const scrollHandler = () => {
      cancelAnimationFrame(cancelAnimationFrameId.current);
      cancelAnimationFrameId.current = requestAnimationFrame(() => {
        rootRef.current && setScrollTop(rootRef.current.scrollTop);
      });
    };

    useEffect(() => {
      if (timerId.current) {
        window.clearInterval(timerId.current);
        setTimerId(null);
      }
      if (isOperatorMode) {
        const tid = window.setInterval(() => {}, 60000);
        setTimerId(tid);
      }
      return () => {
        if (timerId.current) {
          window.clearInterval(timerId.current);
          setTimerId(null);
        }
      };
    }, []);

    return (
      <>
        <WidgetTalkAreaContainerElement
          role="main"
          ref={rootRef}
          onScroll={() => {
            scrollHandler();
          }}
        >
          <AnimatePresence>
            {loading ? (
              <div
                css={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: scrollTop.current,
                }}
              >
                <Loading scale={0.5} isModalEnabled={true} />
              </div>
            ) : null}
          </AnimatePresence>
          <WidgetTalkAreaOrderListElement role="feed" aria-busy={loading}>
            {renderTalkArea(messages)}
          </WidgetTalkAreaOrderListElement>
        </WidgetTalkAreaContainerElement>
      </>
    );
  },
  'WidgetTalkArea'
);
