/**
 * @fileOverview
 * @name action.ts<message>
 * @author Taketoshi Aono
 * @license
 */

import { EventRequestParam } from '@s/domain/values/EventRequestParam';
import { RequestError } from '@w/application/RequestError';
import { DisplayableMessageFormat } from '@s/components/atom/WidgetMessageConfig';

export const eventRequested = ({
  eventRequest,
  isOperatorChatting,
}: {
  eventRequest: EventRequestParam;
  isOperatorChatting: boolean;
}) => {
  return {
    type: 'AIM_WIDGET_EVENT_REQUESTED' as const,
    payload: { eventRequest, isOperatorChatting },
  };
};

export const eventRequestSucceeded = () => {
  return {
    type: 'AIM_WIDGET_MESSAGE_SUCCEEDED' as const,
  };
};

export const eventRequestFailed = (error: RequestError, uuid: string) => {
  return {
    type: 'AIM_WIDGET_EVENT_FAILED' as const,
    error,
    uuid,
  };
};

export const messageReceived = (msg: DisplayableMessageFormat) => {
  return {
    type: 'AIM_WIDGET_MESSAGE_RECEIVED' as const,
    message: msg,
  };
};

export const messagesReceived = (messages: DisplayableMessageFormat[]) => {
  return {
    type: 'AIM_WIDGET_MESSAGES_RECEIVED' as const,
    messages,
  };
};

export const messageDeleted = (uuid: string) => {
  return {
    type: 'AIM_WIDGET_MESSAGE_DELETED' as const,
    uuid,
  };
};

export const messageUpdated = (msg: DisplayableMessageFormat[]) => {
  return {
    type: 'AIM_WIDGET_MESSAGE_UPDATED' as const,
    message: msg,
  };
};

export const welcomeMessageTransferingRequired = () => {
  return {
    type: 'WELCOME_MESSAGE_TRANSFERING_REQUIRED' as const,
  };
};

export const operatorTypingChanged = ({ isTyping }: { isTyping: boolean }) => {
  return {
    type: 'AIM_WIDGET_OPERATOR_TYPING_CHANGED' as const,
    payload: isTyping,
  };
};

export const mediaCached = (payload: { url: string; cacheUrl: string }) => {
  return {
    type: 'AIM_WIDGET_MEDIA_CACHED' as const,
    payload: payload,
  };
};

export const isCustomerImageUploadEnabledChanged = ({ enable }: { enable: boolean }) => {
  return {
    type: 'AIM_WIDGET_IS_CUSTOMER_IMAGE_UPLOAD_ENABLED_CHANGED' as const,
    payload: enable,
  };
};
