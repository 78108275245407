/**
 * @fileOverview
 * @name useKeyboardAppearanceWaiter.ts
 * @author Taketoshi Aono
 * @license
 */

import { useRef, useCallback } from 'react';

export const useKeyboardAppearanceWaiter = ({
  isInputFocused,
  containerElementRef,
}: {
  isInputFocused: boolean;
  containerElementRef: React.RefObject<HTMLElement | null>;
}): ((onComplete: () => void) => Promise<void>) => {
  const isInputFocusedRef = useRef({
    current: !isInputFocused,
    next: isInputFocused,
  });
  const clientHeightRef = useRef(0);
  isInputFocusedRef.current.next = isInputFocused;

  return useCallback(async (onComplete: () => void): Promise<void> => {
    if (!containerElementRef.current) {
      return;
    }

    if (isInputFocusedRef.current.current !== isInputFocusedRef.current.next) {
      const MAX = 10;
      const wait = async (cur: number) => {
        if (!containerElementRef.current) {
          return;
        }
        if (cur === MAX) {
          return onComplete();
        }
        if (containerElementRef.current.clientHeight !== clientHeightRef.current) {
          return new Promise<void>(resolve => {
            setTimeout(async () => {
              if (!containerElementRef.current) {
                return;
              }
              clientHeightRef.current = containerElementRef.current.clientHeight;
              await wait(cur + 1);
              resolve();
            }, 500);
          });
        }
      };
      await wait(1);
      isInputFocusedRef.current.current = isInputFocusedRef.current.next;
      clientHeightRef.current = 0;
      onComplete();
    }
  }, []);
};
