/**
 * @fileOverview
 * @name useiOSOverScrollFreezeFix.ts
 * @author Taketoshi Aono
 * @license
 */

import React, { useEffect } from 'react';
import { PASSIVE, UNPASSIVE } from '@s/detectDomFeature';
import { Environment } from '../Environment';

export const useiOSOverScrollFreezeFix = ({
  environment,
  containerElementRef,
}: {
  environment: Environment;
  containerElementRef: React.RefObject<HTMLElement | null>;
}) => {
  if (environment.platform.os.name !== 'iOS') {
    return;
  }

  useEffect(() => {
    if (!containerElementRef.current) {
      return;
    }
    let lastY = 0;
    const touchStartHandler = (e: TouchEvent) => {
      lastY = e.touches[0].clientY;
    };
    const touchmoveHandler = (e: TouchEvent) => {
      if (!containerElementRef.current) {
        return;
      }
      const top = e.touches[0].clientY;
      const scrollTop = containerElementRef.current.scrollTop;
      const direction = lastY - top < 0 ? 'up' : 'down';

      if (scrollTop <= 0 && direction == 'up') {
        // Prevent scrolling up when already at top as this introduces a freeze.
        e.preventDefault();
      } else if (
        scrollTop >=
          containerElementRef.current.scrollHeight - containerElementRef.current.clientHeight &&
        direction === 'down'
      ) {
        // Prevent scrolling down when already at bottom as this also introduces a freeze.
        e.preventDefault();
      }

      lastY = top;
    };

    containerElementRef.current.addEventListener('touchstart', touchStartHandler, PASSIVE);
    containerElementRef.current.addEventListener('touchmove', touchmoveHandler, UNPASSIVE);

    return () => {
      if (!containerElementRef.current) {
        return;
      }
      containerElementRef.current.removeEventListener('touchstart', touchStartHandler);
      containerElementRef.current.removeEventListener('touchmove', touchmoveHandler);
    };
  }, [containerElementRef.current]);
};
