import React from 'react';

export const CenterIcon = React.memo(() => {
  return (
    <span aria-hidden={true}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.280152 12.0584L0.280153 1.49585C0.280153 1.17262 0.408557 0.862627 0.637117 0.634067C0.865676 0.405508 1.17567 0.277103 1.4989 0.277103L12.0614 0.277104C12.3846 0.277104 12.6946 0.405509 12.9232 0.634068C13.1517 0.862628 13.2802 1.17262 13.2802 1.49585C13.2802 1.81909 13.1517 2.12908 12.9232 2.35764C12.6946 2.5862 12.3846 2.7146 12.0614 2.7146L4.44422 2.7146L15.3612 13.6346C15.4745 13.748 15.5645 13.8826 15.6258 14.0307C15.6872 14.1788 15.7188 14.3376 15.7188 14.4979C15.7188 14.8217 15.5901 15.1322 15.3612 15.3612C15.1322 15.5901 14.8217 15.7187 14.4979 15.7187C14.1741 15.7187 13.8636 15.5901 13.6346 15.3612L2.71765 4.44117L2.71765 12.0584C2.71765 12.3816 2.58925 12.6916 2.36069 12.9201C2.13213 13.1487 1.82213 13.2771 1.4989 13.2771C1.17567 13.2771 0.865675 13.1487 0.637116 12.9201C0.408556 12.6916 0.280152 12.3816 0.280152 12.0584Z"
          fill="#0F172A"
        />
      </svg>
    </span>
  );
});
