import { compareOnlyProperties } from '@s/compareOnlyProperties';
import React from 'react';
import styled from '@emotion/styled';
import { AudioPlayIcon } from '@s/components/atom/AudioPlayIcon';
import { AudioStatus } from '@s/domain/entity/Audio';
import { AudioPauseIcon } from '@s/components/atom/AudioPauseIcon';

const PlayButtonElement = styled.button`
  cursor: pointer;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 0;
  outline: none;
`;

export const AudioSeekButton = compareOnlyProperties(
  ({
    seekTime,
    status,
    onClick,
  }: {
    seekTime: number;
    status: AudioStatus;
    onClick(seekTime: number): void;
  }) => {
    return (
      <PlayButtonElement
        onClick={() => {
          onClick(seekTime);
        }}
      >
        {(status === 'canplay' || status === 'pause') && <AudioPlayIcon color="#000000" />}
        {status === 'playing' && <AudioPauseIcon color="#000000" />}
        {(status === 'error' || status === 'fetalError') && <AudioPlayIcon color="#F2F2F2" />}
      </PlayButtonElement>
    );
  },
  'AudioSeekButton'
);
