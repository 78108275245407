/**
 * @fileoverview
 * @author
 */

import md5 from 'md5';

/**
 * generate
 * @return {string}
 */
export const rng = () => {
  return `${md5(Math.random().toString())}`;
};
