/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import { AiMessenger } from './AiMessenger';
import window from './window';
import { createApiProxy } from './util/createApiProxy';
import '@s/dom/proxifyInlineStyle';
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (typeof window.aiMessenger !== 'object' || !window.aiMessenger.run) {
  const nativeInstanceRef = { nativeInstance: undefined as any as AiMessenger };
  const AiMessengerProxy = createApiProxy(AiMessenger, nativeInstanceRef);
  const aiMessenger = (window.aiMessenger = new AiMessengerProxy() as AiMessenger);
  const run = async (): Promise<void> => {
    window.removeEventListener('DOMContentLoaded', run);
    await nativeInstanceRef.nativeInstance.prepareDebuggerIfNecessary();
    aiMessenger.run();
  };

  /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
  if (document.body) {
    run();
  } else {
    window.addEventListener('DOMContentLoaded', run, false);
  }
} else {
  window.__AIM_DUPLICATION_WARNING__ = true;
  new AiMessenger().prepareDebuggerIfNecessary();
}
