/**
 * @fileOverview
 * @name useOrientationChange.ts
 * @author Taketoshi Aono
 * @license
 */

import { useEffect } from 'react';
import { PASSIVE } from '@s/detectDomFeature';
import { Environment } from '../Environment';

export const useOrientationChange = ({
  onChange,
  environment,
}: {
  environment: Environment;
  onChange(orientation: ScreenOrientation): void;
}) => {
  if (environment.isSupportedPcBrowser) {
    return;
  }
  useEffect(() => {
    window.addEventListener(
      'orientationchange',
      () => {
        onChange(screen.orientation);
      },
      PASSIVE
    );
  }, []);
};
