import React from 'react';
import { Widget, WidgetProps } from '@s/components/ecosystem/Widget';
import { SearchWidget } from '@s/components/ecosystem/SearchWidget';
import { ThunkDeps } from '@w/store/ThunkDeps';

export class Container extends React.Component<
  WidgetProps & {
    store: any;
    reportCrashed: ThunkDeps['reportCrashed'];
    design: 'normal' | 'search';
  }
> {
  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.reportCrashed({
      error,
      state: this.props.store.getState(),
      additionalData: errorInfo,
    });
  }

  public render() {
    if (this.props.design === 'search') return <SearchWidget {...this.props} />;
    return <Widget {...this.props} />;
  }
}
