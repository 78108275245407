/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useRef, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { largeTextStyle } from '../atom/Text';
import { Button } from '../atom/Button';
import { scrollbarStyle } from '../atom/ScrollbarStyle';
import { Loading } from '../atom/Loading';
import { WidgetPictureUploadIcon } from '../atom/WidgetPictureUploadIcon';
import { motion, AnimatePresence } from 'framer-motion';
import { useRefState } from '@s/reactHooks';
import { WidgetContext } from '../atom/WidgetContext';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { WidgetEnvContext } from '../atom/WidgetEnvContext';
import { InputStateValues } from '@s/domain/values/InputStateValues';
import { isValidInputValueForInputState } from '@s/domain/specification/WidgetInputValueSpecification';
import { PLACEHOLDER_MAP } from '@s/domain/specification/WidgetInputValueSpecification';
import { WidgetType } from '../atom/WidgetType';

const WidgetMessageInputContainerElement = styled(motion.div)`
  flex-shrink: 0;
  flex-grow: 1;
  overflow: hidden;
  max-height: 0px;
`;

const WidgetInternalRootContainerElement = styled.div`
  width: 100%;
  min-height: 74px;
  background: #fff;
  border-top: 1px solid #ccc;
  display: flex;
  padding: 10px 0;
  align-items: center;
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
`;

const MessageTextContainerElement = styled.div`
  position: relative;
  flex: 10 10 100%;
  max-height: auto;
`;

const MessageText = styled.textarea`
  top: 0px;
  left: 0px;
  position: absolute;
  margin: 0;
  border: 0;
  padding: 10px;
  resize: none;
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  ${largeTextStyle};
  min-height: 50px;
  max-height: 300px;
  height: 100%;
  outline: none;
  background: #fff;
  box-sizing: border-box;
  ${scrollbarStyle};
`;
const MessageTextHeightAdjuster = styled.div<{ isPc: boolean }>`
  visibility: hidden;
  padding: 10px;
  width: calc(100% - 20px);
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  min-height: 50px;
  max-height: ${p => (p.isPc ? '300px' : '20vh')};
  ${largeTextStyle};
`;

const SendIcon = React.memo(
  ({}: {}) => {
    const { config } = useContext(WidgetContext);
    return (
      <span aria-hidden={true}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <title>icon</title>
          <path
            fill={config.chatColoring.common.color}
            d="M203.92,284.94,475.31,13.69,4.5,170.5,202.37,287A9.94,9.94,0,0,1,203.92,284.94Z"
          />
          <path
            fill={config.chatColoring.common.color}
            d="M218.08,299.06a9.63,9.63,0,0,1-2.09,1.59L331.3,497.3,487.92,27.09Z"
          />
        </svg>
      </span>
    );
  },
  () => false
);

const SendButtonContainerElement = styled.div`
  margin-right: 10px;
`;
const WidgetPictureUploadIconContainerElement = styled(motion.div)`
  margin: 0 10px;
`;

const ImageInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  top: -9999px;
  z-index: -999;
`;

const ImageInputWrapper = styled.label``;
export interface WidgetMessageInputProps {
  visible: boolean;
  loading: boolean;
  textInputState: InputStateValues;
  enableImageUpload: boolean;
  isDesignerMode?: boolean;
  type: WidgetType;
  onSendMessage?(msg: string): void;
  onSendImage?(img: File): void;
  onUserInput(a: { text: string; isTyping: boolean }): void;
}

export const WidgetMessageInput = compareOnlyProperties(
  ({
    visible,
    loading,
    textInputState,
    enableImageUpload,
    isDesignerMode = false,
    type,
    onSendMessage = () => {},
    onSendImage = () => {},
    onFocus,
    onBlur,
    onUserInput,
  }: WidgetMessageInputProps & { onFocus(): void; onBlur(): void }) => {
    const [text, updateText] = useRefState('');
    const textArea = useRef<HTMLTextAreaElement | null>(null);
    const { environment } = useContext(WidgetEnvContext);
    const { config } = useContext(WidgetContext);
    const deleteInputTimerRef = useRef<any>(null);
    const updateInputTimerRef = useRef<any>(null);
    const imageInputRef = useRef<any>(null);

    useEffect(() => {
      if (
        environment.isSupportedPcBrowser &&
        visible &&
        textArea.current &&
        !loading &&
        type == WidgetType.FLOATING
      ) {
        textArea.current.focus();
      }
    }, [loading, textArea.current, textInputState]);

    useEffect(() => {
      clearTimeout(deleteInputTimerRef.current);
      clearTimeout(updateInputTimerRef.current);
      if (text.current) {
        updateInputTimerRef.current = setTimeout(() => {
          onUserInput({ text: text.current, isTyping: true });
          deleteInputTimerRef.current = setTimeout(() => {
            clearTimeout(updateInputTimerRef.current);
            onUserInput({ text: '', isTyping: false });
          }, 20000);
        }, 500);
      } else {
        clearTimeout(updateInputTimerRef.current);
        onUserInput({ text: '', isTyping: false });
      }
    }, [text.current]);
    const isOldBrowser =
      environment.platform.browser.name === 'IE' && environment.platform.browser.major === '11';

    const tree = visible ? (
      <WidgetMessageInputContainerElement
        key="1"
        css={isOldBrowser ? { maxHeight: 500 } : {}}
        initial={
          isOldBrowser
            ? {}
            : {
                maxHeight: 0,
              }
        }
        animate={
          isOldBrowser
            ? {}
            : {
                maxHeight: 500,
              }
        }
        exit={
          isOldBrowser
            ? {}
            : {
                maxHeight: 0,
                transition: { type: 'tween', duration: 0.4 },
              }
        }
        transition={{ type: 'tween', duration: 1, delay: 0.6 }}
      >
        <WidgetInternalRootContainerElement>
          <AnimatePresence>
            {!loading && enableImageUpload ? (
              <WidgetPictureUploadIconContainerElement
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'tween', duration: 0.4 }}
              >
                <ImageInputWrapper>
                  {!isDesignerMode ? (
                    <ImageInput
                      ref={imageInputRef}
                      type="file"
                      // eslint-disable-next-line  @typescript-eslint/no-unnecessary-type-arguments
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files) {
                          onSendImage(e.target.files[0]);
                          imageInputRef.current.value = '';
                        }
                      }}
                    />
                  ) : null}
                  <WidgetPictureUploadIcon />
                </ImageInputWrapper>
              </WidgetPictureUploadIconContainerElement>
            ) : null}
          </AnimatePresence>
          <MessageTextContainerElement>
            {loading ? <Loading scale={0.3} isModalEnabled={true} /> : null}
            <MessageText
              placeholder={
                environment.isSupportedMobileBrowser
                  ? PLACEHOLDER_MAP[textInputState]
                  : `${PLACEHOLDER_MAP[textInputState]}（Ctrl + Enterで送信)`
              }
              ref={textArea}
              disabled={loading || isDesignerMode}
              value={text.current}
              tabIndex={loading ? -1 : 0}
              onFocus={() => onFocus()}
              onBlur={() => onBlur()}
              onChange={e => {
                const value = e.target.value;
                if (
                  isValidInputValueForInputState({
                    state: textInputState,
                    value,
                  })
                ) {
                  updateText(value);
                }
              }}
              onKeyUp={e => {
                if (e.key === 'Enter' && (e.shiftKey || e.ctrlKey)) {
                  if (text.current.length === 0) {
                    return;
                  }
                  onSendMessage(text.current);
                  updateText('');
                  e.preventDefault();
                }
              }}
            />
            <MessageTextHeightAdjuster isPc={environment.isSupportedPcBrowser} aria-hidden={true}>
              {text.current.replace(/\n/g, '\n_')}
            </MessageTextHeightAdjuster>
          </MessageTextContainerElement>
          <SendButtonContainerElement>
            <Button
              tabIndex={loading ? -1 : 0}
              type={{
                color: config.chatColoring.common.color,
                background: config.chatColoring.common.backgroundHover,
              }}
              iconSvg={<SendIcon />}
              label="送信"
              loading={loading}
              onClick={() => {
                if (text.current.length === 0) {
                  return;
                }
                clearTimeout(updateInputTimerRef.current);
                onUserInput({ text: '', isTyping: false });
                onSendMessage(text.current);
                updateText('');
              }}
            />
          </SendButtonContainerElement>
        </WidgetInternalRootContainerElement>
      </WidgetMessageInputContainerElement>
    ) : null;

    return isOldBrowser ? tree : <AnimatePresence>{tree}</AnimatePresence>;
  },
  'WidgetMessageInput'
);
