/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { WIDGET_WIDTH, getWidgetHeight } from '../atom/WidgetConstant';
import { Loading } from '@s/components/atom/Loading';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { useRefState } from '@s/reactHooks';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { CrossbarIcon } from '../atom/CrossbarIcon';
import { IMAGEBOX_CONTAINER_ID, IMAGEBOX_ID } from './widgetImagePopupConfig';
import { WidgetEnvContext } from '../atom/WidgetEnvContext';
import { WidgetContext } from '../atom/WidgetContext';

const ImageContainerElement = motion.div;

const imageTransition = { type: 'tween', duration: 0.3 };
const ImagePopup = compareOnlyProperties(
  ({ img, onClose }: { img: HTMLImageElement; onClose(): void }) => {
    return createPortal(
      <>
        <div id={IMAGEBOX_CONTAINER_ID} onClick={() => onClose()}>
          <ImageContainerElement
            id={IMAGEBOX_ID}
            key="aim_image_box"
            initial={{ translateX: 20, opacity: 0 }}
            animate={{ translateX: 0, opacity: 1 }}
            exit={{ translateX: 20, opacity: 0, transition: imageTransition }}
            transition={imageTransition}
            style={{
              width: img.width,
              height: img.height,
              backgroundImage: `url(${img.src})`,
            }}
            onClick={e => e.stopPropagation()}
          >
            <button
              tabIndex={0}
              id="aim__widget-image-box__close-icon-container"
              onClick={() => onClose()}
            >
              <CrossbarIcon color="#222" />
            </button>
          </ImageContainerElement>
        </div>
      </>,
      document.body
    );
  }
);

const MessageContainer = styled.button<{ isSupportedMobileBrowser: boolean }>`
  cursor: pointer;
  border: 0;
  border-radius: 8px;
  overflow: hidden;
  min-width: 100px;
  min-height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  outline: none;
  border: 1px solid #ddd;
  &:focus {
    ${p => (!p.isSupportedMobileBrowser ? 'box-shadow: 0px 0px 4px rgba(0, 0, 255, 0.6)' : '')};
  }
`;

export interface WidgetImageMessageProps {
  src: string;
}

const getSize = (isMobile: boolean, distanceFromBottomEdge: number, height: number) => {
  return isMobile
    ? {
        width: '70vw',
        height: '30vh',
      }
    : {
        width: `${WIDGET_WIDTH - 100}px`,
        height: `${Math.round(getWidgetHeight(distanceFromBottomEdge, height) / 3)}px`,
      };
};

export const WidgetImageMessage = compareOnlyProperties(({ src }: WidgetImageMessageProps) => {
  const [image, setImage] = useRefState<HTMLImageElement | null>(null);
  const [imagePopupVisibility, setImagePopupVisibility] = useRefState(false);
  const { environment } = useContext(WidgetEnvContext);
  const { config } = useContext(WidgetContext);
  const { width, height } = getSize(
    environment.isSupportedMobileBrowser,
    config.distanceFromScreenEdges?.y ?? 0,
    config.floatingChatWindowHeight
  );

  useEffect(() => {
    const i = new window.Image();
    i.onload = () => {
      setImage(i);
    };
    i.src = decodeURIComponent(src);
  }, [src]);
  return image.current ? (
    <>
      <AnimatePresence>
        {imagePopupVisibility.current ? (
          <ImagePopup
            key="aim__widget-image-popup"
            img={image.current}
            onClose={() => setImagePopupVisibility(false)}
          />
        ) : null}
      </AnimatePresence>
      <MessageContainer
        role="img"
        aria-label="画像メッセージ"
        onClick={() => setImagePopupVisibility(true)}
        isSupportedMobileBrowser={environment.isSupportedMobileBrowser}
        css={{
          width,
          height,
          backgroundImage: `url(${src})`,
        }}
      />
    </>
  ) : (
    <MessageContainer
      isSupportedMobileBrowser={environment.isSupportedMobileBrowser}
      css={{
        width,
        height,
        position: 'relative',
        background: '#FFF',
        border: '1px solid #DDD',
      }}
    >
      <Loading scale={0.2} />
    </MessageContainer>
  );
}, 'WidgetImageMessage');
