/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { CrossbarIcon } from './CrossbarIcon';
import { WidgetContext } from './WidgetContext';

const { useState } = React;

const WidgetCloseContainer = styled.button<{ designerMode: boolean }>`
  cursor: ${p => (p.designerMode ? 'default' : 'pointer')};
  background: transparent;
  border: 0;
  padding: 0;
`;

export interface WidgetCloseProps {
  width: number;
  height: number;
  onClose?(): void;
  isDesignerMode?: boolean;
}

export const WidgetClose = ({
  width,
  height,
  onClose = () => {},
  isDesignerMode = false,
}: WidgetCloseProps) => {
  const { config } = useContext(WidgetContext);

  const [color, setColor] = useState<string>(config.chatColoring.common.color);

  useEffect(() => {
    setColor(config.chatColoring.common.color);
  }, [config]);

  return (
    <WidgetCloseContainer
      type="button"
      css={{ width, height }}
      tabIndex={0}
      onMouseOver={() => {
        !isDesignerMode && setColor(config.chatColoring.common.colorHover);
      }}
      onMouseOut={() => {
        !isDesignerMode && setColor(config.chatColoring.common.color);
      }}
      onClick={() => onClose()}
      aria-label="閉じる"
      designerMode={isDesignerMode}
    >
      <CrossbarIcon color={color} />
    </WidgetCloseContainer>
  );
};
