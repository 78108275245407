/**
 * @fileOverview
 * @name useAndroidKeyboardScrollFix.ts
 * @author Taketoshi Aono
 * @license
 */

import { useEffect, useRef } from 'react';
import { Environment } from '../Environment';

export const useAndroidKeyboardScrollFix = ({
  environment,
  isInputFocused,
  onTick,
}: {
  environment: Environment;
  isInputFocused: boolean;
  onTick(): void;
}) => {
  if (environment.platform.os.name !== 'Android') {
    return;
  }

  const internval = useRef<any>(0);
  useEffect(() => {
    clearInterval(internval.current);
    if (isInputFocused) {
      internval.current = setInterval(() => {
        onTick();
      }, 600);
    } else {
      onTick();
    }
  }, [isInputFocused]);
};
