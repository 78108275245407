import React, { forwardRef, useContext } from 'react';
import styled from '@emotion/styled';
import { WidgetAvatar } from '@s/components/atom/WidgetAvatar';
import { WidgetEnvContext } from '@s/components/atom/WidgetEnvContext';
import { MessageSender } from '@s/domain/entity/MessageFormat';
import { UserAvatar } from './UserAvatar';
import { WidgetLayoutContext } from '@s/components/ecosystem/contexts/WidgetLayout';

const WidgetMessageContainerElement = styled.article`
  position: relative;
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  transition: all 0.5s 0s ease;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export interface WidgetMessageContainerProps {
  pictureUrl?: string;
  pictureSvg?: React.ReactElement;
  children: React.ReactNode;
  isSendFailed?: boolean;
  sender: MessageSender;
  index: number;
  setsize: number;
}

export const WidgetMessageContainer = forwardRef(
  (
    {
      children,
      sender,
      pictureSvg,
      isSendFailed,
      pictureUrl,
      index,
      setsize,
    }: WidgetMessageContainerProps,
    ref: React.Ref<any>
  ) => {
    const { environment, type } = useContext(WidgetEnvContext);
    const widgetLayoutContext = useContext(WidgetLayoutContext);

    return (
      <WidgetMessageContainerElement
        css={{
          flexDirection: 'row',
          width: widgetLayoutContext.layout === 'center' ? '80%' : '95%',
        }}
        aria-posinset={index}
        aria-setsize={setsize}
        ref={ref}
      >
        {sender === 'Customer' && <UserAvatar />}
        {pictureUrl ? (
          <WidgetAvatar pictureUrl={pictureUrl} sender={sender} />
        ) : pictureSvg && sender !== 'Customer' ? (
          pictureSvg
        ) : null}
        <div
          css={{
            maxWidth: environment.isSupportedMobileBrowser ? '70vw' : '80%',
          }}
        >
          {children}
        </div>
      </WidgetMessageContainerElement>
    );
  }
);
