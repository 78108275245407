/**
 * @fileOverview
 * @name ChatStateValue.ts
 * @author Taketoshi Aono
 * @license
 */

export interface ChatStateValue {
  isMinimized: boolean;
}

export const getDefaultChatStateValue = (): ChatStateValue => {
  return {
    isMinimized: false,
  };
};
