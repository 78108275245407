import messageReducer from '@w/module/message/reducer';
import { combineReducers } from 'redux';
import chatReducer from '@w/module/chat/reducer';
import authReducer from '@w/module/auth/reducer';
import envReducer from '@w/module/env/reducer';
import { MessageService } from '@w/domain/service/message/MessageService';
import { ActionLogQueueable } from '@aim/shared/src/application/service/ActionLogQueuingService';

const rootReducer = combineReducers({
  message: messageReducer(state => new MessageService(state)),
  chat: chatReducer,
  auth: authReducer,
  env: envReducer,
});

export default (actionLogQueuingService: ActionLogQueueable) => {
  return (state: Parameters<typeof rootReducer>[0], action: Parameters<typeof rootReducer>[1]) => {
    actionLogQueuingService.enqueue(action);
    return rootReducer(state, action);
  };
};
