/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useState } from 'react';
import { PictureIcon } from './PictureIcon';
import styled from '@emotion/styled';

const IconContainer = styled.div`
  border: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;
  background: transparent;
  padding: 0;
`;

export const WidgetPictureUploadIcon = ({ tabIndex = 0 }: { tabIndex?: number }) => {
  const [color, setColor] = useState<string>('#222');

  return (
    <IconContainer
      onMouseEnter={() => setColor('#AAA')}
      onMouseLeave={() => setColor('#CCC')}
      tabIndex={tabIndex}
      aria-label="画像をアップロードする"
    >
      <PictureIcon aria-hidden={true} color={color} />
    </IconContainer>
  );
};
