/**
 * @fileOverview
 * @name WidgetInputValueSpecification.ts
 * @author Taketoshi Aono
 * @license
 */

import { InputStateValues } from '../values/InputStateValues';

export const REGEX_MAP: { [P in InputStateValues]: RegExp } = {
  show: /^[\s\S]*$/,
  hide: /^[\s\S]*$/,
  number: /^[0-9]*$/,
  alphaAndNumber: /^[0-9a-zA-Z]*$/,
  phone: /^[0-9][0-9-]*$/,
  email: /^[a-z_@+0-9.-]*$/,
  myNumber: /^[0-9]*$/,
  drivingLicence: /^[0-9]*$/,
  passport: /^[a-zA-Z0-9]*$/,
  residentCode: /^[0-9]*$/,
};
export const PLACEHOLDER_MAP: { [P in InputStateValues]: string } = {
  show: 'メッセージを入力',
  hide: '',
  number: '数値を入力',
  alphaAndNumber: '英数字を入力',
  phone: '電話番号を入力',
  email: 'メールアドレスを入力',
  myNumber: 'マイナンバーを入力',
  drivingLicence: '運転免許証番号を入力',
  passport: 'パスポート番号を入力',
  residentCode: '住民票コードを入力',
};

export const isValidInputValueForInputState = ({
  state,
  value,
}: {
  state: InputStateValues;
  value: string;
}) => {
  const regexp = REGEX_MAP[state];
  return regexp.test(value);
};
