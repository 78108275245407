/**
 * @fileOverview
 * @name reducer.ts
 * @author Taketoshi Aono
 * @license
 */

import { authSucceeded, previewTokenStored } from '@w/module/auth/action';
import { AuthState } from '@w/domain/entities/State';
import produce from 'immer';

type ActionTypes = ReturnType<typeof authSucceeded | typeof previewTokenStored>;

export default (
  state: AuthState = { auth: { token: '', claims: {} } },
  action: ActionTypes
): AuthState => {
  return produce(state, state => {
    switch (action.type) {
      case 'AUTH_SUCCEEDED':
        state.auth = action.payload;
        return;
      case 'PREVIEW_TOKEN_STORED':
        state.previewToken = action.payload.token;
        return;
      default:
        return state;
    }
  });
};
