/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React from 'react';

export const BotIcon = ({ width = 50, height = 50 }: { width?: number; height?: number }) => {
  return (
    <div css={{ width, height }} className="bot-icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 970 970">
        <defs>
          <style>{`.bot-icon .a{fill:#0cf;} .bot-icon .b{fill:#aaa;} .bot-icon .c{fill:#ccc;}.bot-icon .d{fill:#06f;}.bot-icon .e{fill:#ddd;}`}</style>
        </defs>
        <title>bot-icon</title>
        <circle className="a" cx="485" cy="485" r="485" />
        <path
          className="b"
          d="M164,400.24A154.82,154.82,0,0,1,165.09,382h-13.5A25.66,25.66,0,0,0,126,407.59V512.41A25.66,25.66,0,0,0,151.59,538H164V400.24Z"
          transform="translate(-3.5 -7.5)"
        />
        <path
          className="c"
          d="M565.61,702c-19.55,1.84-52,7.08-78.61,22-44.83,25.15-41,50-41,50l-2.59,1H551a164.73,164.73,0,0,1,32,3.13V702Z"
          transform="translate(-3.5 -7.5)"
        />
        <path
          className="c"
          d="M239,618.61c-62-67.61-49-152-49-195,0-148.72,98.5-173.59,123.23-177.5C237,248.89,174.33,307.47,165.16,382A154.86,154.86,0,0,0,164,400.88V546.19C164,631.34,233.66,701,318.81,701H409C354,684.39,299,684,239,618.61Z"
          transform="translate(-3.5 -7.5)"
        />
        <path
          className="a"
          d="M755,529.17V463.83C755,403.43,705.57,354,645.17,354H341.83C281.43,354,232,403.43,232,463.83v65.34C232,589.57,281.43,639,341.83,639H645.17C705.57,639,755,589.57,755,529.17ZM355,553a60,60,0,1,1,60-60A60,60,0,0,1,355,553Zm223-60a60,60,0,1,1,60,60A60,60,0,0,1,578,493Z"
          transform="translate(-3.5 -7.5)"
        />
        <circle className="d" cx="351.5" cy="485.5" r="60" />
        <circle className="d" cx="634.5" cy="485.5" r="60" />
        <path
          className="e"
          d="M490.5,24A73.5,73.5,0,0,0,417,97.5c0,.12,0,.24,0,.36l13.76,22.84c8.06,9.68,15.91,18.66,26.32,24.89,8.87,5.3,19.59,8.6,33.89,8.6s24.58-3.14,33.11-8.6c9.83-6.3,17.51-15.68,27.11-26.88L564,99.37c0-.62,0-1.24,0-1.87A73.5,73.5,0,0,0,490.5,24Z"
          transform="translate(-3.5 -7.5)"
        />
        <path
          className="b"
          d="M551.2,118.71c-9.6,11.2-17.28,20.58-27.11,26.88-8.53,5.46-18.67,8.6-33.11,8.6s-25-3.3-33.89-8.6c-10.41-6.23-18.26-15.21-26.32-24.89L417,97.86A73.48,73.48,0,0,0,564,99.37Z"
          transform="translate(-3.5 -7.5)"
        />
        <path
          className="e"
          d="M409,701l174,1h76.12C744.3,702,814,632.3,814,547.12V538h11.41A25.66,25.66,0,0,0,851,512.41V407.59A25.66,25.66,0,0,0,825.41,382H812.84c-9.39-76.36-74.93-136-153.72-136H531.46V158.72a73.78,73.78,0,0,1-80.62.21V246h-132c-1.89,0-3.77.05-5.65.11C288.5,250,190,274.89,190,423.61c0,43-11.84,128.82,49,195,57,62,108,71,168,82l2,.39M755,529.17C755,589.57,705.57,639,645.17,639H341.83C281.43,639,232,589.57,232,529.17V463.83C232,403.43,281.43,354,341.83,354H645.17C705.57,354,755,403.43,755,463.83Z"
          transform="translate(-3.5 -7.5)"
        />
        <path
          className="e"
          d="M583,778.13A164.73,164.73,0,0,0,551,775H443.41c-5.38,2.09-18.58,7.32-34.41,14.25-25,10.93-56.47,26.07-74,39.75-41,32-42,44-42,44-22.19,24.36,11.57,39,16.21,70l2.81,1.11.89.34c1.81.7,3.64,1.39,5.46,2.07l.69.26q12.48,4.62,25.29,8.57l.69.21c1.84.56,3.69,1.12,5.54,1.66l1.27.37c1.67.49,3.35,1,5,1.43l1.49.42,4.93,1.33,1.49.39c1.71.45,3.42.88,5.14,1.31l1.25.32c2,.49,4,1,6,1.43l.41.1q6.56,1.51,13.17,2.86l1.09.22,5.33,1,1.82.34,4.65.84,2.11.36c1.47.25,2.95.5,4.43.73l2.21.36,4.42.67,2.17.31c1.56.23,3.13.44,4.69.65l1.9.25c1.91.24,3.82.48,5.74.7l.85.1q3.35.39,6.7.73l1.31.12,5.3.5,2.26.18,4.38.35,2.57.18,4.13.26,2.68.16,4.06.2,2.7.12,4.16.15,2.59.08,4.54.1,2.21,0q3.38,0,6.76,0,3.54,0,7.08,0l2.24-.05c1.63,0,3.25-.06,4.87-.11l2.56-.08c1.56-.05,3.11-.11,4.66-.18l2.47-.11c1.74-.08,3.47-.18,5.21-.28l1.81-.1q3.5-.22,7-.49l1.4-.11q2.79-.23,5.56-.48l2.34-.22c1.56-.15,3.12-.3,4.68-.47l2.46-.26,4.73-.55,2.22-.27c2-.25,4-.52,6-.79l.83-.11q3.45-.48,6.88-1l1.79-.29,5.05-.82,2.27-.39,4.65-.83,2.25-.42,5-1,1.75-.35q3.4-.69,6.8-1.43l.21,0q3.24-.71,6.46-1.46l1.88-.44,4.83-1.17,2.12-.54c1.58-.39,3.16-.8,4.73-1.22l2-.51c1.88-.5,3.75-1,5.62-1.54l.94-.26q3.36-1,6.69-2l1-.3,5.5-1.69,1.84-.59,4.74-1.53,1.91-.64q2.47-.82,4.93-1.68l1.52-.52q6.49-2.28,12.9-4.72l1.29-.5q2.54-1,5-2l1.74-.7c1.58-.63,3.14-1.27,4.71-1.92l1.66-.69q2.64-1.11,5.28-2.25l.92-.4q3.21-1.39,6.41-2.84l0,0c2-.92,4-1.86,6.05-2.8l1.43-.68c1.58-.74,3.15-1.5,4.71-2.26l1.64-.8q2.36-1.15,4.68-2.34l1.4-.7q3-1.51,5.94-3.08C707.12,849.65,652.72,791.88,583,778.13Z"
          transform="translate(-3.5 -7.5)"
        />
        <path
          className="b"
          d="M446,774s-3.83-24.85,41-50c26.59-14.92,59.06-20.16,78.61-22L409,701v75.67c-34.21,7.08-65.57,25.05-90.08,52a179,179,0,0,0-45.24,98.71c.6.3,1.21.58,1.81.87,2.22,1.07,4.43,2.14,6.67,3.19l1.1.51c2.23,1,4.48,2.05,6.73,3.05l2.33,1q3.56,1.56,7.14,3.08l.38.16.48.2,1.29.53q3.06,1.27,6.14,2.5l1.46.57c-4.64-31-38.4-45.67-16.21-70,0,0,1-12,42-44,17.53-13.68,49-28.82,74-39.75,15.83-6.93,29-12.16,34.41-14.25Z"
          transform="translate(-3.5 -7.5)"
        />
      </svg>
    </div>
  );
};
