/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useContext, useEffect, useRef, Suspense } from 'react';
// #if process.env.NODE_ENV !== 'production'
import { WidgetChatWindowProps } from '../organism/WidgetChatWindow';
// #endif
import styled from '@emotion/styled';
import { CacheProvider } from '@emotion/react';
import createCache, { StylisElement, StylisPlugin, StylisPluginCallback } from '@emotion/cache';
import { WidgetFloat, WidgetFloatProps } from '../organism/WidgetFloat';
import { AnimatePresence } from 'framer-motion';
import { WidgetContext, WidgetRequestError } from '../atom/WidgetContext';
import {
  WIDGET_WIDTH,
  BALLOON_WIDTH,
  BALLOON_MARGIN,
  getWidgetHeight,
} from '../atom/WidgetConstant';
import { WidgetType } from '../atom/WidgetType';
import { WidgetEnvContext } from '../atom/WidgetEnvContext';
import { WidgetInternalConfig } from '../atom/WidgetConfig';
import { useBeforeMount, useWidgetMobileAdaption } from '@s/reactHooks';
import { Global } from '@emotion/react';
import { unsetStyles } from '../atom/unsetStyles';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { mdStyle } from '../atom/mdstyle';
import { AIM_WIDGET_ROOT_ID } from '@s/dom/widgetRootId';
import { WIDGET_AUTO_SCALE_CLASS_NAME } from '@s/dom/widgetAutoScaleClassName';
import { Loading } from '../atom/Loading';
import { imagePopupGlobalStyles } from '../molecule/widgetImagePopupConfig';
import { Environment } from '@s/platform/Environment';
import { getSafeAreaInset } from '@s/dom/getSafeAreaInset';

/* eslint-disable @typescript-eslint/naming-convention */
const importRetryHandler = async <T extends { default: any }>(importHandler: () => Promise<T>) => {
  const fetchModule = async (): Promise<T> => {
    try {
      const ReactComponent = await importHandler();
      return ReactComponent;
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(e);
      }
      return new Promise(resolve => {
        setTimeout(async () => resolve(await fetchModule()), 1000);
      });
    }
  };
  return fetchModule();
};

const WidgetMiniFloat = React.lazy(async () =>
  importRetryHandler(async () =>
    import(
      /* webpackChunkName: "widget-mini-float" */ '@s/components/organism/WidgetMiniFloat'
    ).then(({ WidgetMiniFloat }) => ({
      default: WidgetMiniFloat,
    }))
  )
);

const WidgetChatWindow = React.lazy(async () =>
  importRetryHandler(async () =>
    import(
      /* webpackChunkName: "widget-chat-window" */ '@s/components/organism/WidgetChatWindow'
    ).then(({ WidgetChatWindow }) => ({
      default: WidgetChatWindow,
    }))
  )
);
/* eslint-enable @typescript-eslint/naming-convention */

const PreLoading = ({ scale = 0.5 }: { scale?: number }) => (
  <div
    css={{
      width: '100%',
      height: '100%',
      border: '1px solid #CCC',
      borderRadius: '8px',
      overflow: 'hidden',
      position: 'relative',
    }}
  >
    <Loading scale={scale} />
  </div>
);

const { useState } = React;

const appendImportantPlugin: StylisPlugin = (
  element: StylisElement,
  index: number,
  children: Array<StylisElement>,
  callback: StylisPluginCallback
) => {
  // keyframes
  if (element.type === 'keyframes') {
    return;
  }

  const content = element.value.trim();

  if (element.type === 'declaration' && !content.endsWith('!important')) {
    return `${content} !important`;
  }
};

function createExtraScopePlugin(...extra: string[]) {
  const scopes = extra.map(scope => scope.trim());
  const extraScopePlugin = (
    element: StylisElement,
    index: number,
    children: Array<StylisElement>,
    callback: StylisPluginCallback
  ) => {
    if (element.type !== 'rule' || typeof element.props == 'string') {
      return;
    }

    const selector = element.props[0];
    element.props[0] = `${scopes[0]} ${selector}`;
    for (let i = 1; i < scopes.length; i++) {
      element.props.push(`${scopes[i]} ${selector}`);
    }
  };
  return extraScopePlugin;
}

const emotionCache = createCache({
  key: 'emotion-cache',
  stylisPlugins: [
    createExtraScopePlugin(`html body #${AIM_WIDGET_ROOT_ID}`),
    appendImportantPlugin,
  ],
});
const globalEmotionCache = createCache({
  key: 'global-emotion-cache',
  stylisPlugins: [appendImportantPlugin],
});

// MEMO: emotion 10との互換性を保つために設定
globalEmotionCache.compat = true;
emotionCache.compat = true;

export type WidgetProps = Omit<WidgetFloatProps, 'text' | 'onClick' | 'onClose'> &
  Omit<
    WidgetChatWindowProps,
    'headerText' | 'showCloseButton' | 'onClick' | 'loading' | 'onClose'
  > & {
    config: WidgetInternalConfig;
    type: WidgetType;
    isChatLoading: boolean;
    isOperatorChatting: boolean;
    error?: WidgetRequestError;
    onReset?(): void;
    onOpen(): void;
    onClose(a: { shouldDisplayMiniFloat: boolean }): void;
    shouldDisplayMiniFloat: boolean;
    isChatOpen: boolean;
  };

const WidgeContainerElement = styled.div<{
  isLeftBottom: boolean;
  padding?: number;
  distanceFromSideEdge: { x: number; y: number };
}>`
  height: ${p => (p.padding ? `calc(100% - ${p.padding * 2}px)` : '100%')};
  width: ${p => (p.padding ? `calc(100% - ${p.padding * 2}px)` : '100%')};
  position: absolute;
  bottom: ${p => p.distanceFromSideEdge.y}px;
  ${p =>
    p.isLeftBottom
      ? `left: ${p.distanceFromSideEdge.x}px`
      : `right: ${p.distanceFromSideEdge.x}px`};
`;

type EdgeDistance = {
  outerPosition: { x: number; y: number };
  innerPosition: { x: number; y: number };
};

const setCloseStyleToRootFrame = ({
  isBalloonVisible,
  frame,
  config,
  edgeDistance,
  environment,
  shouldDisplayMiniFloat,
  scaleCache,
}: {
  isBalloonVisible: boolean;
  frame: HTMLElement;
  config: WidgetInternalConfig;
  edgeDistance: EdgeDistance;
  environment: Environment;
  shouldDisplayMiniFloat: boolean;
  scaleCache: React.MutableRefObject<number>;
}) => {
  const { style } = frame;
  const isLeftBottom = config.iconPosition === 'left-bottom';
  style.setProperty('position', 'fixed', 'important');
  const bottomPosition = edgeDistance.outerPosition.y;
  const sidePosition = edgeDistance.outerPosition.x;

  style.setProperty('overflow', 'hidden', 'important');
  style.setProperty('bottom', `${bottomPosition}px`, 'important');
  if (isLeftBottom) {
    style.setProperty('left', `${sidePosition}px`, 'important');
  } else {
    style.setProperty('right', `${sidePosition}px`, 'important');
  }

  if (shouldDisplayMiniFloat) {
    style.setProperty('height', `24px`, 'important');
    style.setProperty('width', `60px`, 'important');
    frame.style.setProperty('bottom', '0px', 'important');
  } else if (isBalloonVisible) {
    style.setProperty(
      'height',
      `${
        (config.floatingIconSize.height > 90 ? config.floatingIconSize.height : 90) +
        edgeDistance.innerPosition.y +
        10
      }px`,
      'important'
    );
    style.setProperty(
      'width',
      `${
        config.floatingIconSize.width +
        BALLOON_WIDTH +
        BALLOON_MARGIN +
        edgeDistance.innerPosition.x +
        20
      }px`,
      'important'
    );
  } else {
    style.setProperty(
      'height',
      `${
        (config.floatingIconSize.height > 90 ? config.floatingIconSize.height : 90) +
        edgeDistance.innerPosition.y +
        10
      }px`,
      'important'
    );
    style.setProperty(
      'width',
      `${
        (config.floatingIconSize.width > 90 ? config.floatingIconSize.width : 90) +
        edgeDistance.innerPosition.x +
        10
      }px`,
      'important'
    );
  }
  style.setProperty('z-index', `2147483645`, 'important');
  if (!environment.isSupportedMobileBrowser && !shouldDisplayMiniFloat) {
    style.setProperty('padding', '30px', 'important');
  } else {
    style.setProperty('padding', '0px', 'important');
  }

  if (environment.isSupportedMobileBrowser && !shouldDisplayMiniFloat) {
    style.setProperty('transform', `none`, 'important');
    const rect = frame.getBoundingClientRect();
    let scale = 1;
    if (!scaleCache.current) {
      if (isBalloonVisible) {
        if (rect.width + sidePosition >= document.body.clientWidth - 20) {
          scale = (document.body.clientWidth - 20) / (rect.width + sidePosition);
        }
      } else {
        const expectSize = Math.round(window.innerHeight / 6);
        if (rect.height > expectSize) {
          scale = expectSize / rect.height;
        }
      }
    } else {
      scale = scaleCache.current;
    }

    scaleCache.current = scale;
    frame.querySelectorAll(`.${WIDGET_AUTO_SCALE_CLASS_NAME}`).forEach((el: unknown) => {
      const element = el as HTMLElement;
      element.style.setProperty('transform', `scale3d(${scale}, ${scale}, 1)`, 'important');
      if (isLeftBottom) {
        element.style.setProperty('transform-origin', `left bottom`, 'important');
      } else {
        element.style.setProperty('transform-origin', `right bottom`, 'important');
      }
    });
  }
};

const setOpenStyleToRootFrame = ({
  frame,
  config,
  positionFromSideEdge,
  positionFromBottomEdge,
  compositedPositionFromBottomEdge,
  environment,
}: {
  frame: HTMLElement;
  config: WidgetInternalConfig;
  positionFromSideEdge: number;
  positionFromBottomEdge: number;
  compositedPositionFromBottomEdge: number;
  environment: Environment;
}): (() => void) => {
  const { style } = frame;
  style.setProperty('position', 'fixed', 'important');
  if (!environment.isSupportedMobileBrowser) {
    style.setProperty('bottom', `${positionFromBottomEdge}px`, 'important');
  } else {
    style.setProperty('bottom', `env(safe-area-inset-bottom, 0px)`, 'important');
  }
  if (!environment.isSupportedMobileBrowser) {
    if (config.iconPosition === 'left-bottom') {
      style.setProperty('left', `${positionFromSideEdge}px`, 'important');
    } else {
      style.setProperty('right', `${positionFromSideEdge}px`, 'important');
    }
  } else {
    if (config.iconPosition === 'left-bottom') {
      style.setProperty('left', `0px`, 'important');
    } else {
      style.setProperty('right', `0px`, 'important');
    }
  }
  style.setProperty('z-index', `2147483645`, 'important');
  if (!environment.isSupportedMobileBrowser) {
    style.setProperty(
      'height',
      `${getWidgetHeight(compositedPositionFromBottomEdge, config.floatingChatWindowHeight)}px`,
      'important'
    );
    style.setProperty('width', `${WIDGET_WIDTH}px`, 'important');
    style.setProperty('padding', '70px', 'important');
    let animationFrameRequest = 0;
    const handler = () => {
      cancelAnimationFrame(animationFrameRequest);
      animationFrameRequest = requestAnimationFrame(() => {
        style.setProperty(
          'height',
          `${getWidgetHeight(compositedPositionFromBottomEdge, config.floatingChatWindowHeight)}px`,
          'important'
        );
      });
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  } else {
    style.setProperty(
      'height',
      `calc(100% - calc(env(safe-area-inset-bottom, 0px) + env(safe-area-inset-top, 0px)))`,
      'important'
    );
    style.setProperty('width', `100%`, 'important');
    style.setProperty('transform', `none`, 'important');
  }
  return () => {};
};

const calcEdgeDistance = ({
  isChatOpened,
  distanceFromScreenEdges,
  isLeftBottom,
}: {
  isChatOpened: boolean;
  distanceFromScreenEdges?: { x: number; y: number };
  isLeftBottom: boolean;
}): EdgeDistance => {
  const { safeAreaInsetBottom, safeAreaInsetLeft, safeAreaInsetRight } = getSafeAreaInset();
  const fromSideEdge =
    (distanceFromScreenEdges?.x ?? 0) + (isLeftBottom ? safeAreaInsetLeft : safeAreaInsetRight);
  const fromBottomEdge = (distanceFromScreenEdges?.y ?? 0) + safeAreaInsetBottom;
  const threshold = isChatOpened ? 70 : 30;
  return {
    innerPosition: {
      x: fromSideEdge > threshold ? threshold : fromSideEdge || 5,
      y: fromBottomEdge > threshold ? threshold : fromBottomEdge || 5,
    },
    outerPosition: {
      x: fromSideEdge > threshold ? fromSideEdge - threshold : 0,
      y: fromBottomEdge > threshold ? fromBottomEdge - threshold : 0,
    },
  };
};

export const WidgetRoot = ({
  config,
  isChattingWithOperator,
  isInputLoading,
  isDisplayTyping,
  shouldDisplayMiniFloat,
  error,
  enableRefreshButton,
  enableImageUpload,
  type,
  messages,
  mediaCache,
  isChatOpen,
  onSendMessage,
  onSendImage,
  onSendQuickRepliesPostPack,
  onDeleteMessage,
  onReset,
  onScroll,
  onOpen,
  onClose,
  onUserInput,
  onCacheMedia,
  isChatLoading,
  isOperatorChatting,
}: WidgetProps) => {
  const { frame, environment } = useContext(WidgetEnvContext);
  const [state, updateState] = useState({
    width: 0,
    height: 0,
  });
  const isOpenedOnce = useRef(false);

  useBeforeMount(() => {
    config.dispatch('immediate');
  });
  const handleMobilePageScrolling = useWidgetMobileAdaption({
    type,
    environment,
  });

  useEffect(() => {
    config.dispatch('mount');
    const handler = () => {
      applyOpenedStyle();
    };
    window.addEventListener('orientationchange', handler, false);
    return () => window.removeEventListener('orientationchange', handler);
  }, []);

  useEffect(() => {
    if (isChatOpen) {
      if (!isOpenedOnce.current) {
        config.dispatch('openOnce');
        isOpenedOnce.current = true;
      }
      config.dispatch('open');
      handleMobilePageScrolling(true);
    } else {
      if (!isOpenedOnce.current) {
        applyClosedStyle();
      } else {
        handleMobilePageScrolling(false);
      }
      config.dispatch('close');
    }
  }, [isChatOpen]);

  const scaleCacheRef = useRef(0);
  const edgeDistance = calcEdgeDistance({
    isChatOpened: isChatOpen,
    distanceFromScreenEdges: config.distanceFromScreenEdges,
    isLeftBottom: config.iconPosition === 'left-bottom',
  });
  const openStyleDisposerRef = useRef<() => void>();
  const isBalloonVisible = !!config.balloonText && !isOpenedOnce.current;
  const isLeftBottom = config.iconPosition === 'left-bottom';
  const applyClosedStyle = () => {
    if (!frame) {
      return;
    }
    if (!isChatOpen || shouldDisplayMiniFloat) {
      openStyleDisposerRef.current && openStyleDisposerRef.current();
      setCloseStyleToRootFrame({
        isBalloonVisible,
        frame,
        config,
        edgeDistance,
        environment,
        shouldDisplayMiniFloat,
        scaleCache: scaleCacheRef,
      });
    }
  };
  const applyOpenedStyle = () => {
    if (!frame) {
      return;
    }
    if (isChatOpen && type === WidgetType.FLOATING) {
      openStyleDisposerRef.current = setOpenStyleToRootFrame({
        frame,
        config,
        positionFromBottomEdge: edgeDistance.outerPosition.y,
        positionFromSideEdge: edgeDistance.outerPosition.x,
        compositedPositionFromBottomEdge:
          edgeDistance.outerPosition.y + edgeDistance.innerPosition.y,
        environment,
      });
    }
  };

  if (!frame) {
    return null;
  }

  let node: React.ReactNode;
  if (shouldDisplayMiniFloat && type !== WidgetType.EMBEDDED) {
    // MiniWindow
    node = (
      <WidgeContainerElement
        key="miniFloat"
        isLeftBottom={isLeftBottom}
        distanceFromSideEdge={{ x: 0, y: 0 }}
      >
        <Suspense fallback={<PreLoading scale={0.1} />}>
          <WidgetMiniFloat
            onClose={() => {
              onOpen();
            }}
          />
        </Suspense>
      </WidgeContainerElement>
    );
  } else if (!isChatOpen) {
    // Floating
    node = (
      <WidgeContainerElement
        key="chatFloat"
        isLeftBottom={isLeftBottom}
        distanceFromSideEdge={edgeDistance.innerPosition}
      >
        <WidgetFloat
          topMargin={
            edgeDistance.innerPosition.y + (!environment.isSupportedMobileBrowser ? 55 : 0)
          }
          isEnableBalloon={isBalloonVisible}
          text={config.balloonText}
          onMount={({ width, height }: any) => updateState({ ...state, width, height })}
          onClick={() => onOpen()}
          onClose={() => {
            updateState({
              ...state,
            });
            onClose({ shouldDisplayMiniFloat: true });
          }}
        />
      </WidgeContainerElement>
    );
  } else {
    node = (
      <WidgeContainerElement
        key="chatWindow"
        id={type === WidgetType.EMBEDDED ? AIM_WIDGET_ROOT_ID : ''}
        isLeftBottom={isLeftBottom}
        padding={environment.isSupportedMobileBrowser || type === WidgetType.EMBEDDED ? 0 : 70}
        distanceFromSideEdge={
          environment.isSupportedMobileBrowser || type === WidgetType.EMBEDDED
            ? { x: 0, y: 0 }
            : edgeDistance.innerPosition
        }
      >
        <Suspense fallback={<PreLoading />}>
          <WidgetChatWindow
            isChattingWithOperator={isChattingWithOperator}
            isDisplayTyping={isDisplayTyping}
            loading={isChatLoading}
            isInputLoading={isInputLoading}
            key="WidgetChatWindow"
            headerText={config.headerText}
            enableRefreshButton={enableRefreshButton}
            enableImageUpload={enableImageUpload}
            enableCloseButton={type !== WidgetType.EMBEDDED}
            onClose={() => onClose({ shouldDisplayMiniFloat: false })}
            onSendMessage={onSendMessage}
            onSendImage={onSendImage}
            onSendQuickRepliesPostPack={onSendQuickRepliesPostPack}
            onDeleteMessage={onDeleteMessage}
            onRefresh={onReset}
            onUserInput={onUserInput}
            onCacheMedia={onCacheMedia}
            type={type}
            messages={messages}
            mediaCache={mediaCache}
            onScroll={onScroll}
          />
        </Suspense>
      </WidgeContainerElement>
    );
  }

  return (
    <WidgetContext.Provider
      value={{ config, error, isMobile: environment.isSupportedMobileBrowser, isOperatorChatting }}
    >
      <AnimatePresence
        exitBeforeEnter={true}
        onExitComplete={() => {
          applyClosedStyle();
          applyOpenedStyle();
        }}
      >
        {node}
      </AnimatePresence>
    </WidgetContext.Provider>
  );
};

export const Widget = compareOnlyProperties((p: WidgetProps) => {
  return (
    <>
      <Global styles={imagePopupGlobalStyles} />
      <CacheProvider value={globalEmotionCache}>
        <Global styles={unsetStyles(`#${AIM_WIDGET_ROOT_ID}`)} />
        <Global styles={mdStyle(`#${AIM_WIDGET_ROOT_ID} .aim__widget-plain-text-message__md`)} />
      </CacheProvider>
      <CacheProvider value={emotionCache}>
        <WidgetRoot {...p} />
      </CacheProvider>
    </>
  );
}, 'Widget');
