/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { RefreshIcon } from './RefreshIcon';
import { WidgetContext } from './WidgetContext';

const { useState } = React;

const WidgetRefreshIconContainer = styled.button<{ designerMode: boolean }>`
  cursor: ${p => (p.designerMode ? 'default' : 'pointer')};
  background: transparent;
  border: 0;
  padding: 0;
`;

export interface WidgetRefreshIconProps {
  width: number;
  height: number;
  onRefresh?(): void;
  isDesignerMode?: boolean;
}

export const WidgetRefreshIcon = ({
  width,
  height,
  isDesignerMode = false,
  onRefresh = () => {},
}: WidgetRefreshIconProps) => {
  const { config } = useContext(WidgetContext);
  const [color, setColor] = useState<string>(config.chatColoring.common.color);

  useEffect(() => {
    setColor(config.chatColoring.common.color);
  }, [config]);

  return (
    <WidgetRefreshIconContainer
      type="button"
      css={{ width, height }}
      tabIndex={0}
      onMouseOver={() => setColor(config.chatColoring.common.colorHover)}
      onMouseOut={() => setColor(config.chatColoring.common.color)}
      onClick={() => onRefresh()}
      aria-label="最初からやり直す"
      designerMode={isDesignerMode}
    >
      <RefreshIcon color={color} />
    </WidgetRefreshIconContainer>
  );
};
