/**
 * @fileOverview
 * @name FirestoreDatabase.ts
 * @author Taketoshi Aono
 * @license
 */

import {
  CollectionReference,
  DocumentChange,
  DocumentData,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore';

export interface FirestoreDatabaseHolder {
  readonly collection: CollectionReference<DocumentData>;

  listen({
    startAt,
    orders,
    onChange,
  }: {
    startAt: number;
    orders: [string, 'desc' | 'asc'][];
    onChange(data: DocumentChange<DocumentData>): void;
  }): () => void;
}

export class FirestoreDatabase implements FirestoreDatabaseHolder {
  public constructor(public readonly collection: CollectionReference<DocumentData>) {}

  public listen({
    startAt,
    orders,
    onChange,
  }: {
    startAt: number;
    orders: [string, 'desc' | 'asc'][];
    onChange(data: DocumentChange<DocumentData>): void;
  }): () => void {
    const orderByConditions = orders.map(([prop, order]) => orderBy(prop, order));

    const q = query(this.collection, ...orderByConditions, startAfter(startAt));

    return onSnapshot(
      q,
      doc => {
        doc.docChanges().forEach(change => {
          onChange(change);
        });
      },
      e => console.error(e)
    );
  }
}
