/**
 * @fileOverview
 * @name usecase.ts<sendData>
 * @author Taketoshi Aono
 * @license
 */

import { ReduxState } from '@w/domain/entities/State';
import { ThunkDeps } from '@w/store/ThunkDeps';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

type Dispatch = ThunkDispatch<ReduxState, ThunkDeps, Action>;

export const sendData =
  (p: { [key: string]: string }) =>
  async (
    unusedDispatch: Dispatch,
    getState: () => ReduxState,
    { userDataRepository, reportCrashed }: ThunkDeps
  ) => {
    try {
      const state = getState();
      await userDataRepository.update({ userData: p, auth: state.auth.auth });
    } catch (error: any) {
      reportCrashed({ error, state: getState(), additionalData: p });
    }
  };
