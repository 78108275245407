/**
 * @fileoverview
 * @author
 */

import { AiMessenger } from '@w/AiMessenger';
import { WidgetType } from '@s/components/atom/WidgetType';
import {
  WidgetEventHandlers,
  WidgetConfigurationCustomize,
  WidgetConfiguration,
} from '@s/domain/values/WidgetConfiguration';
import { WelcomeMessageFormat } from '@w/application/WelcomeMessage';
import { FirebaseConfig } from '@s/application/entity/FirebaseConfig';

export type AimWidgetInitialConfig = {
  customization?: WidgetConfigurationCustomize;
  devices: ('PC' | 'iOS' | 'Android')[];
  forceSupportAs?: 'iOS' | 'Android' | 'PC';
  events?: WidgetEventHandlers;
  persistency?: 'local' | 'session';
  mode?: 'normal' | 'llm';
  design?: 'classic' | 'search';
  projectId: string;
  tenantId: string;
  tenantName: string;
  type: WidgetType;
  dedicatedEnvConfig?: string;
  dedicatedEnvConfigJson?: { firebaseConfig: FirebaseConfig; serverName: string };
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  __preview?: {
    configuration: WidgetConfiguration;
    welcomeMessage: WelcomeMessageFormat;
    token: string;
  };
  userData: { [key: string]: string };
};

interface AimWindow extends Window {
  aimWidgetInitialConfigs?: AimWidgetInitialConfig;
  aiMessenger?: AiMessenger;
  parent: AimWindow;
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  __AIM_DUPLICATION_WARNING__?: boolean;
  [key: string]: any;
}

declare let window: AimWindow;
export default window;
