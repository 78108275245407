/**
 * @fileOverview
 * @name isDebuggerEnabled.ts
 * @author Taketoshi Aono
 * @license
 */

import { QUERY_PARAMETER_KEY } from './queryparameter';

export const isDebuggerEnabled = (hasOpener: { opener?: any }, qs: string, referrer: string) => {
  const rf = referrer ? new URL(referrer) : null;
  return (
    !!hasOpener.opener &&
    (qs
      .slice(1, qs.length)
      .split('&')
      .some(v => v.split('=')[0] === QUERY_PARAMETER_KEY) ||
      (rf &&
        rf.search
          .slice(1, rf.search.length)
          .split('&')
          .some(v => v.split('=')[0] === QUERY_PARAMETER_KEY)))
  );
};
