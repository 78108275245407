/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useEffect, useContext } from 'react';
import { WidgetIcon, WidgetIconProps } from '../molecule/WidgetIcon';
import { WidgetIconBalloon, WidgetIconBalloonProps } from '../molecule/WidgetIconBalloon';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import { BALLOON_MARGIN, BALLOON_WIDTH } from '../atom/WidgetConstant';
import { WidgetContext } from '../atom/WidgetContext';
import { CloseButton } from '@s/components/atom/CloseButton';
import { WidgetEnvContext } from '../atom/WidgetEnvContext';
import { WIDGET_AUTO_SCALE_CLASS_NAME } from '@s/dom/widgetAutoScaleClassName';

export type WidgetFloatProps = Omit<WidgetIconProps, 'iconWidth' | 'iconHeight'> &
  WidgetIconBalloonProps & {
    isDesignerMode?: boolean;
    onMount?(a: { width: number; height: number }): void;
    onClose?(): void;
  };

const WidgetFloatIconContainerElement = styled(motion.div)`
  position: absolute;
  ${p => (p.theme.iconPosition === 'left-bottom' ? 'left' : 'right')}: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${p => (p.theme.iconPosition === 'left-bottom' ? 'row-reverse' : 'row')};
`;

const WidgetFloatContainerElement = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 100%;
  opacity: 0;
`;
const WidgetFloatInnerContainerElement = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;

const WidgetCloseContainerElement = styled.div<{ isLeftBottom: boolean; top: number }>`
  z-index: 1;
  position: absolute !important;
  ${p => (p.isLeftBottom ? 'left' : 'right')}: 0 !important;
  top: ${p => p.top}px !important;
`;

const WidgetBalloonContainerElement = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  ${p =>
    p.theme.iconPosition === 'left-bottom'
      ? `margin-left: ${BALLOON_MARGIN}px`
      : `margin-right: ${BALLOON_MARGIN}px`};
`;

export const WidgetFloat = ({
  borderRadius = 80,
  text = '',
  topMargin = 0,
  isDesignerMode = false,
  isEnableBalloon,
  onMount = () => {},
  onClick = () => {},
  onClose = () => {},
}: Partial<WidgetFloatProps> & { isEnableBalloon?: boolean; topMargin?: number } = {}) => {
  const { environment } = useContext(WidgetEnvContext);
  const { config } = useContext(WidgetContext);
  const width = config.floatingIconSize.width + BALLOON_WIDTH + BALLOON_MARGIN;
  const height = config.floatingIconSize.height > 70 ? config.floatingIconSize.height : 70;
  const isLeftBottom = config.iconPosition === 'left-bottom';

  useEffect(() => {
    onMount({ width, height });
  }, [width, height]);

  const animationCoefficient = config.iconPosition === 'left-bottom' ? -1 : 1;

  return (
    <WidgetFloatContainerElement
      initial={{ translateX: isLeftBottom ? -20 : 20, opacity: 0 }}
      animate={{ translateX: 0, opacity: 1 }}
      transition={
        isDesignerMode ? { type: 'spring', mass: 0.4, damping: 13, delay: 0.2 } : { type: 'spring' }
      }
      exit={{
        translateX: isLeftBottom ? -20 : 20,
        opacity: 0,
        transition: { type: 'tween', duration: 0.2, ease: 'anticipate' },
      }}
    >
      <WidgetFloatInnerContainerElement className={WIDGET_AUTO_SCALE_CLASS_NAME}>
        <WidgetFloatIconContainerElement
          transition={isDesignerMode ? { type: 'spring', mass: 0.4 } : { type: 'spring' }}
          whileHover={
            isDesignerMode || environment.isSupportedMobileBrowser
              ? {}
              : {
                  scale: 1.05,
                }
          }
        >
          {isDesignerMode || isEnableBalloon ? (
            <WidgetBalloonContainerElement
              initial={{ translateX: animationCoefficient * 40, opacity: 0 }}
              animate={{ translateX: 0, opacity: 1 }}
              transition={
                isDesignerMode ? { type: 'spring', mass: 0.4 } : { type: 'spring', delay: 1.5 }
              }
            >
              <WidgetIconBalloon text={text} />
            </WidgetBalloonContainerElement>
          ) : null}
          <div
            css={{
              background: 'transparent',
              position: 'relative',
              flex: `0 0 ${config.floatingIconSize.width}px`,
              width: config.floatingIconSize.width,
              height: config.floatingIconSize.height,
            }}
          >
            <WidgetIcon
              width={config.floatingIconSize.width}
              height={config.floatingIconSize.height}
              src={config.floatingIconSrc}
              backgroundColor={config.floatingIconColor}
              borderRadius={borderRadius}
              onClick={onClick}
            />
          </div>
        </WidgetFloatIconContainerElement>
      </WidgetFloatInnerContainerElement>
      <WidgetCloseContainerElement
        isLeftBottom={isLeftBottom}
        top={isDesignerMode ? 0 : topMargin + 10}
      >
        <CloseButton onClick={onClose} />
      </WidgetCloseContainerElement>
    </WidgetFloatContainerElement>
  );
};
