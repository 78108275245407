import React, { useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { regularTextStyle } from '@s/components/atom/Text';
import { WidgetContext } from '@s/components/atom/WidgetContext';
import marked from 'marked';
import { required } from '@s/assertions';
import { MessageSender } from '@s/domain/entity/MessageFormat';
import { useRefState } from '@s/reactHooks';

marked.setOptions({
  gfm: true,
  breaks: true,
  smartLists: true,
  smartypants: false,
  xhtml: true,
});

marked.use({
  renderer: {
    paragraph(text: string) {
      return `<span>${text}</span>`;
    },
  } as any,
  tokenizer: {
    space(text: string) {
      const breaks = /^ *\n/.exec(text);
      if (breaks) {
        return {
          type: 'html',
          raw: breaks[0],
          text: '<br/>',
        } as any;
      }
    },
  } as any,
});

const MessageContainer = styled.div`
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  overflow-wrap: break-word;
  word-break: break-word;
`;

const TextContainerElement = styled.div<{ linkColor: string }>`
  ${regularTextStyle};
  a {
    color: ${p => p.linkColor} !important;
  }
`;
const CustomerTextContainerElement = styled.div`
  ${regularTextStyle};
  white-space: pre-wrap;
`;

export interface WidgetPlainTextMessageProps {
  sender: MessageSender;
  text: string;
  theme?: 'instagram' | 'web';
}

const TARGET_BLANK_LINK = `<a target="_blank" rel="noreferrer noopener nofollow" `;
export const WidgetPlainTextMessage = ({ sender, text }: WidgetPlainTextMessageProps) => {
  const { config } = useContext(WidgetContext);
  const ref = useRef<HTMLElement>();
  const [markdownHtml, setMarkdownHtml] = useRefState(marked(text));

  // メッセージ内のリンクがクリックされたときのイベントハンドラーを設定
  useEffect(() => {
    if (ref.current) {
      const handler = (e: MouseEvent) => {
        const el = e.target as HTMLLinkElement;
        if (el.nodeName === 'A') {
          e.preventDefault();
          config.dispatch('linkClick', {
            text: el.textContent,
            href: el.href,
          });
          try {
            required(window.open(el.href)).location.href = el.href;
          } catch (e) {
            location.href = el.href;
          }
        }
      };
      ref.current.addEventListener('click', handler);
      return () => {
        ref.current && ref.current.removeEventListener('click', handler);
      };
    }
  }, [ref.current]);

  // markdownのスタイル適用
  useEffect(() => {
    if (sender === 'Bot') {
      const fragment = document.createDocumentFragment();
      const outer = document.createElement('div');
      fragment.appendChild(outer);
      outer.innerHTML = marked(text).replace(/<a /, TARGET_BLANK_LINK);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
      outer.querySelectorAll<HTMLElement>('*').forEach(el => {
        el.style.cssText.split(';').forEach(s => {
          if (s) {
            const splitted = s.split(':');
            const propertyName = splitted[0].trim();
            const value = splitted[1].trim();
            el.style.setProperty(propertyName, value, 'important');
            if (propertyName === 'color') {
              el.style.setProperty('-webkit-text-fill-color', value, 'important');
            }
          }
        });
      });
      setMarkdownHtml(outer.innerHTML);
    }
  }, [text]);

  return (
    <MessageContainer
      css={{
        color: '#222222',
        // For safari
        WebkitTextFillColor: '#222222',
      }}
    >
      {sender === 'Bot' || sender === 'Operator' ? (
        <TextContainerElement
          ref={ref as any}
          className="aim__widget-plain-text-message__md"
          dangerouslySetInnerHTML={{ __html: markdownHtml.current }}
          linkColor={config.chatColoring.common.color}
        />
      ) : (
        <CustomerTextContainerElement>{text}</CustomerTextContainerElement>
      )}
    </MessageContainer>
  );
};
