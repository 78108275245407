/**
 * @fileOverview
 * @name useAnimationFrameFixer.ts
 * @author Taketoshi Aono
 * @license
 */

import { useRef } from 'react';

export const useAnimationFrameFixer = (callbackRequest: (...args: any[]) => void) => {
  const animationFrameRequestId = useRef(0);

  return (...args: any[]) => {
    cancelAnimationFrame(animationFrameRequestId.current);
    animationFrameRequestId.current = requestAnimationFrame(() => callbackRequest(...args));
  };
};
