/**
 * @fileOverview
 * @name reportCrash.ts
 * @author Taketoshi Aono
 * @license
 */

import { CrashReportable } from '@aim/shared/src/crashreporting/CrashReporterForWidget';
import { FirestoreService } from './domain/service/firestore/FirestoreService';
import { ReduxState } from './domain/entities/State';
import { ActionLogQueueable } from '@aim/shared/src/application/service/ActionLogQueuingService';
import { LLMMessageFormat, MessageFormat } from '@s/domain/entity/MessageFormat';

export type ReportCrashed = (a: { error: Error; state: ReduxState; additionalData?: any }) => void;

// Create reportCrashed function which send state and action logs to firestore and send an error report to Sentry if logged in.
// If an user is not logged in, we will send Sentry with state and action logs.
// If network state is offline, this two requests will retry until network become online.
export const crashReporterFactory = ({
  crashReporter,
  firestoreService,
  actionLogQueuingService,
}: {
  crashReporter(): Promise<CrashReportable>;
  firestoreService: FirestoreService<LLMMessageFormat> | FirestoreService<MessageFormat>;
  actionLogQueuingService: ActionLogQueueable;
}) => {
  return async ({ error, state, additionalData }: Parameters<ReportCrashed>[0]) => {
    const reporter = await crashReporter();
    // Only store to firestore if an user logged in.
    if (state.auth.auth.claims.aim_user_id) {
      // A firestore url which includes all state and action logs.
      const firestoreUrl = firestoreService.storeIncidentState({
        state,
        log: actionLogQueuingService.getList(),
        customerId: state.auth.auth.claims.aim_user_id,
        tenantId: state.env.tenantId,
      });
      reporter.send(error, firestoreUrl, additionalData);
    } else {
      reporter.sendWithData(error, {
        state,
        actionLog: actionLogQueuingService.getList(),
        additionalData,
      });
    }
  };
};
