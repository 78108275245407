/**
 * @fileoverview
 * @author
 */

/**
 * WebStorage
 */
export class WebStorage {
  /**
   *
   * @param {*} key
   * @return {*}
   */
  public static getItem(key: string): any {
    try {
      return this.hasItem(key) ? JSON.parse(window.localStorage.getItem(key)!) : null;
    } catch (e) {}
  }

  /**
   *
   * @param {*} key
   * @return {*}
   */
  public static hasItem(key: string): boolean {
    try {
      return key in window.localStorage;
    } catch (e) {
      return false;
    }
  }

  /**
   * @return {*}
   */
  public static keys(): string[] {
    const ret = [];
    try {
      for (const key in window.localStorage) {
        if ({}.hasOwnProperty.call(window.localStorage, key)) {
          ret.push(key);
        }
      }
    } catch (e) {}
    return ret;
  }

  /**
   *
   * @param  {*} key
   * @return {*}
   */
  public static removeItem(key: string): void {
    try {
      return window.localStorage.removeItem(key);
    } catch (e) {}
  }

  /**
   *
   * @param {*} key
   * @param {*}  value
   * @return {*}
   */
  public static setItem(key: string, value: any): void {
    try {
      if (typeof value !== 'string') {
        value = JSON.stringify(value);
      }
      return window.localStorage.setItem(key, value);
    } catch (e) {}
  }
}
