import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { WIDGET_WIDTH } from '@s/components/atom/WidgetConstant';
import { motion } from 'framer-motion';
import { WidgetEnvContext } from '@s/components/atom/WidgetEnvContext';
import { MessageSender } from '@s/domain/entity/MessageFormat';

const widgetTalkAreaFocusStyle = css`
  &:focus {
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 255, 0.6);
    .aim__talkarea__widget-message {
      box-shadow: 0px 0px 4px rgba(0, 0, 255, 0.6) !important;
    }
  }
`;

const WidgetTalkAreaMessageContainerElement = styled(motion.li)<{
  isSupportedMobileBrowser: boolean;
}>`
  outline: none;
  ${p => (!p.isSupportedMobileBrowser ? widgetTalkAreaFocusStyle : '')};
  display: flex;
  justify-content: center;
`;

export const WidgetTalkAreaMessageElement = ({
  children,
  sender,
  label,
  isSendFailed,
}: {
  children: React.ReactNode;
  sender: MessageSender;
  label: string;
  isSendFailed?: boolean;
}) => {
  const { environment } = useContext(WidgetEnvContext);
  let backgroundColor = '#fff';

  if (sender === 'Customer') {
    backgroundColor = '#F5F7FC';
    if (isSendFailed) {
      backgroundColor = '#FFE8F6';
    }
  }

  return (
    <WidgetTalkAreaMessageContainerElement
      css={{ background: backgroundColor }}
      isSupportedMobileBrowser={environment.isSupportedMobileBrowser}
      initial={{
        translateX: sender === 'Customer' ? WIDGET_WIDTH : -WIDGET_WIDTH,
        opacity: 0,
      }}
      animate={{ translateX: 0, opacity: 1 }}
      exit={{
        translateX: sender === 'Customer' ? WIDGET_WIDTH : -WIDGET_WIDTH,
        opacity: 0,
      }}
      transition={{ type: 'tween', duration: 0.2, ease: 'anticipate' }}
      tabIndex={0}
      aria-label={label}
    >
      {children}
    </WidgetTalkAreaMessageContainerElement>
  );
};
