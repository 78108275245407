/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import { css } from '@emotion/react';

export const scrollbarStyle = css`
  &::-webkit-scrollbar {
    width: 10px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #ddd !important;
    border-radius: 20px !important;
  }

  &::-webkit-scrollbar-track {
    background: #fff !important;
    border-radius: 20px !important;
  }
`;

export const transparentScrollbarStyle = css`
  &::-webkit-scrollbar {
    width: 10px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #ddd !important;
    border-radius: 20px !important;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px !important;
  }
`;
