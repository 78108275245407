/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { WidgetDefaultFloatingIcon } from '../atom/WidgetDefaultFloatingIcon';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import objectFitImages from 'object-fit-images';

export interface WidgetIconProps {
  width?: number;
  height?: number;
  src?: string;
  backgroundColor?: string;
  borderRadius?: number;
  tabIndex?: number;
  onClick?(): void;
}

const IconVisual = styled.button`
  border: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  cursor: pointer;
`;
const SelectedImg = styled.img`
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  filter: drop-shadow(-2px 2px 20px rgba(0, 0, 0, 0.2));
`;

export const WidgetIcon = compareOnlyProperties(
  ({
    src,
    width,
    height,
    backgroundColor = '#0066FF',
    borderRadius = 80,
    tabIndex = 0,
    onClick,
  }: WidgetIconProps) => {
    const imageRef = useRef<HTMLImageElement>(null);
    useEffect(() => {
      imageRef.current && objectFitImages(imageRef.current);
    }, [imageRef.current]);

    return (
      <IconVisual
        css={
          !src
            ? {
                backgroundColor,
                borderRadius,
                boxShadow: '-2px 2px 20px rgba(0, 0, 0, 0.2)',
              }
            : {}
        }
        onClick={() => onClick && onClick()}
        tabIndex={tabIndex}
      >
        {!src ? (
          <WidgetDefaultFloatingIcon width="50%" height="50%" />
        ) : (
          <SelectedImg
            ref={imageRef}
            role="img"
            style={{ objectFit: 'contain', fontFamily: 'object-fit: contain' }}
            css={{ width, height }}
            src={src}
          />
        )}
      </IconVisual>
    );
  }
);
