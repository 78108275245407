/**
 * @fileoverview
 * @author
 */

const REGEX_SPECIAL_ESCAPE = /[-[\]{}()+?.,\\^$|#]/g;
const WILDCARD_REGEX = /\*/g;
/**
 *
 * @param {*} whitelist
 * @param {*} current
 * @return {*}
 */
export const isUrlOrDomainContainedInWhitelist = (
  whitelist: string[],
  current: string
): boolean => {
  return whitelist.some(v => {
    const text = v.replace(REGEX_SPECIAL_ESCAPE, '\\$&').replace(WILDCARD_REGEX, '.*');
    const reg = new RegExp(text);
    if (reg.test(current)) {
      return true;
    }
  });
};
