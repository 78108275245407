/**
 * @fileOverview
 * @name Checker.ts
 * @author Taketoshi Aono
 * @license
 */

import { InspectionItems } from '@s/debugger/InspectionItems';
import { isUrlOrDomainContainedInWhitelist } from '@w/util/isUrlOrDomainContainedInWhitelist';
import { Environment } from '@s/platform/Environment';
import window from '@w/window';
import { WidgetConfiguration } from '@aim/shared/src/domain/values';

export interface WidgetInspectable {
  inspect(): InspectionItems;
}

export class WidgetInspector implements WidgetInspectable {
  public constructor(
    private readonly url: string,
    private readonly environment: Environment,
    private readonly configuration: WidgetConfiguration,
    private readonly type: InspectionItems['type']
  ) {}

  public inspect(): ReturnType<WidgetInspectable['inspect']> {
    const inspectionItems: InspectionItems = {
      type: this.type,
      isMoreThanOneSnippetsIncluded: !!window.__AIM_DUPLICATION_WARNING__,
      whitelist: { ok: true, value: [] },
      userAgent: {
        ok: this.environment.isSupportedEnvironment,
        value: this.environment.platform.ua,
      },
      viewport: this.isProperViewportDefined(),
      isEmbeddedTargetExists:
        this.type !== 'embedded' || !!document.querySelector('#aim-widget-frame-embed'),
    };

    if (this.configuration.whitelist.acceptUrl) {
      inspectionItems.whitelist.ok = isUrlOrDomainContainedInWhitelist(
        this.configuration.whitelist.acceptUrl,
        this.url
      );
      inspectionItems.whitelist.value = this.configuration.whitelist.acceptUrl;
    }

    return inspectionItems;
  }

  private isProperViewportDefined(): { ok: boolean; value: string } {
    const viewport = document.querySelectorAll('meta[name=viewport]');
    if (!viewport.length) {
      return { ok: false, value: '' };
    }
    const contents = (Array.from(viewport) as HTMLMetaElement[]).map(v => v.content);
    return {
      ok: /width=device-width|initial-scale=1/.test(contents.join()),
      value: contents.join(','),
    };
  }
}
