/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { WIDGET_RADIUS } from '../atom/WidgetConstant';
import { WidgetType } from '../atom/WidgetType';
import { WidgetEnvContext } from '../atom/WidgetEnvContext';

const RootContainerElement = styled.div`
  position: relative;
  height: 100%;
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: ${WIDGET_RADIUS}px;
    height: 100%;
    width: 100%;
    top: 0px;
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.35);
  }
`;

type ContentElementProps = {
  isFloatingMode?: boolean;
  isMobile: boolean;
};

const ContentElement = styled('div')<ContentElementProps>`
  height: 100%;
  overflow: hidden;
  background: #fff;
  position: relative;
  z-index: 1;
  ${(props: ContentElementProps) =>
    props.isFloatingMode && !props.isMobile ? 'border-radius: 14px' : null};
`;

export const WidgetChatWindowBackground = ({ type, children }: any) => {
  const { environment } = useContext(WidgetEnvContext);
  return type === WidgetType.FLOATING ? (
    <RootContainerElement>
      <ContentElement isFloatingMode isMobile={environment.isSupportedMobileBrowser}>
        {children}
      </ContentElement>
    </RootContainerElement>
  ) : (
    <ContentElement isMobile={environment.isSupportedMobileBrowser}>{children}</ContentElement>
  );
};
