/**
 * @fileOverview
 * @name ChatStateRepository.ts
 * @author Taketoshi Aono
 * @license
 */

import { AppendonlyRepository } from '@s/Repository';
import { ChatStateValue, getDefaultChatStateValue } from '@w/application/value/ChatStateValue';
import { storage } from '@s/io/storage';

export type ChatStateAppendOnlyRepository = AppendonlyRepository<
  undefined,
  ChatStateValue,
  ChatStateValue,
  void
>;

export const STATE_KEY = '__aimwidget_state__';

export class ChatStateRepository implements ChatStateAppendOnlyRepository {
  public async create(): Promise<ChatStateValue> {
    const local = storage('local');
    const state = await local.get(STATE_KEY, getDefaultChatStateValue());
    local.set(STATE_KEY, state);
    return state;
  }

  public async update({
    isMinimized,
  }: Parameters<ChatStateAppendOnlyRepository['update']>[0]): Promise<void> {
    const local = storage('local');
    const state = local.get(STATE_KEY, getDefaultChatStateValue());
    local.set(STATE_KEY, { ...state, isMinimized });
  }
}
