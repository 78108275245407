/**
 * @fileOverview
 * @name EmptyUserIcon.tsx
 * @author Taketoshi Aono
 * @license
 */
import React from 'react';

export const EmptyUserIcon = ({
  size,
  shouldReverseColor = false,
}: {
  size: number;
  shouldReverseColor?: boolean;
}) => {
  return (
    <div className="empty-user-icon">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 600 600.1"
        width={`${size}px`}
        height={`${size}px`}
      >
        <style type="text/css">
          {`.empty-user-icon .st0{fill:none;stroke:#FFFFFF;stroke-width:0;stroke-linecap:round;stroke-linejoin:round;}
	.empty-user-icon .st1{fill:${shouldReverseColor ? '#CCCCCC' : '#FFFFFF'};}
	.empty-user-icon .st2{fill:${shouldReverseColor ? '#FFFFFF' : '#CCCCCC'};}`}
        </style>
        <defs></defs>
        <g>
          <line className="st0" x1="159" y1="564.8" x2="156.5" y2="563.5" />
          <line className="st0" x1="139.1" y1="553.2" x2="136.5" y2="551.5" />
          <line className="st0" x1="127.7" y1="545.6" x2="125.1" y2="543.8" />
          <line className="st0" x1="144.8" y1="556.8" x2="142.5" y2="555.3" />
          <path
            className="st1"
            d="M152.3,561.2c-0.6-0.4-1.3-0.7-1.9-1.1C151,560.5,151.7,560.8,152.3,561.2z"
          />
          <line className="st0" x1="133.4" y1="549.5" x2="130.7" y2="547.7" />
          <path
            className="st1"
            d="M600,300C600,134.3,465.7,0,300,0S0,134.3,0,300c-0.1,89.5,39.9,174.4,109,231.3V417c0.1-33.1,26.9-59.9,60-60
		h262c33.1,0.1,59.9,26.9,60,60v114.3C560.1,474.4,600.1,389.5,600,300z M300,333c-72.9,0-132-59.1-132-132S227.1,69,300,69
		s132,59.1,132,132S372.9,333,300,333z"
          />
          <line className="st0" x1="177.7" y1="574" x2="174.9" y2="572.7" />
          <line className="st0" x1="165.2" y1="568.1" x2="162.5" y2="566.7" />
          <line className="st0" x1="171.5" y1="571.1" x2="168.7" y2="569.8" />
          <line className="st0" x1="184" y1="576.8" x2="181.3" y2="575.6" />
          <line className="st0" x1="443.5" y1="563.5" x2="441" y2="564.8" />
          <path
            className="st1"
            d="M449.6,560.1c-0.6,0.4-1.3,0.7-1.9,1.1C448.3,560.8,449,560.5,449.6,560.1z"
          />
          <line className="st0" x1="474.9" y1="543.8" x2="472.3" y2="545.6" />
          <line className="st0" x1="437.5" y1="566.7" x2="434.8" y2="568.1" />
          <line className="st0" x1="469.3" y1="547.7" x2="466.7" y2="549.5" />
          <line className="st0" x1="457.5" y1="555.3" x2="455.2" y2="556.8" />
          <line className="st0" x1="463.5" y1="551.5" x2="461" y2="553.2" />
          <line className="st0" x1="431.3" y1="569.8" x2="428.6" y2="571.1" />
          <line className="st0" x1="422.3" y1="574" x2="425.1" y2="572.8" />
          <path
            className="st2"
            d="M431,357H169c-33.1,0.1-59.9,26.9-60,60v114.3l1.9,1.5c1.1,0.9,2.3,1.8,3.4,2.7l2.4,1.8c1,0.8,2,1.5,3,2.3
		l2.5,1.9c1,0.7,2,1.5,3,2.2l2.6,1.8l3,2.1l2.6,1.8l3.1,2.1l2.6,1.7c1.1,0.7,2.3,1.4,3.4,2.1l2.3,1.5c1.9,1.1,3.7,2.2,5.6,3.3
		c0.6,0.4,1.3,0.7,1.9,1.1c1.4,0.8,2.8,1.6,4.2,2.3l2.5,1.3l3.5,1.9l2.7,1.4c1.1,0.6,2.3,1.2,3.4,1.7l2.8,1.3
		c1.2,0.5,2.3,1.1,3.5,1.6l2.7,1.2l3.6,1.6l2.7,1.2c1.3,0.6,2.7,1.1,4,1.6l2.4,1c2.1,0.8,4.2,1.6,6.4,2.4l1.3,0.5
		c1.7,0.6,3.5,1.2,5.2,1.8l2.5,0.8c1.4,0.5,2.8,0.9,4.1,1.3c0.9,0.3,1.8,0.6,2.7,0.8c1.3,0.4,2.6,0.8,4,1.2l2.9,0.8
		c1.3,0.4,2.6,0.7,4,1.1l2.8,0.7c1.4,0.3,2.7,0.7,4.1,1l2.7,0.7c1.5,0.3,3,0.7,4.5,1c0.8,0.2,1.6,0.3,2.3,0.5
		c2.3,0.5,4.6,0.9,6.9,1.3l0.6,0.1c2.1,0.4,4.3,0.7,6.4,1.1l2.4,0.3l4.7,0.7l2.8,0.3c1.5,0.2,2.9,0.3,4.4,0.5l2.9,0.3l4.3,0.4
		l2.9,0.2l4.5,0.3c0.9,0,1.9,0.1,2.8,0.2c1.6,0.1,3.2,0.1,4.9,0.2l2.4,0.1c2.4,0.1,4.9,0.1,7.3,0.1s4.9,0,7.3-0.1l2.4-0.1
		c1.6,0,3.3-0.1,4.9-0.2c0.9,0,1.9-0.1,2.8-0.2l4.5-0.3l2.9-0.2l4.3-0.4l2.9-0.3c1.5-0.2,2.9-0.3,4.3-0.5l2.8-0.3l4.7-0.7l2.4-0.3
		c2.1-0.3,4.3-0.7,6.4-1.1l0.6-0.1c2.3-0.4,4.6-0.9,6.9-1.3c0.8-0.2,1.5-0.3,2.3-0.5c1.5-0.3,3-0.7,4.5-1l2.7-0.7
		c1.4-0.3,2.8-0.7,4.1-1l2.8-0.7c1.3-0.3,2.7-0.7,4-1.1l2.9-0.8c1.3-0.4,2.6-0.8,4-1.2c0.9-0.3,1.8-0.5,2.7-0.8
		c1.4-0.4,2.8-0.9,4.1-1.3l2.5-0.8c1.8-0.6,3.5-1.2,5.2-1.8l1.3-0.5c2.1-0.8,4.2-1.6,6.3-2.4l2.4-1c1.3-0.5,2.7-1.1,4-1.6l2.7-1.2
		l3.5-1.6l2.8-1.3c1.2-0.5,2.3-1.1,3.5-1.6l2.8-1.3c1.1-0.6,2.3-1.1,3.4-1.7l2.7-1.4l3.5-1.9l2.5-1.3c1.4-0.8,2.8-1.5,4.2-2.3
		c0.6-0.4,1.3-0.7,1.9-1.1c1.9-1.1,3.7-2.2,5.6-3.3l2.3-1.5c1.1-0.7,2.3-1.4,3.4-2.1l2.6-1.7l3.1-2.1l2.6-1.8l3-2.1l2.6-1.8
		c1-0.7,2-1.4,3-2.2l2.5-1.9c1-0.8,2-1.5,3-2.3l2.4-1.8c1.1-0.9,2.3-1.8,3.4-2.7l1.9-1.5v0V417C490.9,383.9,464.1,357.1,431,357z"
          />
          <circle className="st2" cx="300" cy="201" r="132" />
        </g>
      </svg>
    </div>
  );
};
