/**
 * @fileOverview
 * @name debugger.ts
 * @author Taketoshi Aono
 * @license
 */

import { DebuggerConnection } from '@s/debugger/DebuggerConnector';
import { isDebuggerEnabled } from '@aim/shared/src/debugger/isDebuggerEnabled';
import {
  ConsoleSendableEventType,
  WidgetSendableEventType,
} from '@aim/shared/src/debugger/EventType';
import { WidgetInspector } from './Inspector';
import { Environment } from '@aim/shared/src/platform/Environment';
import { WidgetConfiguration } from '@aim/shared/src/domain/values';
import { AimWidgetInitialConfig } from '@w/window';
import { InspectionItems } from '@aim/shared/src/debugger/InspectionItems';
import { QUERY_PARAMETER_KEY } from '@aim/shared/src/debugger/queryparameter';

export const widgetDebugger = async ({
  origin,
  environment,
  configuration,
  type,
  onConnect = () => {},
  onDisconnect = () => {},
}: {
  origin: string;
  environment: Environment;
  configuration: WidgetConfiguration;
  type: InspectionItems['type'];
  onConnect?(): void;
  onDisconnect?(): void;
}): Promise<AimWidgetInitialConfig['__preview'] | void> => {
  if (!isDebuggerEnabled(window, location.search, document.referrer)) {
    return;
  }
  const nu = new URL(location.href);
  if (!nu.searchParams.has(QUERY_PARAMETER_KEY)) {
    nu.searchParams.append(QUERY_PARAMETER_KEY, '1');
    location.replace(nu.href);
  }

  const connection = new DebuggerConnection({
    isOpener: false,
    self: window,
    subject: window.opener,
    origin,
  });
  connection.onConnect = onConnect;
  connection.onDisconnect = onDisconnect;
  const eventBus = await connection.connect<ConsoleSendableEventType, WidgetSendableEventType>();
  return new Promise(resolve => {
    let preview: AimWidgetInitialConfig['__preview'] | undefined;
    eventBus.on('check', () => {
      const inspector = new WidgetInspector(
        location.href,
        environment,
        preview?.configuration ?? configuration,
        type
      );
      eventBus.send('checked', inspector.inspect());
    });
    eventBus.on('option', e => {
      preview = e.data?.payload;
      resolve(e.data?.payload);
    });
    eventBus.send('option', { url: location.href });
  });
};
