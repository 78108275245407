import { forceLayout } from '@s/components/atom/domUtils';

export type SafeAreaInsets = {
  safeAreaInsetTop: number;
  safeAreaInsetRight: number;
  safeAreaInsetLeft: number;
  safeAreaInsetBottom: number;
};

let cscache: CSSStyleDeclaration | undefined;
export const getSafeAreaInset = (): SafeAreaInsets => {
  if (!cscache) {
    const style = document.createElement('style');
    document.head.appendChild(style);
    const styleSheet = style.sheet;
    if (styleSheet) {
      styleSheet.insertRule(
        `:root {
  --ais__sat: env(safe-area-inset-top);
  --ais__sar: env(safe-area-inset-right);
  --ais__sab: env(safe-area-inset-bottom);
  --ais__sal: env(safe-area-inset-left);
  }`,
        0
      );
    }
    forceLayout(style);
  }
  cscache = getComputedStyle(document.documentElement);
  const ret = {
    safeAreaInsetTop: parseInt(cscache.getPropertyValue('--ais__sat').trim(), 10),
    safeAreaInsetRight: parseInt(cscache.getPropertyValue('--ais__sar').trim(), 10),
    safeAreaInsetLeft: parseInt(cscache.getPropertyValue('--ais__sal').trim(), 10),
    safeAreaInsetBottom: parseInt(cscache.getPropertyValue('--ais__sab').trim(), 10),
  };
  return ret;
};
