/**
 * @fileOverview
 * @name FirebaseHandler.ts
 * @author Taketoshi Aono
 * @license
 */

import firebase from 'firebase/app';
import { staticConfig } from '@w/config';
import { FirebaseConfig } from '@s/application/entity/FirebaseConfig';
import { initializeApp, getApps, setLogLevel } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';

export interface FirebaseHandleable {
  readonly app: firebase.FirebaseApp;
}

const APP_NAME = '__aim_wdiget__';
export class FirebaseHandler implements FirebaseHandleable {
  private readonly firebaseApp: firebase.FirebaseApp;

  public constructor(
    tenantId: string,
    enableDebug = false,
    enableLongPolling = false,
    config: FirebaseConfig = staticConfig as any as FirebaseConfig
  ) {
    const appName = `${APP_NAME}_${tenantId}`;
    this.firebaseApp =
      getApps().find(app => app.name === appName) ?? initializeApp(config, appName);
    if (enableDebug) {
      setLogLevel('debug');
    }
    if (enableLongPolling) {
      initializeFirestore(this.firebaseApp, {
        experimentalForceLongPolling: true,
      });
    }
  }

  public get app() {
    return this.firebaseApp;
  }
}
