import React from 'react';

export const SendIcon = React.memo(
  ({ color }: { color: string }) => {
    return (
      <span aria-hidden={true}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <title>icon</title>
          <path
            fill={color}
            d="M203.92,284.94,475.31,13.69,4.5,170.5,202.37,287A9.94,9.94,0,0,1,203.92,284.94Z"
          />
          <path
            fill={color}
            d="M218.08,299.06a9.63,9.63,0,0,1-2.09,1.59L331.3,497.3,487.92,27.09Z"
          />
        </svg>
      </span>
    );
  },
  () => false
);
