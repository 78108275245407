/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React from 'react';
import styled from '@emotion/styled';
import { CrossbarIcon } from '@s/components/atom/CrossbarIcon';

const CloseElement = styled.button<{ size: number }>`
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  background-color: rgba(230, 230, 230, 0.8);
  cursor: pointer;
`;

const CloseIconElement = styled.span<{ size: number }>`
  display: inline-block;
  width: ${props => props.size / 2}px;
  height: ${props => props.size / 2}px;
  line-height: ${props => props.size / 2}px;
`;
export const CloseButton = ({
  size = 20,
  onClick = () => {},
}: {
  size?: number;
  onClick?(): void;
}) => {
  return (
    <CloseElement size={size} onClick={onClick}>
      <CloseIconElement size={size}>
        <CrossbarIcon color={'#000000'} />
      </CloseIconElement>
    </CloseElement>
  );
};
