import React from 'react';

export const UserAvatar = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 12C22.5166 12 21.0666 12.4399 19.8332 13.264C18.5999 14.0881 17.6386 15.2594 17.0709 16.6299C16.5032 18.0003 16.3547 19.5083 16.6441 20.9632C16.9335 22.418 17.6478 23.7544 18.6967 24.8033C19.7456 25.8522 21.082 26.5665 22.5368 26.8559C23.9917 27.1453 25.4997 26.9968 26.8701 26.4291C28.2406 25.8614 29.4119 24.9001 30.236 23.6668C31.0601 22.4334 31.5 20.9834 31.5 19.5C31.5 17.5109 30.7098 15.6032 29.3033 14.1967C27.8968 12.7902 25.9891 12 24 12ZM24 24C23.11 24 22.24 23.7361 21.4999 23.2416C20.7599 22.7471 20.1831 22.0443 19.8425 21.2221C19.502 20.3998 19.4128 19.495 19.5865 18.6221C19.7601 17.7492 20.1887 16.9474 20.818 16.318C21.4474 15.6887 22.2492 15.2601 23.1221 15.0865C23.995 14.9128 24.8998 15.0019 25.7221 15.3425C26.5443 15.6831 27.2472 16.2599 27.7416 16.9999C28.2361 17.74 28.5 18.61 28.5 19.5C28.4988 20.6931 28.0243 21.837 27.1807 22.6807C26.337 23.5243 25.1931 23.9988 24 24Z"
        fill="#666666"
      />
      <path
        d="M24 3C19.8466 3 15.7865 4.23163 12.333 6.53914C8.8796 8.84665 6.18798 12.1264 4.59854 15.9636C3.0091 19.8009 2.59323 24.0233 3.40352 28.0969C4.21381 32.1705 6.21386 35.9123 9.15077 38.8492C12.0877 41.7861 15.8295 43.7862 19.9031 44.5965C23.9767 45.4068 28.1991 44.9909 32.0364 43.4015C35.8736 41.812 39.1534 39.1204 41.4609 35.667C43.7684 32.2135 45 28.1534 45 24C44.9937 18.4324 42.7791 13.0947 38.8422 9.15777C34.9053 5.22089 29.5676 3.00635 24 3ZM15 39.5655V37.5C15.0012 36.3069 15.4757 35.163 16.3193 34.3193C17.163 33.4757 18.3069 33.0012 19.5 33H28.5C29.6931 33.0012 30.837 33.4757 31.6807 34.3193C32.5243 35.163 32.9988 36.3069 33 37.5V39.5655C30.2688 41.1603 27.1628 42.0007 24 42.0007C20.8372 42.0007 17.7313 41.1603 15 39.5655ZM35.988 37.389C35.9581 35.4215 35.1565 33.5445 33.7559 32.1624C32.3553 30.7803 30.4677 30.0037 28.5 30H19.5C17.5323 30.0037 15.6448 30.7803 14.2441 32.1624C12.8435 33.5445 12.0419 35.4215 12.012 37.389C9.29186 34.9601 7.3736 31.7623 6.51126 28.219C5.64892 24.6757 5.88316 20.954 7.18297 17.5468C8.48278 14.1396 10.7868 11.2075 13.7901 9.13885C16.7933 7.0702 20.354 5.96254 24.0008 5.96254C27.6475 5.96254 31.2082 7.0702 34.2115 9.13885C37.2147 11.2075 39.5187 14.1396 40.8186 17.5468C42.1184 20.954 42.3526 24.6757 41.4903 28.219C40.6279 31.7623 38.7082 34.9601 35.988 37.389Z"
        fill="#666666"
      />
    </svg>
  );
};
