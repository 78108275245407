/**
 * @fileoverview
 * @author
 */

import { rng } from '@s/rng';

export type ServiceSideMessageSender = 'Bot' | 'Customer';
export type MessageSender = ServiceSideMessageSender | 'Operator';

export type MessageFormatType =
  | 'plainText'
  | 'image'
  | 'instagramStoryMention'
  | 'instagramStoryReply'
  | 'instagramMessageDelete';
export type WidgetMessageFormatType = MessageFormatType | 'postback';

/**
 *  Payload
 */
export type ImagePayload = {
  url: string;
  previewUrl: string;
};

type LLMImagePayload = {
  url: string;
  thumbnailUrl: string;
};

export type QuickReplyPostbackPayload = {
  payload: string;
  text: string;
};

/**
 * QuickReply表示フォーマット
 */
export type QuickReplyType = 'text' | 'link' | 'postback';

// Widget内で使うMessageのinterface
export interface QuickReply {
  label: string;
  payload: string;
  type: QuickReplyType;
}

/**
 *  Attachment
 */
type Attachment = {
  mediaType: WidgetMessageFormatType;
  quickReplies?: QuickReply[];
};

export type PlainTextAttachment = Attachment & {
  mediaType: 'plainText';
  payload: string;
};

export type StoryReplyTextAttachment = Attachment & {
  mediaType: 'instagramStoryReply';
  payload: {
    /* eslint-disable @typescript-eslint/naming-convention */
    ReplyStoryURL: string;
    Text: string;
    /* eslint-enable @typescript-eslint/naming-convention */
  };
};

export type ImageTextAttachment = Attachment & {
  mediaType: 'image';
  payload: ImagePayload;
};

type LLMImageTextAttachment = Attachment & {
  mediaType: 'image';
  payload: LLMImagePayload;
};

export type QuickReplyPostbackAttachment = Attachment & {
  mediaType: 'postback';
  payload: QuickReplyPostbackPayload;
};

/**
 * MessageFormat
 */
export type TextInputState = 'show' | 'hide';

/**
 * 表示メッセージのフォーマット
 */
export interface ChatMessage {
  attachment:
    | PlainTextAttachment
    | ImageTextAttachment
    | QuickReplyPostbackAttachment
    | StoryReplyTextAttachment;
  id: string;
  meta?: {
    delay?: string;
    uuid: string;
    isWelcome: boolean;
    clientOriginMessage?: string;
  };
  type?: WidgetMessageFormatType | string;
}

/**
 * メッセージフォーマット
 */
export interface MessageFormat {
  at: number;
  viewAt?: number;
  id: string;
  messages: ChatMessage[];
  meta?: {
    uuid: string;
    isWelcome?: boolean;
    delay?: number;
    textInputState?: TextInputState;
    clientOriginMessageId?: string;
    isTyping?: boolean;
  };
  sender: {
    id: string;
    name?: string;
    type?: MessageSender;
  };
  type: WidgetMessageFormatType | string;
}

const id = rng();
export const defaultWelcomeMessage: ChatMessage = {
  attachment: {
    mediaType: 'plainText',
    payload: 'ようこそ',
  },
  id: id,
  meta: {
    uuid: id,
    isWelcome: true,
  },
  type: 'plainText',
};

export class MessageFactory {
  public static image(
    payload: { url: string; previewUrl: string } = { url: '', previewUrl: '' },
    entity: PartialDeep<MessageFormat> = {}
  ): MessageFormat {
    return {
      at: Date.now(),
      viewAt: Date.now(),
      id: rng(),
      messages: [
        {
          attachment: {
            mediaType: 'image',
            payload,
          },
          id: rng(),
          meta: { uuid: rng(), isWelcome: false },
        },
      ],
      meta: {
        uuid: rng(),
        ...(entity.meta ?? {}),
      },
      sender: {
        id: rng(),
        name: '',
        type: 'Operator',
        ...(entity.sender ?? {}),
      },
      type: 'message',
    };
  }

  public static text(text = '', entity: PartialDeep<MessageFormat> = {}): MessageFormat {
    return {
      at: Date.now(),
      viewAt: Date.now(),
      id: rng(),
      messages: [
        {
          attachment: {
            mediaType: 'plainText',
            payload: text,
          },
          id: rng(),
          meta: { uuid: rng(), isWelcome: false },
        },
      ],
      meta: {
        uuid: rng(),
        ...(entity.meta ?? {}),
      },
      sender: {
        id: rng(),
        name: '',
        type: 'Operator',
        ...(entity.sender ?? {}),
      },
      type: 'message',
    };
  }
}

export interface LLMChatMessage {
  attachment: PlainTextAttachment | LLMImageTextAttachment | QuickReplyPostbackAttachment;
}

export interface LLMMessageFormat {
  at: number;
  createdAt: string;
  type: string;
  messages: LLMChatMessage[];
  sender: {
    id: string;
    name: string;
    type: MessageSender;
  };
  isWelcome: boolean;
  referer: string;
  senderMessageId: string;
}
