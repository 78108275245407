import { runtimeConfig } from '@w/config';

export class Console {
  public static error(message: string) {
    if (runtimeConfig.isLoggingEnabled()) {
      console.error(message);
    }
  }

  public static info(message: any) {
    if (runtimeConfig.isLoggingEnabled()) {
      console.info(message);
    }
  }

  public static log(message: string) {
    if (runtimeConfig.isLoggingEnabled()) {
      // eslint-disable-next-line no-console
      console.log(message);
    }
  }

  public static warn(message: string) {
    if (runtimeConfig.isLoggingEnabled()) {
      console.warn(message);
    }
  }
}
