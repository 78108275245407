/**
 * @fileOverview
 * @name env.ts<module>
 * @author Taketoshi Aono
 * @license
 */

import { ChatMessage, TextInputState } from '@s/domain/entity/MessageFormat';
import { ReduxState } from '@w/domain/entities/State';

export const envInitialized = (payload: {
  projectId: string;
  tenantId: string;
  tenantName: string;
  persistency?: ReduxState['env']['persistency'];
  userData?: { [key: string]: string };
}) => {
  return {
    type: 'AIM_WIDGET_ENV_INITIALIZED' as const,
    payload,
  };
};

export const welcomeMessagePrepared = (payload: {
  messages: ChatMessage[];
  textInputState?: TextInputState;
}) => {
  return {
    type: 'AIM_WIDGET_WELCOME_MESSAGE_PREPARED' as const,
    payload,
  };
};
