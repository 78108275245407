const publicApiSymbol = Symbol('@@aimPublicApi');
export const publicApi = (target: any, name: string, descriptor: PropertyDescriptor) => {
  if (!target[publicApiSymbol]) {
    target[publicApiSymbol] = [];
  }
  target[publicApiSymbol].push({ name, descriptor });
};

export const createApiProxy = <T>(
  classObject: new (...args: any[]) => T & { [publicApiSymbol]?: (keyof T)[] },
  instanceRef: { nativeInstance?: T } = {}
) => {
  return class PublicApiProxy {
    public constructor(...rest: ConstructorParameters<typeof classObject>) {
      if (!classObject.prototype[publicApiSymbol]) {
        throw new Error('Class object must have at least one @publicApi decorated member');
      }
      const instance: T & { [publicApiSymbol]?: (keyof T)[] } = new classObject(...rest);
      instanceRef.nativeInstance = instance;
      instance[publicApiSymbol]!.forEach(prop => {
        const pName = (prop as any).name;
        const key = pName as keyof T;
        const p = instance[key];
        const callable =
          typeof p === 'function' ? (...args: any[]) => (p as any).apply(instance, args) : null;
        Object.defineProperty(this, pName, {
          get() {
            return callable ? callable : instance[key];
          },
        });
      });
    }
  };
};
