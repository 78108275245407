/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import { WidgetConfigurationCustomize, defaultWidgetConfiguration } from '@s/domain/values';
import { chooseTextColor } from '@s/chooseColor';
import { getRgbColor } from '@s/colorUtils';
import { WIDGET_HEIGHT } from './WidgetConstant';

export type WidgetEventType =
  | 'immediate'
  | 'mount'
  | 'open'
  | 'openOnce'
  | 'close'
  | 'linkClick'
  | 'buttonClick';

export interface WidgetConfig {
  floatingIconColor: string;
  floatingIconSrc: string;
  floatingIconSize: { width: number; height: number };
  floatingChatWindowHeight: number;
  themeColor: string;
  botIconSrc: string;
  customerIconSrc: string;
  operatorIconSrc: string;
  headerText: string;
  balloonText: string;
  iconPosition: WidgetConfigurationCustomize['floatingIcon']['position'];
  distanceFromScreenEdges: WidgetConfigurationCustomize['floatingIcon']['distanceFromScreenEdges'];
  dispatch(type: WidgetEventType, ...args: any[]): Promise<void>;
}

export interface WidgetColoringConfig {
  color: string;
  background: string;
  colorHover: string;
  backgroundHover: string;
}

export interface WidgetInternalConfig extends Omit<WidgetConfig, 'themeColor'> {
  chatColoring: {
    common: WidgetColoringConfig;
    Operator: WidgetColoringConfig;
    Bot: WidgetColoringConfig;
    Customer: WidgetColoringConfig;
  };
}

export const getWidgetConfig = (
  customization: WidgetConfigurationCustomize,
  dispatch: (type: WidgetEventType, ...args: any[]) => Promise<void>
): WidgetInternalConfig => {
  const color = chooseTextColor(customization.themeColor);
  const backgroundHover = getRgbColor(customization.themeColor, {
    a: () => 0.7,
  });
  const colorHover = color === 'black' ? '#666' : '#DDD';

  const v: WidgetColoringConfig = {
    color,
    background: customization.themeColor,
    backgroundHover,
    colorHover,
  };
  return {
    floatingIconColor: customization.floatingIcon.color ?? v.background,
    floatingIconSrc: customization.floatingIcon.src ?? '',
    floatingIconSize: customization.floatingIcon.size ?? {
      width: 80,
      height: 80,
    },
    floatingChatWindowHeight: customization.floatingChatWindowHeight ?? WIDGET_HEIGHT,
    headerText: customization.messages?.headerText ?? 'チャットサポート',
    balloonText: customization.messages?.balloonText ?? 'チャットサポートはこちら',
    iconPosition: customization.floatingIcon.position,
    distanceFromScreenEdges: customization.floatingIcon.distanceFromScreenEdges ?? { x: 0, y: 0 },
    botIconSrc: customization.icons && customization.icons.bot ? customization.icons.bot : '',
    customerIconSrc:
      customization.icons && customization.icons.customer ? customization.icons.customer : '',
    operatorIconSrc:
      customization.icons && customization.icons.operator ? customization.icons.operator : '',
    chatColoring: {
      common: v,
      Operator: v,
      Bot: v,
      Customer: {
        background: '#DDDDDD',
        color: chooseTextColor('#DDDDDD'),
        backgroundHover: '#EEE',
        colorHover: '#444',
      },
    },
    dispatch,
  };
};

export const defaultWidgetInternalConfig = getWidgetConfig(
  defaultWidgetConfiguration.customization,
  async () => {}
);
