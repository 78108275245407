import React from 'react';
import styled from '@emotion/styled';

const PathElement = styled.path<{ fill: string }>`
  transition: fill 0.2s;
  fill: ${p => p.fill};
`;
export const AudioPauseIcon = ({ color }: { color: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <PathElement
      d="M256,0C114.625,0,0,114.625,0,256c0,141.374,114.625,256,256,256s256-114.626,256-256
		C512,114.625,397.375,0,256,0z M224,336h-64V176h64V336z M352,336h-64V176h64V336z"
      fill={color}
    />
  </svg>
);
