/**
 * @fileOverview
 * @name useScrollFix.ts
 * @author Taketoshi Aono
 * @license
 */

import React, { useRef } from 'react';
import { Environment } from '../Environment';

export const useScrollFix = ({
  environment,
  containerElementRef,
  scrollCallback,
  onComplete,
}: {
  environment: Environment;
  containerElementRef: React.RefObject<HTMLElement | null>;
  scrollCallback(e: Event | React.SyntheticEvent<any>): void;
  onComplete?(): void;
}) => {
  if (environment.isSupportedPcBrowser) {
    return scrollCallback;
  }
  const currentScrollTopRef = useRef(0);

  return (e: Event) => {
    scrollCallback(e);

    if (!containerElementRef.current) {
      return;
    }

    currentScrollTopRef.current = containerElementRef.current.scrollTop;

    setTimeout(() => {
      if (!containerElementRef.current) {
        return;
      }
      if (currentScrollTopRef.current !== containerElementRef.current.scrollTop) {
        scrollCallback(e);
      } else {
        onComplete && onComplete();
      }
    }, 100);
  };
};
