/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React from 'react';

export const WidgetDefaultFloatingIcon = ({
  width = 50,
  height = 50,
}: {
  width?: number | string;
  height?: number | string;
}) => {
  return (
    <div css={{ width, height }} aria-hidden={true}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
        <title>icon</title>
        <path
          d="M207.1,298.34c0,26.65,12.05,51.23,32.33,70.92-5.09.68-10.24,1.25-15.46,1.66C183.58,411.16,71.43,433,71.43,433s28.95-19.37,45.06-37a118,118,0,0,0,20.93-31.62C58.4,345.67,1.5,293.45,1.5,231.9,1.5,154.63,91.13,92,201.7,92c86.3,0,159.83,38.15,188,91.64a217.05,217.05,0,0,0-26.28-1.6C275.75,182,207.1,233.13,207.1,298.34Zm289,0c0-51.22-59.43-92.75-132.74-92.75s-132.73,41.53-132.73,92.75c0,47.74,51.62,87,118,92.17,26.78,26.68,101.13,41.16,101.13,41.16s-19.19-12.84-29.87-24.52a78.06,78.06,0,0,1-13.88-21C458.39,373.78,496.12,339.15,496.12,298.34Z"
          fill="#FFF"
        />
      </svg>
    </div>
  );
};
